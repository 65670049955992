import { convertTimestampToMoment } from '../../convertTimestampToMoment';
import { defaultDateTimeFormatWithSecondsUTC } from '../../constants';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const formatCustomLabel = (
  startTimestamp: timing.v1.Timestamp,
  endTimestamp: timing.v1.Timestamp
): string => {
  const start = convertTimestampToMoment(startTimestamp).format(
    defaultDateTimeFormatWithSecondsUTC
  );
  const end = convertTimestampToMoment(endTimestamp).format(
    defaultDateTimeFormatWithSecondsUTC
  );
  return `${start} - ${end}`;
};

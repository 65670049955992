import { FC, memo, useEffect, useState } from 'react';
import { FilterMenu } from '../FilterMenu';
import { FilterMenuOption } from '../FilterMenu/types';
import { Flexbox } from '../Flexbox';
import { Panel } from './Panel';
import { ReadOnlyBadge } from '../ReadOnlyBadge';
import { Search } from './Search';
import { SourceFilter } from '../../../apps/admin/components/shared/Filters/SourceFilter';
import { ToolbarProps } from './types';
import { TopPanel } from './TopPanel';
import { WorkflowFilter } from '../../../apps/admin/components/shared/Filters/WorkflowFilter';
import { getMatchingTimeOption, TimeFilter } from '../Filters';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

const Toolbar: FC<ToolbarProps> = ({
  borderType,
  filterOptions = [],
  isPanelOpen,
  isTopPanelOpen,
  searchTooltipText,
  searchValue,
  setIsPanelOpen,
  setIsTopPanelOpen,
  setSearchValue,
  showReadOnly,
  showSourceFilter,
  showWorkflowFilter,
  time,
  ...props
}) => {
  const theme = useTheme();
  const { workflowIds, sourceApplicationEventPayloads } = useUrlParams();

  const timeOption = getMatchingTimeOption(time);
  const hasWorkflowIds = !!workflowIds?.length;
  const hasSourceApplicationEventPayloads =
    !!sourceApplicationEventPayloads?.length;

  const [isTimeFilterSelected, setIsTimeFilterSelected] =
    useState<boolean>(false);
  const [isWorkflowFilterSelected, setIsWorkflowFilterSelected] =
    useState<boolean>(hasWorkflowIds);
  const [isSourceFilterSelected, setIsSourceFilterSelected] = useState<boolean>(
    hasSourceApplicationEventPayloads
  );

  const updatedFilterOptions = [
    {
      component: <TimeFilter timeOption={timeOption} />,
      isSelected: isTimeFilterSelected,
      label: 'Time',
      setIsSelected: setIsTimeFilterSelected,
    },
    ...(showWorkflowFilter
      ? [
          {
            component: (
              <WorkflowFilter
                setIsWorkflowFilterSelected={setIsWorkflowFilterSelected}
              />
            ),
            isSelected: isWorkflowFilterSelected,
            label: 'Workflow',
            setIsSelected: setIsWorkflowFilterSelected,
          },
        ]
      : []),
    ...(showSourceFilter
      ? [
          {
            component: (
              <SourceFilter
                setIsSourceFilterSelected={setIsSourceFilterSelected}
              />
            ),
            isSelected: isSourceFilterSelected,
            label: 'Source',
            setIsSelected: setIsSourceFilterSelected,
          },
        ]
      : []),
    ...filterOptions,
  ];

  const selectedFilterOptions = updatedFilterOptions.filter(
    ({ isSelected }) => isSelected
  );

  const unSelectedFilterOptions = updatedFilterOptions.filter(
    ({ isSelected }) => !isSelected
  );

  const handleClick = (filterOption: FilterMenuOption) => {
    filterOption.setIsSelected(true);
  };

  useEffect(() => {
    setIsTimeFilterSelected(!!time);
  }, [time]);

  return (
    <Flexbox
      data-testid='toolbar'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      height='2.25rem'
      borderType={borderType}
      py={0.5}
      px={1}
      {...props}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.5}
      >
        {isPanelOpen !== undefined && setIsPanelOpen && (
          <Panel isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
        )}

        {isTopPanelOpen !== undefined && setIsTopPanelOpen && (
          <TopPanel
            isTopPanelOpen={isTopPanelOpen}
            setIsTopPanelOpen={setIsTopPanelOpen}
          />
        )}

        {searchValue !== undefined && searchTooltipText && setSearchValue && (
          <Search
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchTooltipText={searchTooltipText}
          />
        )}

        <FilterMenu
          filterOptions={unSelectedFilterOptions}
          onClick={handleClick}
        />

        <>{selectedFilterOptions.map(({ component }) => component)}</>
      </Flexbox>

      {showReadOnly && <ReadOnlyBadge />}
    </Flexbox>
  );
};

export const MemoizedToolbar = memo(Toolbar);

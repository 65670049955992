'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.facebook = (() => {
  const facebook = {};
  facebook.v1 = (() => {
    const v1 = {};
    v1.SendConversionEventResponse = (() => {
      class SendConversionEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SendConversionEventResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.facebookTraceId != null &&
            Object.hasOwnProperty.call(message, 'facebookTraceId')
          ) {
            writer.uint32(10).string(message.facebookTraceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SendConversionEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.facebookTraceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.facebook.v1.SendConversionEventResponse) {
            return object;
          }
          const message = new $root.facebook.v1.SendConversionEventResponse();
          if (object.facebookTraceId != null) {
            message.facebookTraceId = String(object.facebookTraceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.facebookTraceId = '';
          }
          let keys;
          if (
            message.facebookTraceId != null &&
            message.hasOwnProperty('facebookTraceId')
          ) {
            object.facebookTraceId = message.facebookTraceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'facebook.v1.SendConversionEventResponse';
        }
      }

      SendConversionEventResponse.prototype.facebookTraceId = '';

      return SendConversionEventResponse;
    })();

    return v1;
  })();

  return facebook;
})();

export const facebook = $root.facebook;

'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.v1 = (() => {
    const v1 = {};
    v1.GoogleAdsSendConversionEventResponse = (() => {
      class GoogleAdsSendConversionEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GoogleAdsSendConversionEventResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.jobId != null &&
            Object.hasOwnProperty.call(message, 'jobId')
          ) {
            writer.uint32(10).string(message.jobId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GoogleAdsSendConversionEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.jobId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.google.v1.GoogleAdsSendConversionEventResponse
          ) {
            return object;
          }
          const message =
            new $root.google.v1.GoogleAdsSendConversionEventResponse();
          if (object.jobId != null) {
            message.jobId = String(object.jobId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.jobId = '';
          }
          let keys;
          if (message.jobId != null && message.hasOwnProperty('jobId')) {
            object.jobId = message.jobId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'google.v1.GoogleAdsSendConversionEventResponse'
          );
        }
      }

      GoogleAdsSendConversionEventResponse.prototype.jobId = '';

      return GoogleAdsSendConversionEventResponse;
    })();

    return v1;
  })();

  return google;
})();

export const google = $root.google;

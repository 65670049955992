import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { WorkflowExecutionStatus } from '../../../WorkflowExecutionStatus';
import { WorkflowExecutionTooltipProps } from './types';
import { useTheme } from '@morf/theming';
import { MemoizedWorkflowExecutionTooltipName } from './WorkflowExecutionTooltipName';
import { MemoizedWorkflowExecutionTooltipProfileId } from './WorkflowExecutionTooltipProfileId';
import {
  convertTimestampToMoment,
  defaultDateTimeFormatWithSeconds,
} from '../../../Filters';

const WorkflowExecutionTooltip: FC<WorkflowExecutionTooltipProps> = ({
  applicationType,
  executionEndTime,
  executionStartTime,
  matchedProfileIndex,
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  supersededByWorkflowExecutionId,
  supersededWorkflowExecutionId,
  workflowName,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='workflow-execution-tooltip'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      width='21.25rem'
      gap={0.25}
      p={0.75}
    >
      <Flexbox
        data-testid='workflow-execution-start-timestamp'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.25}
      >
        <Icon
          name='clock-start'
          size={1}
          fill={theme.colors.text.muted}
          stroke={theme.colors.text.muted}
          strokeWidth={1.75}
        />
        <Text tag='p3'>
          {convertTimestampToMoment(executionStartTime).format(
            defaultDateTimeFormatWithSeconds
          )}
        </Text>
      </Flexbox>

      {executionEndTime && (
        <Flexbox
          data-testid='workflow-execution-end-timestamp'
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='clock-end'
            size={1}
            fill={theme.colors.text.muted}
            stroke={theme.colors.text.muted}
            strokeWidth={1.75}
          />
          <Text tag='p3'>
            {convertTimestampToMoment(executionEndTime).format(
              defaultDateTimeFormatWithSeconds
            )}
          </Text>
        </Flexbox>
      )}

      <MemoizedWorkflowExecutionTooltipName
        applicationType={applicationType}
        workflowName={workflowName}
      />

      <MemoizedWorkflowExecutionTooltipProfileId
        matchedProfileIndex={matchedProfileIndex}
      />

      <WorkflowExecutionStatus
        retryNumber={retryNumber}
        retryOriginalWorkflowExecutionId={retryOriginalWorkflowExecutionId}
        status={status}
        supersededByWorkflowExecutionId={supersededByWorkflowExecutionId}
        supersededWorkflowExecutionId={supersededWorkflowExecutionId}
        variant='icon-hover-description'
      />
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionTooltip = memo(WorkflowExecutionTooltip);

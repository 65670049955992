import _ from 'lodash';
import { FC, memo } from 'react';
import { Filter } from '../../Filter';
import { StatusFilterProps } from './types';

const StatusFilter: FC<StatusFilterProps> = ({ statuses }) => {
  const statusOptions = [
    { id: '1', name: 'Executed', icon: { name: 'bolt', stroke: '#0FB0A7' } },
    { id: '2', name: 'Waiting', icon: { name: 'bolt', stroke: '#9747FF' } },
    { id: '3', name: 'Processing', icon: { name: 'bolt', stroke: '#999BAF' } },
    {
      id: '4',
      name: 'Retrying',
      icon: { name: 'bolt-arrow', stroke: '#999BAF' },
    },
    {
      id: '5',
      name: 'Superseded',
      icon: { name: 'bolt-double', stroke: '#999BAF' },
    },
    {
      id: '6',
      name: 'Skipped',
      icon: { name: 'bolt-slash', stroke: '#999BAF' },
    },
    {
      id: '7',
      name: 'Failed',
      icon: { name: 'bolt-slash', stroke: '#FD4B4B' },
    },
  ];

  return (
    <Filter
      allOptions={statusOptions}
      name='Status'
      options={statuses}
      queryName='status'
      setIsFilterSelected={() => console.log('setIsFilterSelected')}
    />
  );
};

export const MemoizedStatusFilter = memo(StatusFilter);

import { Container } from '../Container';
import { Flexbox } from '../Flexbox';
import { PayloadProps } from './types';
import { StyledEditor } from './Payload.css';
import { Text } from '../Typography';
import { formatPayload } from './formatPayload';
import { options } from './options';
import { useState, useEffect, useRef, FC } from 'react';
import { useTheme } from '@morf/theming';
import {
  BeforeMount,
  OnChange,
  OnMount,
  useMonaco,
} from '@monaco-editor/react';

export const Payload: FC<PayloadProps> = ({
  color,
  defaultLanguage = 'json',
  extraOptions,
  onChange,
  payload,
  placeholder,
  ...props
}) => {
  const theme = useTheme();
  const monaco = useMonaco();
  const editorRef = useRef<typeof monaco.editor.IStandaloneCodeEditor | null>(
    null
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const editorOptions = { ...options, ...extraOptions };

  const [containerSize, setContainerSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setContainerSize({
        width: Math.min(width, container.clientWidth),
        height,
      });
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    editorRef.current?.layout();
  }, [containerSize]);

  const handleMount: OnMount = (editor) => {
    editorRef.current = editor;
    editor.layout();
  };

  const handleBeforeMount: BeforeMount = (monaco) => {
    monaco.editor.defineTheme('default', {
      base: 'vs',
      inherit: false,
      rules: [],
      colors: {
        'editor.foreground': color || theme.colors.text.body,
        'editor.background': theme.colors.ui.card,
        'editorLineNumber.foreground': theme.colors.text.body,
        'editor.findMatchBackground': theme.colors.main.light.normal,
        'editor.selectionBackground': theme.colors.main.light.lighter,
        'editor.lineHighlightBackground': theme.colors.main.light.lighter,
        'editor.findMatchHighlightBackground': theme.colors.main.light.normal,
        'editor.rangeHighlightBackground': theme.colors.main.light.lighter,
      },
    });
  };

  const handleChange: OnChange = (value, ev) => {
    if (value && onChange && editorRef.current) {
      const position = editorRef.current.getPosition();
      onChange(value, ev);
      setTimeout(() => {
        if (position) {
          editorRef.current.setPosition(position);
        }
      }, 0);
    }
  };

  return (
    <Container data-testid='payload-editor' ref={containerRef}>
      {payload ? (
        <StyledEditor
          beforeMount={handleBeforeMount}
          defaultLanguage={defaultLanguage}
          height={`${containerSize.height}px`}
          loading={null}
          onChange={handleChange}
          onMount={handleMount}
          options={editorOptions}
          theme='default'
          value={formatPayload(payload)}
          width={`${containerSize.width}px`}
          {...props}
        />
      ) : (
        <Flexbox justifyContent='center' alignItems='center'>
          <Text tag='p1'>{placeholder}</Text>
        </Flexbox>
      )}
    </Container>
  );
};

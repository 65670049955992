export const Bolt = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z'
    />
  );
};

export const BoltSlash = () => {
  return (
    <>
      <path
        d='M12.7316 9.65069L14.75 2.25L4.25 13.5H9'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 13.5L10.25 21.75L20.75 10.5H16'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 2L18.8363 3.66365L4 18.5L2.5 20'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltArrow = () => {
  return (
    <>
      <path
        d='M9 13.5H4.25L14.75 2.25L13 10H14'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.75 10L10.25 21.75L12.5 13.5H6M20.75 10H17M20.75 10L21 13.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltArrowSlash = () => {
  return (
    <>
      <path
        d='M9 13.5H4.25L14.75 2.25L13.1874 9.16991'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.75 10L10.25 21.75L12.5 13.5H6M20.75 10H17M20.75 10L21 13.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 2L18.8363 3.66365L4 18.5L2.5 20'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltDouble = () => {
  return (
    <>
      <path
        d='M2.5 14.2692L11.3846 4.75L9.48077 11.7308H16.4615L7.57692 21.25L9.48077 14.2692H2.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0866 17.8898L12.5962 19.7692L21.75 9.51775H14.5577L16.5193 3L12.5962 7.39349'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltDoubleSlash = () => {
  return (
    <>
      <path
        d='M13.7959 11.7308H15.9615L7.07692 21.25L8.09965 17.5M10.7404 11.7308H8.98077L10.8846 4.75L2 14.2692H8.09965'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 9.47845L21.5938 10.25L11.0938 21.5L12.2188 17.375L12.3594 16.8594L13 15M14.6455 7.73307V7.5L15.5938 2L12.9688 4.8125L11.6562 6.21875L11 6.92188L10 8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 2L18.8363 3.66365L4 18.5L2.5 20'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltId = () => {
  return (
    <>
      <path
        d='M15.5 9H19.25M15.5 12H19.25M15.5 15H19.25M5 19.5H20C21.2426 19.5 22.25 18.4926 22.25 17.25V6.75C22.25 5.50736 21.2426 4.5 20 4.5H5C3.75736 4.5 2.75 5.50736 2.75 6.75V17.25C2.75 18.4926 3.75736 19.5 5 19.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 12.7692L10.0909 7L9 11.2308H13L7.90909 17L9 12.7692H5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

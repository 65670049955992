import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeType } from '../types';
import { TrackProfilePropertiesNodeProps } from './types';
import { iconNameNodeTypeMap } from '../constants';

const TrackProfilePropertiesNode: FC<TrackProfilePropertiesNodeProps> = ({
  data,
  id,
}) => {
  const { integrationIconName, isRequired, name, status, title } = data.value;
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel
        id={id}
        integrationIconName={integrationIconName}
        isRequired={isRequired}
        name={name}
        status={status}
        title={title}
      />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedTrackProfilePropertiesNode = memo(
  TrackProfilePropertiesNode
);

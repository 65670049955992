import Long from 'long';
import _ from 'lodash';
import rpcService from '@morf/server/browser-server';
import { FC, memo } from 'react';
import { Filter } from '@morf/ui/Filter';
import { FilterOption } from '@morf/ui/Filter/FilterDropdown/FilterOption/types';
import { SourceFilterProps } from './types';
import { convertToKebabCase, convertToTitleCase } from '@morf/ui';
import { encodeStringBase64 } from '../../../helpers';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { useQuery } from '@tanstack/react-query';
import { useUrlParams } from '../../../helpers/useUrlParams';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

const SourceFilter: FC<SourceFilterProps> = ({ setIsSourceFilterSelected }) => {
  const { organizationId, sourceApplicationEventPayloads } = useUrlParams();

  const {
    data: listOrganizationFilterTypesResponse,
    isLoading: listOrganizationFilterTypesResponseIsLoading,
  } = useQuery<workflow_monitoring.v2.ListOrganizationFilterTypesResponse>(
    [
      '/workflow_monitoring.v2.WorkflowMonitoringService/ListOrganizationFilterTypes',
      organizationId,
    ],
    async () => {
      return await rpcService.workflowMonitoringV2Service.listOrganizationFilterTypes(
        {
          stub: '',
        }
      );
    }
  );

  const allSourceOptions: FilterOption[] =
    listOrganizationFilterTypesResponse?.sourceApplicationEventPayloads?.map(
      ({ sourceApplication, eventPayloadStubHashMatches }) => {
        const application =
          source_application.v2.SourceApplication.Application[
            sourceApplication
          ];

        const eventPayloadHashes = Object.entries(
          eventPayloadStubHashMatches as Record<
            string,
            workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches
          >
        ).map(([eventPayloadHash, { eventTypeIndices }]) => {
          return new workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes(
            {
              eventPayloadHash: Long.fromString(eventPayloadHash),
              eventTypeIndices: eventTypeIndices.map(({ index }) => index),
            }
          );
        });

        return {
          id: `sourceApplication=${sourceApplication};eventPayloadHashes=${encodeStringBase64(
            JSON.stringify(eventPayloadHashes)
          )}`,
          integrationIconName: convertToKebabCase(application),
          name: convertToTitleCase(application),
        };
      }
    ) || [];

  const selectedSourceOptions = allSourceOptions.filter(({ id }) =>
    sourceApplicationEventPayloads?.some((entry) =>
      entry.includes(id.split(';')[0])
    )
  );

  return (
    <Filter
      allOptions={allSourceOptions}
      name='Source'
      options={selectedSourceOptions}
      queryName='sourceApplicationEventPayloads'
      setIsFilterSelected={setIsSourceFilterSelected}
    />
  );
};

export const MemoizedSourceFilter = memo(SourceFilter);

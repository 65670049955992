export const options = {
  fontSize: 12,
  fontFamily: 'Apercu Pro',
  readOnly: true,
  minimap: {
    enabled: false,
  },
  contextmenu: false,
  scrollBeyondLastLine: false,
  renderLineHighlight: 'none' as 'none' | 'line' | 'all' | 'gutter',
  automaticLayout: false,
  overviewRulerLanes: 0,
  scrollbar: {
    verticalScrollbarSize: 8,
    horizontalScrollbarSize: 8,
  },
};

import _ from 'lodash';

export const getDefaultIndividualWorkflowExecutionPath = (
  organizationId: string,
  workflowExecutionId: string
): string => {
  const workflowExecutionURL = new URL(
    window.location.origin +
      `/organizations/${organizationId}/workflowExecution/${workflowExecutionId}`
  );
  return _.toString(workflowExecutionURL);
};

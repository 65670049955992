import _ from 'lodash';
import { EventRow, EventRowLoading } from './EventRow';
import { EventTimelineProps } from './types';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { NoMoreTimelineRows } from '../NoMoreTimelineRows';
import { Text } from '../Typography';
import { createScrollHandler } from '../Helpers/createScrollHandler';
import { useTheme } from '@morf/theming';

const EventTimeline: FC<EventTimelineProps> = ({
  eventRows,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  isRefetching,
  isRetrying,
  timeOption,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoadingAnotherHour, setIsLoadingAnotherHour] =
    useState<boolean>(false);

  const initialLoading =
    isLoading ||
    isRetrying ||
    (isFetching && isRefetching && !isLoadingAnotherHour);

  const handleScroll = createScrollHandler({
    containerRef,
    callback: fetchNextPage,
  });

  const uniqueEventRows = _.uniqBy(eventRows, 'id');

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='event-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {initialLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <EventRowLoading key={index} />
        ))
      ) : _.isEmpty(eventRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No events
          </Text>
        </Flexbox>
      ) : (
        <>
          {uniqueEventRows.map((row) => (
            <EventRow key={row.id} {...row} />
          ))}

          {hasNextPage ? (
            isFetching && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <EventRowLoading key={index} />
                ))}
              </>
            )
          ) : (
            <NoMoreTimelineRows
              setIsLoadingAnotherHour={setIsLoadingAnotherHour}
              timeOption={timeOption}
              type='events'
            />
          )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedEventTimeline = memo(EventTimeline);

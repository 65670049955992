import { google } from '@morf/proto/struct_ts_proto';

export const parseStringAsStruct = (
  string: string | undefined
): google.protobuf.Struct => {
  if (!string) return new google.protobuf.Struct();

  try {
    const parsedObject = JSON.parse(string);
    return buildStruct(parsedObject);
  } catch (error) {
    return new google.protobuf.Struct();
  }
};

const buildStruct = (data: Object): google.protobuf.Struct => {
  const struct = new google.protobuf.Struct();
  struct.fields = {};

  for (const [key, value] of Object.entries(data)) {
    struct.fields[key] = buildValue(value);
  }

  return struct;
};

const buildValue = (value: unknown): google.protobuf.Value => {
  const protobufValue = new google.protobuf.Value();

  if (value === null) {
    protobufValue.nullValue = google.protobuf.NullValue.NULL_VALUE;
  } else if (typeof value === 'boolean') {
    protobufValue.boolValue = value;
  } else if (typeof value === 'number') {
    protobufValue.numberValue = value;
  } else if (typeof value === 'string') {
    protobufValue.stringValue = value;
  } else if (Array.isArray(value)) {
    protobufValue.listValue = new google.protobuf.ListValue();
    protobufValue.listValue.values = value.map(buildValue);
  } else if (typeof value === 'object' && value !== null) {
    protobufValue.structValue = buildStruct(value);
  }

  return protobufValue;
};

'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { google as google$1 } from '../../empty_ts_proto';
import { hubspot as hubspot$1 } from '../../hubspot_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from '../../workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.hubspot = (() => {
    const hubspot = {};
    hubspot.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of [
              'createOrUpdateContact',
              'createOrUpdateCompany',
              'createTicket',
              'searchContacts',
              'searchCompanies',
              'sendEvent',
              'getContact',
              'createNote',
              'searchUsers',
              'updateContact',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'createOrUpdateContact',
              'createOrUpdateCompany',
              'createTicket',
              'searchContacts',
              'searchCompanies',
              'sendEvent',
              'getContact',
              'createNote',
              'searchUsers',
              'updateContact',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.createOrUpdateContact != null &&
              Object.hasOwnProperty.call(message, 'createOrUpdateContact')
            ) {
              $root.fetchactions.hubspot.v1.CreateOrUpdateContact.encode(
                message.createOrUpdateContact,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.createOrUpdateCompany != null &&
              Object.hasOwnProperty.call(message, 'createOrUpdateCompany')
            ) {
              $root.fetchactions.hubspot.v1.CreateOrUpdateCompany.encode(
                message.createOrUpdateCompany,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.createTicket != null &&
              Object.hasOwnProperty.call(message, 'createTicket')
            ) {
              $root.fetchactions.hubspot.v1.CreateTicket.encode(
                message.createTicket,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.searchContacts != null &&
              Object.hasOwnProperty.call(message, 'searchContacts')
            ) {
              $root.fetchactions.hubspot.v1.SearchContacts.encode(
                message.searchContacts,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.searchCompanies != null &&
              Object.hasOwnProperty.call(message, 'searchCompanies')
            ) {
              $root.fetchactions.hubspot.v1.SearchCompanies.encode(
                message.searchCompanies,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.sendEvent != null &&
              Object.hasOwnProperty.call(message, 'sendEvent')
            ) {
              $root.fetchactions.hubspot.v1.SendEvent.encode(
                message.sendEvent,
                writer.uint32(50).fork()
              ).ldelim();
            }
            if (
              message.getContact != null &&
              Object.hasOwnProperty.call(message, 'getContact')
            ) {
              $root.fetchactions.hubspot.v1.GetContact.encode(
                message.getContact,
                writer.uint32(58).fork()
              ).ldelim();
            }
            if (
              message.createNote != null &&
              Object.hasOwnProperty.call(message, 'createNote')
            ) {
              $root.fetchactions.hubspot.v1.CreateNote.encode(
                message.createNote,
                writer.uint32(66).fork()
              ).ldelim();
            }
            if (
              message.searchUsers != null &&
              Object.hasOwnProperty.call(message, 'searchUsers')
            ) {
              $root.fetchactions.hubspot.v1.SearchUsers.encode(
                message.searchUsers,
                writer.uint32(74).fork()
              ).ldelim();
            }
            if (
              message.updateContact != null &&
              Object.hasOwnProperty.call(message, 'updateContact')
            ) {
              $root.fetchactions.hubspot.v1.UpdateContact.encode(
                message.updateContact,
                writer.uint32(82).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.createOrUpdateContact =
                    $root.fetchactions.hubspot.v1.CreateOrUpdateContact.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.createOrUpdateCompany =
                    $root.fetchactions.hubspot.v1.CreateOrUpdateCompany.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.createTicket =
                    $root.fetchactions.hubspot.v1.CreateTicket.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.searchContacts =
                    $root.fetchactions.hubspot.v1.SearchContacts.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.searchCompanies =
                    $root.fetchactions.hubspot.v1.SearchCompanies.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  message.sendEvent =
                    $root.fetchactions.hubspot.v1.SendEvent.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 7: {
                  message.getContact =
                    $root.fetchactions.hubspot.v1.GetContact.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 8: {
                  message.createNote =
                    $root.fetchactions.hubspot.v1.CreateNote.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 9: {
                  message.searchUsers =
                    $root.fetchactions.hubspot.v1.SearchUsers.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 10: {
                  message.updateContact =
                    $root.fetchactions.hubspot.v1.UpdateContact.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.FetchAction();
            if (object.createOrUpdateContact != null) {
              if (typeof object.createOrUpdateContact !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.createOrUpdateContact: object expected, but got ' +
                    typeof object.createOrUpdateContact
                );
              }
              message.createOrUpdateContact =
                $root.fetchactions.hubspot.v1.CreateOrUpdateContact.fromObject(
                  object.createOrUpdateContact
                );
            }
            if (object.createOrUpdateCompany != null) {
              if (typeof object.createOrUpdateCompany !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.createOrUpdateCompany: object expected, but got ' +
                    typeof object.createOrUpdateCompany
                );
              }
              message.createOrUpdateCompany =
                $root.fetchactions.hubspot.v1.CreateOrUpdateCompany.fromObject(
                  object.createOrUpdateCompany
                );
            }
            if (object.createTicket != null) {
              if (typeof object.createTicket !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.createTicket: object expected, but got ' +
                    typeof object.createTicket
                );
              }
              message.createTicket =
                $root.fetchactions.hubspot.v1.CreateTicket.fromObject(
                  object.createTicket
                );
            }
            if (object.searchContacts != null) {
              if (typeof object.searchContacts !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.searchContacts: object expected, but got ' +
                    typeof object.searchContacts
                );
              }
              message.searchContacts =
                $root.fetchactions.hubspot.v1.SearchContacts.fromObject(
                  object.searchContacts
                );
            }
            if (object.searchCompanies != null) {
              if (typeof object.searchCompanies !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.searchCompanies: object expected, but got ' +
                    typeof object.searchCompanies
                );
              }
              message.searchCompanies =
                $root.fetchactions.hubspot.v1.SearchCompanies.fromObject(
                  object.searchCompanies
                );
            }
            if (object.sendEvent != null) {
              if (typeof object.sendEvent !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.sendEvent: object expected, but got ' +
                    typeof object.sendEvent
                );
              }
              message.sendEvent =
                $root.fetchactions.hubspot.v1.SendEvent.fromObject(
                  object.sendEvent
                );
            }
            if (object.getContact != null) {
              if (typeof object.getContact !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.getContact: object expected, but got ' +
                    typeof object.getContact
                );
              }
              message.getContact =
                $root.fetchactions.hubspot.v1.GetContact.fromObject(
                  object.getContact
                );
            }
            if (object.createNote != null) {
              if (typeof object.createNote !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.createNote: object expected, but got ' +
                    typeof object.createNote
                );
              }
              message.createNote =
                $root.fetchactions.hubspot.v1.CreateNote.fromObject(
                  object.createNote
                );
            }
            if (object.searchUsers != null) {
              if (typeof object.searchUsers !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.searchUsers: object expected, but got ' +
                    typeof object.searchUsers
                );
              }
              message.searchUsers =
                $root.fetchactions.hubspot.v1.SearchUsers.fromObject(
                  object.searchUsers
                );
            }
            if (object.updateContact != null) {
              if (typeof object.updateContact !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.FetchAction.updateContact: object expected, but got ' +
                    typeof object.updateContact
                );
              }
              message.updateContact =
                $root.fetchactions.hubspot.v1.UpdateContact.fromObject(
                  object.updateContact
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.createOrUpdateContact != null &&
              message.hasOwnProperty('createOrUpdateContact')
            ) {
              object.createOrUpdateContact =
                $root.fetchactions.hubspot.v1.CreateOrUpdateContact.toObject(
                  message.createOrUpdateContact,
                  options
                );
              if (options.oneofs) {
                object.type = 'createOrUpdateContact';
              }
            }
            if (
              message.createOrUpdateCompany != null &&
              message.hasOwnProperty('createOrUpdateCompany')
            ) {
              object.createOrUpdateCompany =
                $root.fetchactions.hubspot.v1.CreateOrUpdateCompany.toObject(
                  message.createOrUpdateCompany,
                  options
                );
              if (options.oneofs) {
                object.type = 'createOrUpdateCompany';
              }
            }
            if (
              message.createTicket != null &&
              message.hasOwnProperty('createTicket')
            ) {
              object.createTicket =
                $root.fetchactions.hubspot.v1.CreateTicket.toObject(
                  message.createTicket,
                  options
                );
              if (options.oneofs) {
                object.type = 'createTicket';
              }
            }
            if (
              message.searchContacts != null &&
              message.hasOwnProperty('searchContacts')
            ) {
              object.searchContacts =
                $root.fetchactions.hubspot.v1.SearchContacts.toObject(
                  message.searchContacts,
                  options
                );
              if (options.oneofs) {
                object.type = 'searchContacts';
              }
            }
            if (
              message.searchCompanies != null &&
              message.hasOwnProperty('searchCompanies')
            ) {
              object.searchCompanies =
                $root.fetchactions.hubspot.v1.SearchCompanies.toObject(
                  message.searchCompanies,
                  options
                );
              if (options.oneofs) {
                object.type = 'searchCompanies';
              }
            }
            if (
              message.sendEvent != null &&
              message.hasOwnProperty('sendEvent')
            ) {
              object.sendEvent =
                $root.fetchactions.hubspot.v1.SendEvent.toObject(
                  message.sendEvent,
                  options
                );
              if (options.oneofs) {
                object.type = 'sendEvent';
              }
            }
            if (
              message.getContact != null &&
              message.hasOwnProperty('getContact')
            ) {
              object.getContact =
                $root.fetchactions.hubspot.v1.GetContact.toObject(
                  message.getContact,
                  options
                );
              if (options.oneofs) {
                object.type = 'getContact';
              }
            }
            if (
              message.createNote != null &&
              message.hasOwnProperty('createNote')
            ) {
              object.createNote =
                $root.fetchactions.hubspot.v1.CreateNote.toObject(
                  message.createNote,
                  options
                );
              if (options.oneofs) {
                object.type = 'createNote';
              }
            }
            if (
              message.searchUsers != null &&
              message.hasOwnProperty('searchUsers')
            ) {
              object.searchUsers =
                $root.fetchactions.hubspot.v1.SearchUsers.toObject(
                  message.searchUsers,
                  options
                );
              if (options.oneofs) {
                object.type = 'searchUsers';
              }
            }
            if (
              message.updateContact != null &&
              message.hasOwnProperty('updateContact')
            ) {
              object.updateContact =
                $root.fetchactions.hubspot.v1.UpdateContact.toObject(
                  message.updateContact,
                  options
                );
              if (options.oneofs) {
                object.type = 'updateContact';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.FetchAction';
          }
        }

        FetchAction.prototype.createOrUpdateContact = null;
        FetchAction.prototype.createOrUpdateCompany = null;
        FetchAction.prototype.createTicket = null;
        FetchAction.prototype.searchContacts = null;
        FetchAction.prototype.searchCompanies = null;
        FetchAction.prototype.sendEvent = null;
        FetchAction.prototype.getContact = null;
        FetchAction.prototype.createNote = null;
        FetchAction.prototype.searchUsers = null;
        FetchAction.prototype.updateContact = null;

        return FetchAction;
      })();

      v1.CreateOrUpdateContact = (() => {
        class CreateOrUpdateContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateContact(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              Object.hasOwnProperty.call(message, 'doNotAssociateThirdPartyId')
            ) {
              writer.uint32(32).bool(message.doNotAssociateThirdPartyId);
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Contact.encode(
                message.result,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 4: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 5: {
                  message.result = hubspot$1.v1.Contact.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.fetchactions.hubspot.v1.CreateOrUpdateContact
            ) {
              return object;
            }
            const message =
              new $root.fetchactions.hubspot.v1.CreateOrUpdateContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateContact.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateOrUpdateContact.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateContact.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateOrUpdateContact.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(
                object.doNotAssociateThirdPartyId
              );
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateContact.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Contact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              message.hasOwnProperty('doNotAssociateThirdPartyId')
            ) {
              object.doNotAssociateThirdPartyId =
                message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Contact.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'fetchactions.hubspot.v1.CreateOrUpdateContact'
            );
          }
        }

        CreateOrUpdateContact.prototype.params = $util.emptyArray;
        CreateOrUpdateContact.prototype.configs = $util.emptyArray;
        CreateOrUpdateContact.prototype.doNotAssociateThirdPartyId = false;
        CreateOrUpdateContact.prototype.result = null;

        return CreateOrUpdateContact;
      })();

      v1.UpdateContact = (() => {
        class UpdateContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateContact(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              Object.hasOwnProperty.call(
                message,
                'requiredThirdPartyIdMissingBehavior'
              )
            ) {
              writer
                .uint32(24)
                .int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              Object.hasOwnProperty.call(message, 'doNotAssociateThirdPartyId')
            ) {
              writer.uint32(32).bool(message.doNotAssociateThirdPartyId);
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Contact.encode(
                message.result,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 5: {
                  message.result = hubspot$1.v1.Contact.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.UpdateContact) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.UpdateContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.UpdateContact.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.UpdateContact.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.UpdateContact.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.UpdateContact.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR':
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW':
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH':
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE':
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case 'REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE':
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (
                    typeof object.requiredThirdPartyIdMissingBehavior ==
                    'number'
                  ) {
                    message.requiredThirdPartyIdMissingBehavior =
                      object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(
                object.doNotAssociateThirdPartyId
              );
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.UpdateContact.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Contact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? 'UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR'
                  : 0;
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (
              message.requiredThirdPartyIdMissingBehavior != null &&
              message.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
            ) {
              object.requiredThirdPartyIdMissingBehavior =
                options.enums === String
                  ? workflow_parameters$1.v1.ParameterMissingPolicy
                      .RequiredParameterMissingBehavior[
                      message.requiredThirdPartyIdMissingBehavior
                    ] === undefined
                    ? message.requiredThirdPartyIdMissingBehavior
                    : workflow_parameters$1.v1.ParameterMissingPolicy
                        .RequiredParameterMissingBehavior[
                        message.requiredThirdPartyIdMissingBehavior
                      ]
                  : message.requiredThirdPartyIdMissingBehavior;
            }
            if (
              message.doNotAssociateThirdPartyId != null &&
              message.hasOwnProperty('doNotAssociateThirdPartyId')
            ) {
              object.doNotAssociateThirdPartyId =
                message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Contact.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.UpdateContact';
          }
        }

        UpdateContact.prototype.params = $util.emptyArray;
        UpdateContact.prototype.configs = $util.emptyArray;
        UpdateContact.prototype.requiredThirdPartyIdMissingBehavior = 0;
        UpdateContact.prototype.doNotAssociateThirdPartyId = false;
        UpdateContact.prototype.result = null;

        return UpdateContact;
      })();

      v1.CreateOrUpdateCompany = (() => {
        class CreateOrUpdateCompany {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateCompany(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Company.encode(
                message.result,
                writer.uint32(34).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateCompany();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 4: {
                  message.result = hubspot$1.v1.Company.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.fetchactions.hubspot.v1.CreateOrUpdateCompany
            ) {
              return object;
            }
            const message =
              new $root.fetchactions.hubspot.v1.CreateOrUpdateCompany();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateCompany.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateOrUpdateCompany.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateCompany.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateOrUpdateCompany.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateOrUpdateCompany.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Company.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Company.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'fetchactions.hubspot.v1.CreateOrUpdateCompany'
            );
          }
        }

        CreateOrUpdateCompany.prototype.params = $util.emptyArray;
        CreateOrUpdateCompany.prototype.configs = $util.emptyArray;
        CreateOrUpdateCompany.prototype.result = null;

        return CreateOrUpdateCompany;
      })();

      v1.CreateTicket = (() => {
        class CreateTicket {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateTicket(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Ticket.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateTicket();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = hubspot$1.v1.Ticket.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.CreateTicket) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.CreateTicket();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateTicket.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateTicket.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateTicket.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateTicket.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateTicket.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Ticket.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Ticket.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.CreateTicket';
          }
        }

        CreateTicket.prototype.params = $util.emptyArray;
        CreateTicket.prototype.configs = $util.emptyArray;
        CreateTicket.prototype.result = null;

        return CreateTicket;
      })();

      v1.SearchContacts = (() => {
        class SearchContacts {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchContacts(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              for (const element of message.result) {
                hubspot$1.v1.Contact.encode(
                  element,
                  writer.uint32(26).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchContacts();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(
                    hubspot$1.v1.Contact.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.hubspot.v1.SearchContacts
            ) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.SearchContacts();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchContacts.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchContacts.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchContacts.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchContacts.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchContacts.result: array type expected, but got ' +
                    typeof object.result
                );
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchContacts.result: object expected, but got ' +
                      typeof object.result[i]
                  );
                }
                message.result[i] = hubspot$1.v1.Contact.fromObject(
                  object.result[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = hubspot$1.v1.Contact.toObject(
                  message.result[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.SearchContacts';
          }
        }

        SearchContacts.prototype.params = $util.emptyArray;
        SearchContacts.prototype.configs = $util.emptyArray;
        SearchContacts.prototype.result = $util.emptyArray;

        return SearchContacts;
      })();

      v1.SearchCompanies = (() => {
        class SearchCompanies {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchCompanies(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              for (const element of message.result) {
                hubspot$1.v1.Company.encode(
                  element,
                  writer.uint32(26).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchCompanies();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(
                    hubspot$1.v1.Company.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.fetchactions.hubspot.v1.SearchCompanies
            ) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.SearchCompanies();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchCompanies.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchCompanies.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchCompanies.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchCompanies.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchCompanies.result: array type expected, but got ' +
                    typeof object.result
                );
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchCompanies.result: object expected, but got ' +
                      typeof object.result[i]
                  );
                }
                message.result[i] = hubspot$1.v1.Company.fromObject(
                  object.result[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = hubspot$1.v1.Company.toObject(
                  message.result[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.SearchCompanies';
          }
        }

        SearchCompanies.prototype.params = $util.emptyArray;
        SearchCompanies.prototype.configs = $util.emptyArray;
        SearchCompanies.prototype.result = $util.emptyArray;

        return SearchCompanies;
      })();

      v1.SendEvent = (() => {
        class SendEvent {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SendEvent(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              google$1.protobuf.Empty.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SendEvent();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = google$1.protobuf.Empty.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.SendEvent) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.SendEvent();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SendEvent.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SendEvent.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SendEvent.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SendEvent.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SendEvent.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = google$1.protobuf.Empty.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = google$1.protobuf.Empty.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.SendEvent';
          }
        }

        SendEvent.prototype.params = $util.emptyArray;
        SendEvent.prototype.configs = $util.emptyArray;
        SendEvent.prototype.result = null;

        return SendEvent;
      })();

      v1.GetContact = (() => {
        class GetContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetContact(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Contact.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = hubspot$1.v1.Contact.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.GetContact) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.GetContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.GetContact.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.GetContact.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.GetContact.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.GetContact.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.GetContact.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Contact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Contact.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.GetContact';
          }
        }

        GetContact.prototype.params = $util.emptyArray;
        GetContact.prototype.configs = $util.emptyArray;
        GetContact.prototype.result = null;

        return GetContact;
      })();

      v1.CreateNote = (() => {
        class CreateNote {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateNote(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              hubspot$1.v1.Note.encode(
                message.result,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateNote();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.result = hubspot$1.v1.Note.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.CreateNote) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.CreateNote();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateNote.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateNote.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateNote.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.CreateNote.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.CreateNote.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = hubspot$1.v1.Note.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = hubspot$1.v1.Note.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.CreateNote';
          }
        }

        CreateNote.prototype.params = $util.emptyArray;
        CreateNote.prototype.configs = $util.emptyArray;
        CreateNote.prototype.result = null;

        return CreateNote;
      })();

      v1.SearchUsers = (() => {
        class SearchUsers {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchUsers(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              for (const element of message.result) {
                hubspot$1.v1.User.encode(
                  element,
                  writer.uint32(26).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchUsers();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(
                    hubspot$1.v1.User.decode(reader, reader.uint32())
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.hubspot.v1.SearchUsers) {
              return object;
            }
            const message = new $root.fetchactions.hubspot.v1.SearchUsers();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchUsers.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchUsers.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchUsers.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchUsers.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(
                  '.fetchactions.hubspot.v1.SearchUsers.result: array type expected, but got ' +
                    typeof object.result
                );
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.hubspot.v1.SearchUsers.result: object expected, but got ' +
                      typeof object.result[i]
                  );
                }
                message.result[i] = hubspot$1.v1.User.fromObject(
                  object.result[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = hubspot$1.v1.User.toObject(
                  message.result[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.hubspot.v1.SearchUsers';
          }
        }

        SearchUsers.prototype.params = $util.emptyArray;
        SearchUsers.prototype.configs = $util.emptyArray;
        SearchUsers.prototype.result = $util.emptyArray;

        return SearchUsers;
      })();

      return v1;
    })();

    return hubspot;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;

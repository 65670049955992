import _ from 'lodash';
import { ActionExecutionTimelineProps } from './types';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { NoMoreTimelineRows } from '../NoMoreTimelineRows';
import { Text } from '../Typography';
import { createScrollHandler } from '../Helpers/createScrollHandler';
import { useTheme } from '@morf/theming';
import {
  ActionExecutionRow,
  ActionExecutionRowLoading,
} from './ActionExecutionRow';

const ActionExecutionTimeline: FC<ActionExecutionTimelineProps> = ({
  actionExecutionRows,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  isRefetching,
  isRetrying,
  timeOption,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoadingAnotherHour, setIsLoadingAnotherHour] =
    useState<boolean>(false);

  const initialLoading =
    isLoading ||
    isRetrying ||
    (isFetching && isRefetching && !isLoadingAnotherHour);

  const handleScroll = createScrollHandler({
    containerRef,
    callback: fetchNextPage,
  });

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='action-execution-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {initialLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <ActionExecutionRowLoading key={index} />
        ))
      ) : _.isEmpty(actionExecutionRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No actions
          </Text>
        </Flexbox>
      ) : (
        <>
          {actionExecutionRows.map((row, index) => (
            <ActionExecutionRow key={index} {...row} />
          ))}

          {hasNextPage
            ? isFetching && (
                <>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <ActionExecutionRowLoading key={index} />
                  ))}
                </>
              )
            : fetchNextPage && (
                <NoMoreTimelineRows
                  setIsLoadingAnotherHour={setIsLoadingAnotherHour}
                  timeOption={timeOption}
                  type='actions'
                />
              )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedActionExecutionTimeline = memo(ActionExecutionTimeline);

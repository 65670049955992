export const Staging = () => {
  return (
    <>
      <rect width='24' height='24' rx='12' fill='#F1C921' />
      <path
        d='M11.6129 14.1129L15.5 18C16.1904 18.6904 17.3096 18.6904 18 18C18.6904 17.3096 18.6904 16.1904 18 15.5L14.0822 11.5822M11.6129 14.1129L13.277 12.0922C13.4877 11.8364 13.7694 11.6755 14.0822 11.5822M11.6129 14.1129L8.50955 17.8813C8.18691 18.273 7.70598 18.5 7.19846 18.5C6.26043 18.5 5.5 17.7396 5.5 16.8015C5.5 16.294 5.72696 15.8131 6.11874 15.4905L10.6764 11.7371M14.0822 11.5822C14.4485 11.473 14.8572 11.4565 15.2439 11.4892C15.3283 11.4964 15.4137 11.5 15.5 11.5C17.1569 11.5 18.5 10.1569 18.5 8.5C18.5 8.05982 18.4052 7.64178 18.2349 7.26519L16.0508 9.44931C15.307 9.27857 14.7215 8.69309 14.5508 7.94931L16.7349 5.76516C16.3583 5.59482 15.9402 5.5 15.5 5.5C13.8431 5.5 12.5 6.84315 12.5 8.5C12.5 8.58626 12.5036 8.67166 12.5108 8.75607C12.5714 9.47304 12.4632 10.2656 11.9078 10.723L11.8397 10.7791M10.6764 11.7371L7.93934 9H7L5.5 6.5L6.5 5.5L9 7V7.93934L11.8397 10.7791M10.6764 11.7371L11.8397 10.7791M16.25 16.25L14.5 14.5M7.24482 16.75H7.24982V16.755H7.24482V16.75Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const Production = () => {
  return (
    <>
      <rect width='24' height='24' rx='12' fill='#818EF9' />
      <path
        d='M14.3936 13.5797C14.4632 13.8752 14.5 14.1833 14.5 14.5C14.5 16.7091 12.7091 18.5 10.5 18.5V15.3002M14.3936 13.5797C16.8829 11.7607 18.5 8.81916 18.5 5.5C15.181 5.50014 12.2396 7.11724 10.4208 9.6065M14.3936 13.5797C13.2546 14.4121 11.9329 15.0093 10.5 15.3002M10.4208 9.6065C10.1252 9.53685 9.81689 9.5 9.5 9.5C7.29086 9.5 5.5 11.2909 5.5 13.5H8.70048M10.4208 9.6065C9.58857 10.7455 8.99134 12.0671 8.70048 13.5M10.5 15.3002C10.4311 15.3142 10.3619 15.3275 10.2924 15.3401C9.6878 14.8607 9.13994 14.3128 8.66053 13.7082C8.67313 13.6385 8.68645 13.5691 8.70048 13.5M7.20794 15.0938C6.47476 15.6408 6 16.5149 6 17.5C6 17.6579 6.0122 17.813 6.03571 17.9643C6.18703 17.9878 6.34209 18 6.5 18C7.48505 18 8.35922 17.5252 8.90616 16.7921M15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

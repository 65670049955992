import _ from 'lodash';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { NoMoreTimelineRows } from '../NoMoreTimelineRows';
import { Text } from '../Typography';
import { WorkflowExecutionTimelineProps } from './types';
import { createScrollHandler } from '../Helpers/createScrollHandler';
import { useTheme } from '@morf/theming';
import {
  WorkflowExecutionRow,
  WorkflowExecutionRowLoading,
} from './WorkflowExecutionRow';

const WorkflowExecutionTimeline: FC<WorkflowExecutionTimelineProps> = ({
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  isRefetching,
  isRetrying,
  timeOption,
  workflowExecutionRows,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoadingAnotherHour, setIsLoadingAnotherHour] =
    useState<boolean>(false);

  const initialLoading =
    isLoading ||
    isRetrying ||
    (isFetching && isRefetching && !isLoadingAnotherHour);

  const handleScroll = createScrollHandler({
    containerRef,
    callback: fetchNextPage,
  });

  const uniqueWorkflowExecutionRows = _.uniqBy(
    workflowExecutionRows,
    'workflowExecutionId'
  );

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='workflow-execution-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {initialLoading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <WorkflowExecutionRowLoading key={index} />
        ))
      ) : _.isEmpty(workflowExecutionRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No executions
          </Text>
        </Flexbox>
      ) : (
        <>
          {uniqueWorkflowExecutionRows.map((row) => (
            <WorkflowExecutionRow key={row.workflowExecutionId} {...row} />
          ))}

          {hasNextPage ? (
            isFetching && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <WorkflowExecutionRowLoading key={index} />
                ))}
              </>
            )
          ) : (
            <NoMoreTimelineRows
              setIsLoadingAnotherHour={setIsLoadingAnotherHour}
              timeOption={timeOption}
              type='executions'
            />
          )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionTimeline = memo(
  WorkflowExecutionTimeline
);

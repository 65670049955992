import _ from 'lodash';
import { Checkbox } from '../../../Checkbox';
import { ErrorClass } from '../../../ErrorClass';
import { FC, memo } from 'react';
import { FilterOptionProps } from './types';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { Text } from '../../../Typography';
import { useTheme } from '@morf/theming';
import { Container } from '../../../Container';

const FilterOption: FC<FilterOptionProps> = ({
  isChecked,
  onClick,
  option,
}) => {
  const theme = useTheme();
  const { id, name } = option;
  const handleClick = () => {
    onClick(option);
  };

  const renderIcon =
    'integrationIconName' in option && option.integrationIconName ? (
      <IntegrationIcon name={option.integrationIconName} size={1} />
    ) : 'icon' in option && option.icon ? (
      <Icon {...option.icon} size={1} />
    ) : (
      <ErrorClass errorClass={_.toNumber(option.id)} />
    );

  return (
    <Flexbox
      data-testid={id + '-option'}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      cursor='pointer'
      height='auto'
      onClick={handleClick}
      hoverBackgroundColor={theme.colors.main.light.light}
      borderRadius={0.25}
      p={0.25}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={0.25}
      >
        <Container height='auto' width='auto'>
          {renderIcon}
        </Container>
        <Text tag='p3'>{name}</Text>
      </Flexbox>
      <Checkbox name={id + '-checkbox'} checked={isChecked} />
    </Flexbox>
  );
};

export const MemoizedFilterOption = memo(FilterOption);

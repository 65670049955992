import { debounce, DebouncedFunc } from 'lodash';

type ScrollHandlerOptions = {
  containerRef: React.RefObject<HTMLElement>;
  callback?: () => void; // Function to execute when the scroll threshold is met.
  scrollElementThresholdPixels?: number; // Buffer for triggering the callback slightly before reaching the bottom.
  debounceTime?: number; // Debounce delay in milliseconds.
};

export const createScrollHandler = ({
  containerRef,
  callback,
  scrollElementThresholdPixels = 5,
  debounceTime = 100,
}: ScrollHandlerOptions): DebouncedFunc<() => void> => {
  return debounce(() => {
    const containerElement = containerRef.current;

    if (callback && containerElement) {
      const scrollDistanceRemainingPixels =
        containerElement.scrollHeight -
        containerElement.scrollTop -
        containerElement.clientHeight;

      if (scrollDistanceRemainingPixels <= scrollElementThresholdPixels) {
        callback();
      }
    }
  }, debounceTime);
};

import { ActionNode } from './CustomNode/ProfileLookupNode/ActionNode';
import { AddEdge } from './CustomEdge/AddEdge';
import { BranchNode } from './CustomNode/ProfileLookupNode/BranchNode';
import { Button } from '../Button';
import { DestinationActionNode } from './CustomNode/DestinationActionNode';
import { EdgeType } from './CustomEdge/types';
import { ErrorNode } from './CustomNode/ProfileLookupNode/ErrorNode';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FetchActionNode } from './CustomNode/FetchActionNode';
import { FieldNode } from './CustomNode/ProfileLookupNode/FieldNode.tsx';
import { FilterNode } from './CustomNode/FilterNode';
import { HiddenNode } from './CustomNode/HiddenNode';
import { Icon } from '../Icon';
import { LabelEdge } from './CustomEdge/LabelEdge';
import { PauseNode } from './CustomNode/PauseNode';
import { ProfileConvertNode } from './CustomNode/ProfileConvertNode';
import { ProfileLookupNode } from './CustomNode/ProfileLookupNode';
import { ProfileLookupNodeType } from './CustomNode/ProfileLookupNode/types';
import { ProfileUpdateNode } from './CustomNode/ProfileUpdateNode';
import { RestartNode } from './CustomNode/RestartNode';
import { RetryNode } from './CustomNode/ProfileLookupNode/RetryNode';
import { ReturnWorkflowNode } from './CustomNode/ProfileLookupNode/ReturnWorkflowNode';
import { SimpleEdge } from './CustomEdge/SimpleEdge';
import { StyledWorkflow } from './Workflow.css';
import { SupportNodeType } from './CustomNode/types';
import { TerminateNode } from './CustomNode/ProfileLookupNode/TerminateNode';
import { Text } from '../Typography';
import { TrackProfilePropertiesNode } from './CustomNode/TrackProfilePropertiesNode';
import { TriggerNode } from './CustomNode/TriggerNode';
import { WorkflowProps, NodeType } from './types';
import { getDefaultEdgeOptions } from './CustomEdge/getDefaultEdgeOptions';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';
import { useWorkflow } from '../../../apps/admin/components/context/workflow/useWorkflow';
import {
  Background,
  Connection,
  Controls,
  Edge,
  FitViewOptions,
  Node,
  NodeTypes,
  Panel,
  ReactFlowInstance,
  addEdge,
} from 'reactflow';

const Workflow: FC<WorkflowProps> = ({
  description,
  isLoading,
  showEventPayload,
  ...props
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const { showCELPlayground } = useUrlParams();

  const {
    nodes: initialNodes,
    edges: initalEdges,
    setEdges,
    selectedNode,
  } = useWorkflow();

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);

  const nodes = isLoading ? [] : initialNodes;
  const edges = isLoading ? [] : initalEdges;

  const fitViewOptions: FitViewOptions = {
    maxZoom: 0.75,
    includeHiddenNodes: true,
    nodes: selectedNode && [{ id: selectedNode.id }],
  };

  const edgeTypes = useMemo(
    () => ({
      [EdgeType.AddEdge]: AddEdge,
      [EdgeType.LabelEdge]: LabelEdge,
      [EdgeType.SimpleEdge]: SimpleEdge,
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      [NodeType.DestinationActionNode]: DestinationActionNode,
      [NodeType.FetchActionNode]: FetchActionNode,
      [NodeType.FilterNode]: FilterNode,
      [NodeType.PauseNode]: PauseNode,
      [NodeType.ProfileConvertNode]: ProfileConvertNode,
      [NodeType.ProfileLookupNode]: ProfileLookupNode,
      [NodeType.ProfileUpdateNode]: ProfileUpdateNode,
      [NodeType.RestartNode]: RestartNode,
      [NodeType.TrackProfilePropertiesNode]: TrackProfilePropertiesNode,
      [NodeType.TriggerNode]: TriggerNode,
      [ProfileLookupNodeType.ActionNode]: ActionNode,
      [ProfileLookupNodeType.BranchNode]: BranchNode,
      [ProfileLookupNodeType.ErrorNode]: ErrorNode,
      [ProfileLookupNodeType.FieldNode]: FieldNode,
      [ProfileLookupNodeType.RetryNode]: RetryNode,
      [ProfileLookupNodeType.ReturnWorkflowNode]: ReturnWorkflowNode,
      [ProfileLookupNodeType.TerminateNode]: TerminateNode,
      [SupportNodeType.HiddenNode]: HiddenNode,
    }),
    []
  ) as unknown as NodeTypes;

  const handleConnect = useCallback(
    (params: Edge | Connection) =>
      setEdges((eds) => addEdge({ ...params }, eds)),
    []
  );

  const handleInit = (reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
  };

  const handleNodeClick = (_event: React.MouseEvent, node: Node) => {
    if (node.selectable) {
      updateUrlParam({ panelNodeId: node.id });
    }
  };

  const handleShowCELPlayground = () => {
    updateUrlParam({ showCELPlayground: 'true' });
  };

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => reactFlowInstance.fitView(fitViewOptions), 5);
    }
  }, [reactFlowInstance, selectedNode]);

  return (
    <StyledWorkflow
      defaultEdgeOptions={getDefaultEdgeOptions(theme)}
      edgeTypes={edgeTypes}
      edges={edges}
      fitView={true}
      fitViewOptions={fitViewOptions}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesDraggable={false}
      onConnect={handleConnect}
      onInit={handleInit}
      onNodeClick={handleNodeClick}
      onlyRenderVisibleElements={false}
      {...props}
    >
      <Background />
      {description && !isLoading && (
        <Panel position='bottom-right'>
          <Text tag='h6' color={theme.colors.ui.dark}>
            {description}
          </Text>
        </Panel>
      )}

      <Controls
        position='top-left'
        showFitView={false}
        showInteractive={false}
        showZoom={false}
      >
        {showEventPayload && showCELPlayground !== 'true' && (
          <Button
            variant='tertiary'
            size='base'
            shape='square'
            onClick={handleShowCELPlayground}
          >
            <Icon
              size={1.25}
              name='code-bracket'
              stroke={theme.colors.ui.dark}
            />
          </Button>
        )}
      </Controls>
    </StyledWorkflow>
  );
};

export const MemoizedWorkflow = memo(Workflow);

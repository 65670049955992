export const PanelBottom = () => {
  return (
    <path
      d='M3.5 14.5V8.81818V6.90909C3.5 5.85473 4.50736 5 5.75 5H16.5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V14.5M3.5 14.5V17.0909C3.5 18.1453 4.50736 19 5.75 19H16.5H19.25C20.4926 19 21.5 18.1453 21.5 17.0909V14.5M3.5 14.5H21.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const PanelLeft = () => {
  return (
    <path
      d='M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5M8.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H8.5M8.5 5V19'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const SolidPanelLeft = () => {
  return (
    <>
      <path
        d='M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5M8.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H8.5M8.5 5V19'
        stroke='#3C4CEC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5V5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818Z'
        fill='#3C4CEC'
      />
      <rect
        x='4.25'
        y='15'
        width='3.5'
        height='1.5'
        rx='0.75'
        fill='white'
        stroke='none'
      />
      <rect
        x='4.25'
        y='11'
        width='3.5'
        height='1.5'
        rx='0.75'
        fill='white'
        stroke='none'
      />
      <rect
        x='4.25'
        y='7'
        width='3.5'
        height='1.5'
        rx='0.75'
        fill='white'
        stroke='none'
      />
    </>
  );
};

export const PanelTop = () => {
  return (
    <path
      d='M3.5 9.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5H19.25C20.4926 19 21.5 18.1453 21.5 17.0909V9.81818M3.5 9.81818V6.90909C3.5 5.85473 4.50736 5 5.75 5H8.5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V9.81818M3.5 9.81818H21.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

export const SolidPanelTop = () => {
  return (
    <>
      <path
        d='M3.5 8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H8.5H19.25C20.4926 19 21.5 18.1453 21.5 17.0909V8.81818M3.5 8.81818V6.90909C3.5 5.85473 4.50736 5 5.75 5H8.5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818M3.5 8.81818H21.5'
        stroke='#3C4CEC'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 6.90909V9.81818H21V6.90909C21 5.85473 19.9926 5 18.75 5H8H5.25C4.00736 5 3 5.85473 3 6.90909ZM10.5 8C9.94772 8 9.5 7.55228 9.5 7C9.5 6.44772 9.94772 6 10.5 6L18.5 6C19.0523 6 19.5 6.44772 19.5 7C19.5 7.55228 19.0523 8 18.5 8L10.5 8ZM4.5 7C4.5 7.55228 4.94772 8 5.5 8H6.5C7.05228 8 7.5 7.55228 7.5 7C7.5 6.44772 7.05228 6 6.5 6L5.5 6C4.94772 6 4.5 6.44772 4.5 7Z'
        fill='#3C4CEC'
        stroke='none'
      />
    </>
  );
};

export const PanelLeftSlash = () => {
  return (
    <>
      <path
        d='M21.5 8.81818V9V17.0909C21.5 18.1453 20.4926 19 19.25 19H11.5M18.5 5H18H8.5M8.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 17.7528 3.89701 18.336 4.5 18.6785M8.5 5V15'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 3L18.8363 4.66365L4 19.5L2.5 21'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const PanelRight = () => {
  return (
    <path
      d='M16.5 5H19.25C20.4926 5 21.5 5.85473 21.5 6.90909V8.81818V17.0909C21.5 18.1453 20.4926 19 19.25 19H16.5M16.5 5H5.75C4.50736 5 3.5 5.85473 3.5 6.90909V8.81818V17.0909C3.5 18.1453 4.50736 19 5.75 19H16.5M16.5 5V19'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );
};

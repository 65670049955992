import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { TimelineSelectionPlaceholderProps } from './types';
import { useTheme } from '@morf/theming';

const TimelineSelectionPlaceholder: FC<TimelineSelectionPlaceholderProps> = ({
  width,
  type,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='empty-timeline'
      direction='column'
      justifyContent='center'
      alignItems='center'
      width={width}
      gap={0.5}
    >
      <Icon name='folder-open' stroke={theme.colors.ui.dark} />
      <Text tag='p2' color={theme.colors.text.muted}>
        Select an {type}
      </Text>
    </Flexbox>
  );
};

export const MemoizedTimelineSelectionPlaceholder = memo(
  TimelineSelectionPlaceholder
);

import moment from 'moment';
import { TimeOption } from './TimeDropdown/TimeOption/types';
import { dateToTimestamp } from '../../../../apps/admin/components/helpers/formatValue/dateToTimestamp';
import {
  defaultDateTimeFormatWithSeconds,
  defaultDateTimeFormatWithSecondsUTC,
} from './constants';

const parseUTCDateTimeString = (dateString: string): Date | undefined => {
  const date = moment(dateString, defaultDateTimeFormatWithSecondsUTC).toDate();
  return moment(date).isValid() ? date : undefined;
};

const formatToDefaultDateTime = (date: Date): string => {
  const timezone = moment.tz.guess();
  return moment(date).tz(timezone).format(defaultDateTimeFormatWithSeconds);
};

export const parseTimeRange = (label: string): TimeOption | undefined => {
  const [trimmedStart, trimmedEnd] = label.split(' - ').map((t) => t.trim());
  const startDate = parseUTCDateTimeString(trimmedStart);
  const endDate = parseUTCDateTimeString(trimmedEnd);

  if (!startDate || !endDate) {
    return undefined;
  }

  const formattedLabel = `${formatToDefaultDateTime(
    startDate
  )} - ${formatToDefaultDateTime(endDate)}`;

  return {
    label: formattedLabel,
    timeRange: {
      startTimestamp: dateToTimestamp(startDate),
      endTimestamp: dateToTimestamp(endDate),
    },
  };
};

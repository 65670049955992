import _ from 'lodash';
import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { getHoverDescription } from './getHoverDescription';
import { useTheme } from '@morf/theming';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';
import {
  WorkflowExecutionStatusDetails,
  WorkflowExecutionStatusProps,
} from './types';

const WorkflowExecutionStatus: FC<WorkflowExecutionStatusProps> = ({
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  supersededByWorkflowExecutionId,
  supersededWorkflowExecutionId,
  variant,
}) => {
  const theme = useTheme();

  const retryingHoverDescription = getHoverDescription(
    `Retry ${_.toString(retryNumber)} of`,
    retryOriginalWorkflowExecutionId
  );

  const supersededHoverDescription = getHoverDescription(
    'Superseded by',
    supersededByWorkflowExecutionId
  );

  const executedHoverDescription = retryOriginalWorkflowExecutionId
    ? getHoverDescription('Executed retry of', retryOriginalWorkflowExecutionId)
    : supersededWorkflowExecutionId
    ? getHoverDescription(
        'Executed superseder of',
        supersededWorkflowExecutionId
      )
    : getHoverDescription('Executed');

  const executedIcon = retryOriginalWorkflowExecutionId
    ? 'bolt-arrow'
    : supersededWorkflowExecutionId
    ? 'bolt-double'
    : 'bolt';

  const failedHoverDescription = retryOriginalWorkflowExecutionId
    ? getHoverDescription('Failed retry of', retryOriginalWorkflowExecutionId)
    : supersededWorkflowExecutionId
    ? getHoverDescription('Failed superseder of', supersededWorkflowExecutionId)
    : getHoverDescription('Failed');

  const failedIcon = retryOriginalWorkflowExecutionId
    ? 'bolt-arrow-slash'
    : supersededWorkflowExecutionId
    ? 'bolt-double-slash'
    : 'bolt-slash';

  const statusMap: Record<
    workflow_monitoring.v2.WorkflowExecutionStatus,
    WorkflowExecutionStatusDetails
  > = {
    [workflow_monitoring.v2.WorkflowExecutionStatus
      .UNSPECIFIED_WORKFLOW_EXECUTION_STATUS]: {
      description: 'Unspecified',
      hoverDescription: getHoverDescription('Unspecified'),
      icon: <></>,
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.SKIPPED]: {
      description: 'Skipped',
      hoverDescription: getHoverDescription('Skipped'),
      icon: (
        <Icon
          name='bolt-slash'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.TRIGGERED]: {
      description: 'Triggered',
      hoverDescription: getHoverDescription('Triggered'),
      icon: (
        <Icon
          name='bolt'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.PROCESSING]: {
      description: 'Processing',
      hoverDescription: getHoverDescription('Processing...'),
      icon: (
        <Icon
          name='bolt'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.RETRYING]: {
      description: 'Retrying',
      hoverDescription: retryingHoverDescription,
      icon: (
        <Icon
          name='bolt-arrow'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.SUPERSEDED]: {
      description: 'Superseded',
      hoverDescription: supersededHoverDescription,
      icon: (
        <Icon
          name='bolt-double'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.EXECUTED]: {
      description: 'Executed',
      hoverDescription: executedHoverDescription,
      icon: (
        <Icon
          name={executedIcon}
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.green.darkest}
        />
      ),
    },
    [workflow_monitoring.v2.WorkflowExecutionStatus.FAILED]: {
      description: 'Failed',
      hoverDescription: failedHoverDescription,
      icon: (
        <Icon
          name={failedIcon}
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.red.darkest}
        />
      ),
    },
  };

  const { description, hoverDescription, icon } = statusMap[status];

  return (
    <Flexbox
      data-testid='workflow-execution-status'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      width='auto'
      gap={0.25}
    >
      <Container height='auto' width='auto'>
        {icon}
      </Container>
      {variant === 'icon-description' && <Text tag='p3'>{description}</Text>}
      {variant === 'icon-hover-description' && hoverDescription}
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionStatus = memo(WorkflowExecutionStatus);

'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { accounts as accounts$1 } from './accounts_v1_ts_proto';
import { google as google$3 } from './checked_ts_proto';
import { destinationactions as destinationactions$1 } from './destinationactions/activecampaign/v1_ts_proto';
import { destinationactions as destinationactions$2 } from './destinationactions/customerio/v1_ts_proto';
import { destinationactions as destinationactions$3 } from './destinationactions/healthie/v1_ts_proto';
import { destinationactions as destinationactions$5 } from './destinationactions/intakeq/v1_ts_proto';
import { destinationactions as destinationactions$4 } from './destinationactions/morf/v1_ts_proto';
import { destinationactions as destinationactions$6 } from './destinationactions/segment/v1_ts_proto';
import { destinationactions as destinationactions$8 } from './destinationactions/slack/v1_ts_proto';
import { destinationactions as destinationactions$7 } from './destinationactions/spruce/v1_ts_proto';
import { google as google$1 } from './empty_ts_proto';
import { event_payload as event_payload$1 } from './event_payload_v2_ts_proto';
import { fetchactions as fetchactions$6 } from './fetchactions/facebook/v1_ts_proto';
import { fetchactions as fetchactions$2 } from './fetchactions/freshdesk/v1_ts_proto';
import { fetchactions as fetchactions$7 } from './fetchactions/google/v1_ts_proto';
import { fetchactions as fetchactions$1 } from './fetchactions/healthie/v1_ts_proto';
import { fetchactions as fetchactions$4 } from './fetchactions/hubspot/v1_ts_proto';
import { fetchactions as fetchactions$5 } from './fetchactions/intercom/v1_ts_proto';
import { fetchactions as fetchactions$3 } from './fetchactions/morf/v1_ts_proto';
import { object_category as object_category$1 } from './object_category_v1_ts_proto';
import { profiles as profiles$1 } from './profiles_v1_ts_proto';
import { source_application as source_application$1 } from './source_application_v2_ts_proto';
import { sources as sources$1 } from './sources_v2_ts_proto';
import { google as google$2 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { workflow_destination_applications as workflow_destination_applications$1 } from './workflow_destination_applications_v1_ts_proto';
import { workflow_evaluation_results as workflow_evaluation_results$1 } from './workflow_evaluation_results_v1_ts_proto';
import { workflow_extra_fetched_data as workflow_extra_fetched_data$1 } from './workflow_extra_fetched_data_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from './workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflows = (() => {
  const workflows = {};
  workflows.v1 = (() => {
    const v1 = {};
    v1.CreateRequest = (() => {
      class CreateRequest {
        constructor(properties) {
          this.tags = [];
          this.triggerObjectTypeFilters = [];
          this.nodes = [];
          this.edges = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.sourceTriggerEvents != null &&
            Object.hasOwnProperty.call(message, 'sourceTriggerEvents')
          ) {
            sources$1.v2.CreateRequest.encode(
              message.sourceTriggerEvents,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.triggerObjectCategory != null &&
            Object.hasOwnProperty.call(message, 'triggerObjectCategory')
          ) {
            writer.uint32(40).int32(message.triggerObjectCategory);
          }
          if (
            message.triggerObjectTypeFilters != null &&
            Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
          ) {
            for (const element of message.triggerObjectTypeFilters) {
              writer.uint32(50).string(element);
            }
          }
          if (
            message.triggerExtraCelExpression != null &&
            Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
          ) {
            writer.uint32(58).string(message.triggerExtraCelExpression);
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            for (const element of message.nodes) {
              $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                element,
                writer.uint32(74).fork()
              ).ldelim();
            }
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            for (const element of message.edges) {
              $root.workflows.v1.WorkflowEdge.encode(
                element,
                writer.uint32(82).fork()
              ).ldelim();
            }
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(90).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 4: {
                message.sourceTriggerEvents = sources$1.v2.CreateRequest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.triggerObjectCategory = reader.int32();
                break;
              }
              case 6: {
                if (
                  !message.triggerObjectTypeFilters ||
                  !message.triggerObjectTypeFilters.length
                ) {
                  message.triggerObjectTypeFilters = [];
                }
                message.triggerObjectTypeFilters.push(reader.string());
                break;
              }
              case 7: {
                message.triggerExtraCelExpression = reader.string();
                break;
              }
              case 8: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                if (!message.nodes || !message.nodes.length) {
                  message.nodes = [];
                }
                message.nodes.push(
                  $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 10: {
                if (!message.edges || !message.edges.length) {
                  message.edges = [];
                }
                message.edges.push(
                  $root.workflows.v1.WorkflowEdge.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 11: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.sourceTriggerEvents != null) {
            if (typeof object.sourceTriggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.sourceTriggerEvents: object expected, but got ' +
                  typeof object.sourceTriggerEvents
              );
            }
            message.sourceTriggerEvents = sources$1.v2.CreateRequest.fromObject(
              object.sourceTriggerEvents
            );
          }
          if (object.triggerObjectCategory != null) {
            switch (object.triggerObjectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.triggerObjectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.triggerObjectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.triggerObjectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.triggerObjectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.triggerObjectCategory = 4;
                break;
              }
              default: {
                if (typeof object.triggerObjectCategory == 'number') {
                  message.triggerObjectCategory = object.triggerObjectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.triggerObjectTypeFilters) {
            if (!Array.isArray(object.triggerObjectTypeFilters)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.triggerObjectTypeFilters: array type expected, but got ' +
                  typeof object.triggerObjectTypeFilters
              );
            }
            message.triggerObjectTypeFilters = new Array(
              object.triggerObjectTypeFilters.length
            );
            for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
              message.triggerObjectTypeFilters[i] = String(
                object.triggerObjectTypeFilters[i]
              );
            }
          }
          if (object.triggerExtraCelExpression != null) {
            message.triggerExtraCelExpression = String(
              object.triggerExtraCelExpression
            );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes) {
            if (!Array.isArray(object.nodes)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.nodes: array type expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = new Array(object.nodes.length);
            for (let i = 0; i < object.nodes.length; ++i) {
              if (typeof object.nodes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.CreateRequest.nodes: object expected, but got ' +
                    typeof object.nodes[i]
                );
              }
              message.nodes[i] =
                $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                  object.nodes[i]
                );
            }
          }
          if (object.edges) {
            if (!Array.isArray(object.edges)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.edges: array type expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = new Array(object.edges.length);
            for (let i = 0; i < object.edges.length; ++i) {
              if (typeof object.edges[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.CreateRequest.edges: object expected, but got ' +
                    typeof object.edges[i]
                );
              }
              message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                object.edges[i]
              );
            }
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
            object.triggerObjectTypeFilters = [];
            object.nodes = [];
            object.edges = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.sourceTriggerEvents = null;
            object.triggerObjectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.profileLookupConfiguration = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (
            message.sourceTriggerEvents != null &&
            message.hasOwnProperty('sourceTriggerEvents')
          ) {
            object.sourceTriggerEvents = sources$1.v2.CreateRequest.toObject(
              message.sourceTriggerEvents,
              options
            );
          }
          if (
            message.triggerObjectCategory != null &&
            message.hasOwnProperty('triggerObjectCategory')
          ) {
            object.triggerObjectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.triggerObjectCategory
                  ] === undefined
                  ? message.triggerObjectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ]
                : message.triggerObjectCategory;
          }
          if (
            message.triggerObjectTypeFilters &&
            message.triggerObjectTypeFilters.length
          ) {
            object.triggerObjectTypeFilters = new Array(
              message.triggerObjectTypeFilters.length
            );
            for (let i = 0; i < message.triggerObjectTypeFilters.length; ++i) {
              object.triggerObjectTypeFilters[i] =
                message.triggerObjectTypeFilters[i];
            }
          }
          if (
            message.triggerExtraCelExpression != null &&
            message.hasOwnProperty('triggerExtraCelExpression')
          ) {
            object.triggerExtraCelExpression =
              message.triggerExtraCelExpression;
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes && message.nodes.length) {
            object.nodes = new Array(message.nodes.length);
            for (let i = 0; i < message.nodes.length; ++i) {
              object.nodes[i] =
                $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                  message.nodes[i],
                  options
                );
            }
          }
          if (message.edges && message.edges.length) {
            object.edges = new Array(message.edges.length);
            for (let i = 0; i < message.edges.length; ++i) {
              object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                message.edges[i],
                options
              );
            }
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateRequest';
        }
      }

      CreateRequest.prototype.name = '';
      CreateRequest.prototype.description = null;
      CreateRequest.prototype.tags = $util.emptyArray;
      CreateRequest.prototype.sourceTriggerEvents = null;
      CreateRequest.prototype.triggerObjectCategory = 0;
      CreateRequest.prototype.triggerObjectTypeFilters = $util.emptyArray;
      CreateRequest.prototype.triggerExtraCelExpression = null;
      CreateRequest.prototype.profileLookupConfiguration = null;
      CreateRequest.prototype.nodes = $util.emptyArray;
      CreateRequest.prototype.edges = $util.emptyArray;
      CreateRequest.prototype.uniquenessConflictResolution = null;

      return CreateRequest;
    })();

    v1.CreateResponse = (() => {
      class CreateResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateResponse) {
            return object;
          }
          const message = new $root.workflows.v1.CreateResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateResponse';
        }
      }

      CreateResponse.prototype.workflow = null;

      return CreateResponse;
    })();

    v1.CreateWorkflowCloneRequest = (() => {
      class CreateWorkflowCloneRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowCloneRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.targetOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'targetOrganizationId')
          ) {
            writer.uint32(10).string(message.targetOrganizationId);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(18).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(24).int64(message.version);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowCloneRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.targetOrganizationId = reader.string();
                break;
              }
              case 2: {
                message.workflowId = reader.string();
                break;
              }
              case 3: {
                message.version = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateWorkflowCloneRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowCloneRequest();
          if (object.targetOrganizationId != null) {
            message.targetOrganizationId = String(object.targetOrganizationId);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.targetOrganizationId = '';
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.targetOrganizationId != null &&
            message.hasOwnProperty('targetOrganizationId')
          ) {
            object.targetOrganizationId = message.targetOrganizationId;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowCloneRequest';
        }
      }

      CreateWorkflowCloneRequest.prototype.targetOrganizationId = '';
      CreateWorkflowCloneRequest.prototype.workflowId = '';
      CreateWorkflowCloneRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return CreateWorkflowCloneRequest;
    })();

    v1.CreateWorkflowCloneResponse = (() => {
      class CreateWorkflowCloneResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowCloneResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowCloneResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.CreateWorkflowCloneResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowCloneResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowCloneResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowCloneResponse';
        }
      }

      CreateWorkflowCloneResponse.prototype.workflow = null;

      return CreateWorkflowCloneResponse;
    })();

    v1.CreateWorkflowNodeRequest = (() => {
      class CreateWorkflowNodeRequest {
        get type() {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowNodeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.CreateWorkflowFilterRequest.encode(
              message.filter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.pause != null &&
            Object.hasOwnProperty.call(message, 'pause')
          ) {
            $root.workflows.v1.WorkflowPause.encode(
              message.pause,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.restart != null &&
            Object.hasOwnProperty.call(message, 'restart')
          ) {
            $root.workflows.v1.WorkflowRestart.encode(
              message.restart,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowNodeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.filter =
                  $root.workflows.v1.CreateWorkflowFilterRequest.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.pause = $root.workflows.v1.WorkflowPause.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.restart = $root.workflows.v1.WorkflowRestart.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateWorkflowNodeRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowNodeRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.workflows.v1.CreateWorkflowFilterRequest.fromObject(
                object.filter
              );
          }
          if (object.pause != null) {
            if (typeof object.pause !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.pause: object expected, but got ' +
                  typeof object.pause
              );
            }
            message.pause = $root.workflows.v1.WorkflowPause.fromObject(
              object.pause
            );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.restart != null) {
            if (typeof object.restart !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.restart: object expected, but got ' +
                  typeof object.restart
              );
            }
            message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
              object.restart
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.type = 'destinationAction';
            }
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.workflows.v1.CreateWorkflowFilterRequest.toObject(
                message.filter,
                options
              );
            if (options.oneofs) {
              object.type = 'filter';
            }
          }
          if (message.pause != null && message.hasOwnProperty('pause')) {
            object.pause = $root.workflows.v1.WorkflowPause.toObject(
              message.pause,
              options
            );
            if (options.oneofs) {
              object.type = 'pause';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.type = 'fetchAction';
            }
          }
          if (message.restart != null && message.hasOwnProperty('restart')) {
            object.restart = $root.workflows.v1.WorkflowRestart.toObject(
              message.restart,
              options
            );
            if (options.oneofs) {
              object.type = 'restart';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowNodeRequest';
        }
      }

      CreateWorkflowNodeRequest.prototype.id = '';
      CreateWorkflowNodeRequest.prototype.name = null;
      CreateWorkflowNodeRequest.prototype.description = null;
      CreateWorkflowNodeRequest.prototype.destinationAction = null;
      CreateWorkflowNodeRequest.prototype.filter = null;
      CreateWorkflowNodeRequest.prototype.pause = null;
      CreateWorkflowNodeRequest.prototype.fetchAction = null;
      CreateWorkflowNodeRequest.prototype.restart = null;

      return CreateWorkflowNodeRequest;
    })();

    v1.CreateWorkflowFilterRequest = (() => {
      class CreateWorkflowFilterRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowFilterRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.celExpressionStr != null &&
            Object.hasOwnProperty.call(message, 'celExpressionStr')
          ) {
            writer.uint32(10).string(message.celExpressionStr);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowFilterRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.celExpressionStr = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.CreateWorkflowFilterRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowFilterRequest();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = '';
          }
          let keys;
          if (
            message.celExpressionStr != null &&
            message.hasOwnProperty('celExpressionStr')
          ) {
            object.celExpressionStr = message.celExpressionStr;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowFilterRequest';
        }
      }

      CreateWorkflowFilterRequest.prototype.celExpressionStr = '';

      return CreateWorkflowFilterRequest;
    })();

    v1.GetRequest = (() => {
      class GetRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetRequest';
        }
      }

      GetRequest.prototype.id = '';

      return GetRequest;
    })();

    v1.GetResponse = (() => {
      class GetResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.WorkflowSummary.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.WorkflowSummary.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetResponse) {
            return object;
          }
          const message = new $root.workflows.v1.GetResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.WorkflowSummary.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.WorkflowSummary.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetResponse';
        }
      }

      GetResponse.prototype.workflow = null;

      return GetResponse;
    })();

    v1.UpdateRequest = (() => {
      class UpdateRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            $root.workflows.v1.UpdateRequest.Tags.encode(
              message.tags,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.newVersion != null &&
            Object.hasOwnProperty.call(message, 'newVersion')
          ) {
            $root.workflows.v1.UpdateRequest.Version.encode(
              message.newVersion,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.tags = $root.workflows.v1.UpdateRequest.Tags.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.newVersion =
                  $root.workflows.v1.UpdateRequest.Version.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.UpdateRequest) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags != null) {
            if (typeof object.tags !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateRequest.tags: object expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = $root.workflows.v1.UpdateRequest.Tags.fromObject(
              object.tags
            );
          }
          if (object.newVersion != null) {
            if (typeof object.newVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateRequest.newVersion: object expected, but got ' +
                  typeof object.newVersion
              );
            }
            message.newVersion =
              $root.workflows.v1.UpdateRequest.Version.fromObject(
                object.newVersion
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags != null && message.hasOwnProperty('tags')) {
            object.tags = $root.workflows.v1.UpdateRequest.Tags.toObject(
              message.tags,
              options
            );
          }
          if (
            message.newVersion != null &&
            message.hasOwnProperty('newVersion')
          ) {
            object.newVersion =
              $root.workflows.v1.UpdateRequest.Version.toObject(
                message.newVersion,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateRequest';
        }
      }

      UpdateRequest.prototype.id = '';
      UpdateRequest.prototype.name = null;
      UpdateRequest.prototype.description = null;
      UpdateRequest.prototype.tags = null;
      UpdateRequest.prototype.newVersion = null;
      UpdateRequest.Tags = (() => {
        class Tags {
          constructor(properties) {
            this.tags = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Tags(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.tags != null &&
              Object.hasOwnProperty.call(message, 'tags')
            ) {
              for (const element of message.tags) {
                writer.uint32(10).string(element);
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Tags();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.tags || !message.tags.length) {
                    message.tags = [];
                  }
                  message.tags.push(reader.string());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.UpdateRequest.Tags) {
              return object;
            }
            const message = new $root.workflows.v1.UpdateRequest.Tags();
            if (object.tags) {
              if (!Array.isArray(object.tags)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Tags.tags: array type expected, but got ' +
                    typeof object.tags
                );
              }
              message.tags = new Array(object.tags.length);
              for (let i = 0; i < object.tags.length; ++i) {
                message.tags[i] = String(object.tags[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.tags = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.tags && message.tags.length) {
              object.tags = new Array(message.tags.length);
              for (let i = 0; i < message.tags.length; ++i) {
                object.tags[i] = message.tags[i];
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.UpdateRequest.Tags';
          }
        }

        Tags.prototype.tags = $util.emptyArray;

        return Tags;
      })();

      UpdateRequest.Version = (() => {
        class Version {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            this.nodes = [];
            this.edges = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Version(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sourceTriggerEvents != null &&
              Object.hasOwnProperty.call(message, 'sourceTriggerEvents')
            ) {
              sources$1.v2.CreateRequest.encode(
                message.sourceTriggerEvents,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.triggerObjectCategory != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectCategory')
            ) {
              writer.uint32(16).int32(message.triggerObjectCategory);
            }
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(26).string(element);
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
            ) {
              writer.uint32(34).string(message.triggerExtraCelExpression);
            }
            if (
              message.profileLookupConfiguration != null &&
              Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
            ) {
              profiles$1.v1.LookupConfiguration.encode(
                message.profileLookupConfiguration,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.nodes != null &&
              Object.hasOwnProperty.call(message, 'nodes')
            ) {
              for (const element of message.nodes) {
                $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                  element,
                  writer.uint32(50).fork()
                ).ldelim();
              }
            }
            if (
              message.edges != null &&
              Object.hasOwnProperty.call(message, 'edges')
            ) {
              for (const element of message.edges) {
                $root.workflows.v1.WorkflowEdge.encode(
                  element,
                  writer.uint32(58).fork()
                ).ldelim();
              }
            }
            if (
              message.uniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'uniquenessConflictResolution'
              )
            ) {
              $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
                message.uniquenessConflictResolution,
                writer.uint32(66).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Version();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sourceTriggerEvents =
                    sources$1.v2.CreateRequest.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.triggerObjectCategory = reader.int32();
                  break;
                }
                case 3: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                case 4: {
                  message.triggerExtraCelExpression = reader.string();
                  break;
                }
                case 5: {
                  message.profileLookupConfiguration =
                    profiles$1.v1.LookupConfiguration.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  if (!message.nodes || !message.nodes.length) {
                    message.nodes = [];
                  }
                  message.nodes.push(
                    $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 7: {
                  if (!message.edges || !message.edges.length) {
                    message.edges = [];
                  }
                  message.edges.push(
                    $root.workflows.v1.WorkflowEdge.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 8: {
                  message.uniquenessConflictResolution =
                    $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.UpdateRequest.Version) {
              return object;
            }
            const message = new $root.workflows.v1.UpdateRequest.Version();
            if (object.sourceTriggerEvents != null) {
              if (typeof object.sourceTriggerEvents !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.sourceTriggerEvents: object expected, but got ' +
                    typeof object.sourceTriggerEvents
                );
              }
              message.sourceTriggerEvents =
                sources$1.v2.CreateRequest.fromObject(
                  object.sourceTriggerEvents
                );
            }
            if (object.triggerObjectCategory != null) {
              switch (object.triggerObjectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.triggerObjectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.triggerObjectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.triggerObjectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.triggerObjectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.triggerObjectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.triggerObjectCategory == 'number') {
                    message.triggerObjectCategory =
                      object.triggerObjectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            if (object.triggerExtraCelExpression != null) {
              message.triggerExtraCelExpression = String(
                object.triggerExtraCelExpression
              );
            }
            if (object.profileLookupConfiguration != null) {
              if (typeof object.profileLookupConfiguration !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.profileLookupConfiguration: object expected, but got ' +
                    typeof object.profileLookupConfiguration
                );
              }
              message.profileLookupConfiguration =
                profiles$1.v1.LookupConfiguration.fromObject(
                  object.profileLookupConfiguration
                );
            }
            if (object.nodes) {
              if (!Array.isArray(object.nodes)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.nodes: array type expected, but got ' +
                    typeof object.nodes
                );
              }
              message.nodes = new Array(object.nodes.length);
              for (let i = 0; i < object.nodes.length; ++i) {
                if (typeof object.nodes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateRequest.Version.nodes: object expected, but got ' +
                      typeof object.nodes[i]
                  );
                }
                message.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                    object.nodes[i]
                  );
              }
            }
            if (object.edges) {
              if (!Array.isArray(object.edges)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.edges: array type expected, but got ' +
                    typeof object.edges
                );
              }
              message.edges = new Array(object.edges.length);
              for (let i = 0; i < object.edges.length; ++i) {
                if (typeof object.edges[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateRequest.Version.edges: object expected, but got ' +
                      typeof object.edges[i]
                  );
                }
                message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                  object.edges[i]
                );
              }
            }
            if (object.uniquenessConflictResolution != null) {
              if (typeof object.uniquenessConflictResolution !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.uniquenessConflictResolution: object expected, but got ' +
                    typeof object.uniquenessConflictResolution
                );
              }
              message.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                  object.uniquenessConflictResolution
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
              object.nodes = [];
              object.edges = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.sourceTriggerEvents = null;
              object.triggerObjectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
              object.profileLookupConfiguration = null;
            }
            let keys;
            if (
              message.sourceTriggerEvents != null &&
              message.hasOwnProperty('sourceTriggerEvents')
            ) {
              object.sourceTriggerEvents = sources$1.v2.CreateRequest.toObject(
                message.sourceTriggerEvents,
                options
              );
            }
            if (
              message.triggerObjectCategory != null &&
              message.hasOwnProperty('triggerObjectCategory')
            ) {
              object.triggerObjectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ] === undefined
                    ? message.triggerObjectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.triggerObjectCategory
                      ]
                  : message.triggerObjectCategory;
            }
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              message.hasOwnProperty('triggerExtraCelExpression')
            ) {
              object.triggerExtraCelExpression =
                message.triggerExtraCelExpression;
            }
            if (
              message.profileLookupConfiguration != null &&
              message.hasOwnProperty('profileLookupConfiguration')
            ) {
              object.profileLookupConfiguration =
                profiles$1.v1.LookupConfiguration.toObject(
                  message.profileLookupConfiguration,
                  options
                );
            }
            if (message.nodes && message.nodes.length) {
              object.nodes = new Array(message.nodes.length);
              for (let i = 0; i < message.nodes.length; ++i) {
                object.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                    message.nodes[i],
                    options
                  );
              }
            }
            if (message.edges && message.edges.length) {
              object.edges = new Array(message.edges.length);
              for (let i = 0; i < message.edges.length; ++i) {
                object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                  message.edges[i],
                  options
                );
              }
            }
            if (
              message.uniquenessConflictResolution != null &&
              message.hasOwnProperty('uniquenessConflictResolution')
            ) {
              object.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                  message.uniquenessConflictResolution,
                  options
                );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.UpdateRequest.Version';
          }
        }

        Version.prototype.sourceTriggerEvents = null;
        Version.prototype.triggerObjectCategory = 0;
        Version.prototype.triggerObjectTypeFilters = $util.emptyArray;
        Version.prototype.triggerExtraCelExpression = null;
        Version.prototype.profileLookupConfiguration = null;
        Version.prototype.nodes = $util.emptyArray;
        Version.prototype.edges = $util.emptyArray;
        Version.prototype.uniquenessConflictResolution = null;

        return Version;
      })();

      return UpdateRequest;
    })();

    v1.UpdateResponse = (() => {
      class UpdateResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.UpdateResponse) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateResponse';
        }
      }

      UpdateResponse.prototype.workflow = null;

      return UpdateResponse;
    })();

    v1.UpdateWorkflowVersionRequest = (() => {
      class UpdateWorkflowVersionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(24).int32(message.status);
          }
          if (
            message.trigger != null &&
            Object.hasOwnProperty.call(message, 'trigger')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.encode(
              message.trigger,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.encode(
              message.nodes,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.encode(
              message.edges,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              case 3: {
                message.status = reader.int32();
                break;
              }
              case 4: {
                message.trigger =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.nodes =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.edges =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.UpdateWorkflowVersionRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateWorkflowVersionRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.trigger != null) {
            if (typeof object.trigger !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.trigger: object expected, but got ' +
                  typeof object.trigger
              );
            }
            message.trigger =
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.fromObject(
                object.trigger
              );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes != null) {
            if (typeof object.nodes !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.nodes: object expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes =
              $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.fromObject(
                object.nodes
              );
          }
          if (object.edges != null) {
            if (typeof object.edges !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.edges: object expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges =
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.fromObject(
                object.edges
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (message.trigger != null && message.hasOwnProperty('trigger')) {
            object.trigger =
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.toObject(
                message.trigger,
                options
              );
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes != null && message.hasOwnProperty('nodes')) {
            object.nodes =
              $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.toObject(
                message.nodes,
                options
              );
          }
          if (message.edges != null && message.hasOwnProperty('edges')) {
            object.edges =
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.toObject(
                message.edges,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionRequest';
        }
      }

      UpdateWorkflowVersionRequest.prototype.workflowId = '';
      UpdateWorkflowVersionRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      UpdateWorkflowVersionRequest.prototype.status = null;
      UpdateWorkflowVersionRequest.prototype.trigger = null;
      UpdateWorkflowVersionRequest.prototype.profileLookupConfiguration = null;
      UpdateWorkflowVersionRequest.prototype.nodes = null;
      UpdateWorkflowVersionRequest.prototype.edges = null;
      UpdateWorkflowVersionRequest.TriggerObjectTypeFilters = (() => {
        class TriggerObjectTypeFilters {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new TriggerObjectTypeFilters(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(10).string(element);
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new TriggerObjectTypeFilters();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest
                .TriggerObjectTypeFilters
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters();
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters'
            );
          }
        }

        TriggerObjectTypeFilters.prototype.triggerObjectTypeFilters =
          $util.emptyArray;

        return TriggerObjectTypeFilters;
      })();

      UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests = (() => {
        class CreateWorkflowNodeRequests {
          constructor(properties) {
            this.nodes = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateWorkflowNodeRequests(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.nodes != null &&
              Object.hasOwnProperty.call(message, 'nodes')
            ) {
              for (const element of message.nodes) {
                $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateWorkflowNodeRequests();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.nodes || !message.nodes.length) {
                    message.nodes = [];
                  }
                  message.nodes.push(
                    $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest
                .CreateWorkflowNodeRequests
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests();
            if (object.nodes) {
              if (!Array.isArray(object.nodes)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.nodes: array type expected, but got ' +
                    typeof object.nodes
                );
              }
              message.nodes = new Array(object.nodes.length);
              for (let i = 0; i < object.nodes.length; ++i) {
                if (typeof object.nodes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.nodes: object expected, but got ' +
                      typeof object.nodes[i]
                  );
                }
                message.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                    object.nodes[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.nodes = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.nodes && message.nodes.length) {
              object.nodes = new Array(message.nodes.length);
              for (let i = 0; i < message.nodes.length; ++i) {
                object.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                    message.nodes[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests'
            );
          }
        }

        CreateWorkflowNodeRequests.prototype.nodes = $util.emptyArray;

        return CreateWorkflowNodeRequests;
      })();

      UpdateWorkflowVersionRequest.WorkflowEdges = (() => {
        class WorkflowEdges {
          constructor(properties) {
            this.edges = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WorkflowEdges(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.edges != null &&
              Object.hasOwnProperty.call(message, 'edges')
            ) {
              for (const element of message.edges) {
                $root.workflows.v1.WorkflowEdge.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WorkflowEdges();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.edges || !message.edges.length) {
                    message.edges = [];
                  }
                  message.edges.push(
                    $root.workflows.v1.WorkflowEdge.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges();
            if (object.edges) {
              if (!Array.isArray(object.edges)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.edges: array type expected, but got ' +
                    typeof object.edges
                );
              }
              message.edges = new Array(object.edges.length);
              for (let i = 0; i < object.edges.length; ++i) {
                if (typeof object.edges[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.edges: object expected, but got ' +
                      typeof object.edges[i]
                  );
                }
                message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                  object.edges[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.edges = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.edges && message.edges.length) {
              object.edges = new Array(message.edges.length);
              for (let i = 0; i < message.edges.length; ++i) {
                object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                  message.edges[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges'
            );
          }
        }

        WorkflowEdges.prototype.edges = $util.emptyArray;

        return WorkflowEdges;
      })();

      UpdateWorkflowVersionRequest.UpdateTrigger = (() => {
        class UpdateTrigger {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateTrigger(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.triggerObjectCategory != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectCategory')
            ) {
              writer.uint32(8).int32(message.triggerObjectCategory);
            }
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(18).string(element);
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
            ) {
              writer.uint32(26).string(message.triggerExtraCelExpression);
            }
            if (
              message.uniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'uniquenessConflictResolution'
              )
            ) {
              $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
                message.uniquenessConflictResolution,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.triggerEventTypes != null &&
              Object.hasOwnProperty.call(message, 'triggerEventTypes')
            ) {
              event_payload$1.v2.EventTypes.encode(
                message.triggerEventTypes,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.removeUniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'removeUniquenessConflictResolution'
              )
            ) {
              writer
                .uint32(48)
                .bool(message.removeUniquenessConflictResolution);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateTrigger();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.triggerObjectCategory = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                case 3: {
                  message.triggerExtraCelExpression = reader.string();
                  break;
                }
                case 4: {
                  message.uniquenessConflictResolution =
                    $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.triggerEventTypes =
                    event_payload$1.v2.EventTypes.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  message.removeUniquenessConflictResolution = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger();
            if (object.triggerObjectCategory != null) {
              switch (object.triggerObjectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.triggerObjectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.triggerObjectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.triggerObjectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.triggerObjectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.triggerObjectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.triggerObjectCategory == 'number') {
                    message.triggerObjectCategory =
                      object.triggerObjectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            if (object.triggerExtraCelExpression != null) {
              message.triggerExtraCelExpression = String(
                object.triggerExtraCelExpression
              );
            }
            if (object.uniquenessConflictResolution != null) {
              if (typeof object.uniquenessConflictResolution !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.uniquenessConflictResolution: object expected, but got ' +
                    typeof object.uniquenessConflictResolution
                );
              }
              message.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                  object.uniquenessConflictResolution
                );
            }
            if (object.triggerEventTypes != null) {
              if (typeof object.triggerEventTypes !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.triggerEventTypes: object expected, but got ' +
                    typeof object.triggerEventTypes
                );
              }
              message.triggerEventTypes =
                event_payload$1.v2.EventTypes.fromObject(
                  object.triggerEventTypes
                );
            }
            if (object.removeUniquenessConflictResolution != null) {
              message.removeUniquenessConflictResolution = Boolean(
                object.removeUniquenessConflictResolution
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.triggerObjectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            }
            let keys;
            if (
              message.triggerObjectCategory != null &&
              message.hasOwnProperty('triggerObjectCategory')
            ) {
              object.triggerObjectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ] === undefined
                    ? message.triggerObjectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.triggerObjectCategory
                      ]
                  : message.triggerObjectCategory;
            }
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              message.hasOwnProperty('triggerExtraCelExpression')
            ) {
              object.triggerExtraCelExpression =
                message.triggerExtraCelExpression;
            }
            if (
              message.uniquenessConflictResolution != null &&
              message.hasOwnProperty('uniquenessConflictResolution')
            ) {
              object.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                  message.uniquenessConflictResolution,
                  options
                );
            }
            if (
              message.triggerEventTypes != null &&
              message.hasOwnProperty('triggerEventTypes')
            ) {
              object.triggerEventTypes = event_payload$1.v2.EventTypes.toObject(
                message.triggerEventTypes,
                options
              );
            }
            if (
              message.removeUniquenessConflictResolution != null &&
              message.hasOwnProperty('removeUniquenessConflictResolution')
            ) {
              object.removeUniquenessConflictResolution =
                message.removeUniquenessConflictResolution;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger'
            );
          }
        }

        UpdateTrigger.prototype.triggerObjectCategory = 0;
        UpdateTrigger.prototype.triggerObjectTypeFilters = $util.emptyArray;
        UpdateTrigger.prototype.triggerExtraCelExpression = null;
        UpdateTrigger.prototype.uniquenessConflictResolution = null;
        UpdateTrigger.prototype.triggerEventTypes = null;
        UpdateTrigger.prototype.removeUniquenessConflictResolution = null;

        return UpdateTrigger;
      })();

      return UpdateWorkflowVersionRequest;
    })();

    v1.UpdateWorkflowVersionNodeRequest = (() => {
      class UpdateWorkflowVersionNodeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionNodeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(26).string(message.nodeId);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(34).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(42).string(message.description);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.encode(
              message.type,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionNodeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              case 3: {
                message.nodeId = reader.string();
                break;
              }
              case 4: {
                message.name = reader.string();
                break;
              }
              case 5: {
                message.description = reader.string();
                break;
              }
              case 6: {
                message.type =
                  $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.UpdateWorkflowVersionNodeRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionNodeRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionNodeRequest.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type =
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.fromObject(
                object.type
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.nodeId = '';
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.toObject(
                message.type,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionNodeRequest'
          );
        }
      }

      UpdateWorkflowVersionNodeRequest.prototype.workflowId = '';
      UpdateWorkflowVersionNodeRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      UpdateWorkflowVersionNodeRequest.prototype.nodeId = '';
      UpdateWorkflowVersionNodeRequest.prototype.name = null;
      UpdateWorkflowVersionNodeRequest.prototype.description = null;
      UpdateWorkflowVersionNodeRequest.prototype.type = null;
      UpdateWorkflowVersionNodeRequest.UpdateNodeType = (() => {
        class UpdateNodeType {
          get type() {
            for (const key of [
              'destinationAction',
              'filter',
              'pause',
              'fetchAction',
              'restart',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'destinationAction',
              'filter',
              'pause',
              'fetchAction',
              'restart',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateNodeType(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.destinationAction != null &&
              Object.hasOwnProperty.call(message, 'destinationAction')
            ) {
              $root.workflows.v1.WorkflowDestinationAction.encode(
                message.destinationAction,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.filter != null &&
              Object.hasOwnProperty.call(message, 'filter')
            ) {
              $root.workflows.v1.CreateWorkflowFilterRequest.encode(
                message.filter,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.pause != null &&
              Object.hasOwnProperty.call(message, 'pause')
            ) {
              $root.workflows.v1.WorkflowPause.encode(
                message.pause,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.fetchAction != null &&
              Object.hasOwnProperty.call(message, 'fetchAction')
            ) {
              $root.workflows.v1.WorkflowFetchAction.encode(
                message.fetchAction,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.restart != null &&
              Object.hasOwnProperty.call(message, 'restart')
            ) {
              $root.workflows.v1.WorkflowRestart.encode(
                message.restart,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateNodeType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.destinationAction =
                    $root.workflows.v1.WorkflowDestinationAction.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.filter =
                    $root.workflows.v1.CreateWorkflowFilterRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.pause = $root.workflows.v1.WorkflowPause.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 4: {
                  message.fetchAction =
                    $root.workflows.v1.WorkflowFetchAction.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.restart = $root.workflows.v1.WorkflowRestart.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType();
            if (object.destinationAction != null) {
              if (typeof object.destinationAction !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.destinationAction: object expected, but got ' +
                    typeof object.destinationAction
                );
              }
              message.destinationAction =
                $root.workflows.v1.WorkflowDestinationAction.fromObject(
                  object.destinationAction
                );
            }
            if (object.filter != null) {
              if (typeof object.filter !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.filter: object expected, but got ' +
                    typeof object.filter
                );
              }
              message.filter =
                $root.workflows.v1.CreateWorkflowFilterRequest.fromObject(
                  object.filter
                );
            }
            if (object.pause != null) {
              if (typeof object.pause !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.pause: object expected, but got ' +
                    typeof object.pause
                );
              }
              message.pause = $root.workflows.v1.WorkflowPause.fromObject(
                object.pause
              );
            }
            if (object.fetchAction != null) {
              if (typeof object.fetchAction !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.fetchAction: object expected, but got ' +
                    typeof object.fetchAction
                );
              }
              message.fetchAction =
                $root.workflows.v1.WorkflowFetchAction.fromObject(
                  object.fetchAction
                );
            }
            if (object.restart != null) {
              if (typeof object.restart !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.restart: object expected, but got ' +
                    typeof object.restart
                );
              }
              message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
                object.restart
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.destinationAction != null &&
              message.hasOwnProperty('destinationAction')
            ) {
              object.destinationAction =
                $root.workflows.v1.WorkflowDestinationAction.toObject(
                  message.destinationAction,
                  options
                );
              if (options.oneofs) {
                object.type = 'destinationAction';
              }
            }
            if (message.filter != null && message.hasOwnProperty('filter')) {
              object.filter =
                $root.workflows.v1.CreateWorkflowFilterRequest.toObject(
                  message.filter,
                  options
                );
              if (options.oneofs) {
                object.type = 'filter';
              }
            }
            if (message.pause != null && message.hasOwnProperty('pause')) {
              object.pause = $root.workflows.v1.WorkflowPause.toObject(
                message.pause,
                options
              );
              if (options.oneofs) {
                object.type = 'pause';
              }
            }
            if (
              message.fetchAction != null &&
              message.hasOwnProperty('fetchAction')
            ) {
              object.fetchAction =
                $root.workflows.v1.WorkflowFetchAction.toObject(
                  message.fetchAction,
                  options
                );
              if (options.oneofs) {
                object.type = 'fetchAction';
              }
            }
            if (message.restart != null && message.hasOwnProperty('restart')) {
              object.restart = $root.workflows.v1.WorkflowRestart.toObject(
                message.restart,
                options
              );
              if (options.oneofs) {
                object.type = 'restart';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType'
            );
          }
        }

        UpdateNodeType.prototype.destinationAction = null;
        UpdateNodeType.prototype.filter = null;
        UpdateNodeType.prototype.pause = null;
        UpdateNodeType.prototype.fetchAction = null;
        UpdateNodeType.prototype.restart = null;

        return UpdateNodeType;
      })();

      return UpdateWorkflowVersionNodeRequest;
    })();

    v1.UpdateWorkflowVersionNodeResponse = (() => {
      class UpdateWorkflowVersionNodeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionNodeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.node != null &&
            Object.hasOwnProperty.call(message, 'node')
          ) {
            $root.workflows.v1.WorkflowNode.encode(
              message.node,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionNodeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.node = $root.workflows.v1.WorkflowNode.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.UpdateWorkflowVersionNodeResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionNodeResponse();
          if (object.node != null) {
            if (typeof object.node !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionNodeResponse.node: object expected, but got ' +
                  typeof object.node
              );
            }
            message.node = $root.workflows.v1.WorkflowNode.fromObject(
              object.node
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.node = null;
          }
          let keys;
          if (message.node != null && message.hasOwnProperty('node')) {
            object.node = $root.workflows.v1.WorkflowNode.toObject(
              message.node,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionNodeResponse'
          );
        }
      }

      UpdateWorkflowVersionNodeResponse.prototype.node = null;

      return UpdateWorkflowVersionNodeResponse;
    })();

    v1.GetWorkflowVersionRequest = (() => {
      class GetWorkflowVersionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetWorkflowVersionRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetWorkflowVersionRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetWorkflowVersionRequest';
        }
      }

      GetWorkflowVersionRequest.prototype.workflowId = '';
      GetWorkflowVersionRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return GetWorkflowVersionRequest;
    })();

    v1.UpdateWorkflowVersionResponse = (() => {
      class UpdateWorkflowVersionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            $root.workflows.v1.Version.encode(
              message.version,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.UpdateWorkflowVersionResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionResponse();
          if (object.version != null) {
            if (typeof object.version !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionResponse.version: object expected, but got ' +
                  typeof object.version
              );
            }
            message.version = $root.workflows.v1.Version.fromObject(
              object.version
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.version = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version = $root.workflows.v1.Version.toObject(
              message.version,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionResponse';
        }
      }

      UpdateWorkflowVersionResponse.prototype.version = null;

      return UpdateWorkflowVersionResponse;
    })();

    v1.ListRequest = (() => {
      class ListRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.ListRequest.Filter.encode(
              message.filter,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.filter = $root.workflows.v1.ListRequest.Filter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListRequest();
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.workflows.v1.ListRequest.Filter.fromObject(
              object.filter
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.filter = null;
          }
          let keys;
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.workflows.v1.ListRequest.Filter.toObject(
              message.filter,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListRequest';
        }
      }

      ListRequest.prototype.filter = null;
      ListRequest.Filter = (() => {
        class Filter {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Filter(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.status != null &&
              Object.hasOwnProperty.call(message, 'status')
            ) {
              writer.uint32(8).int32(message.status);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Filter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.status = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.ListRequest.Filter) {
              return object;
            }
            const message = new $root.workflows.v1.ListRequest.Filter();
            if (object.status != null) {
              switch (object.status) {
                case 'UNSPECIFIED_WORKFLOW_STATUS':
                case 0: {
                  message.status = 0;
                  break;
                }
                case 'DRAFT':
                case 1: {
                  message.status = 1;
                  break;
                }
                case 'ACTIVE':
                case 2: {
                  message.status = 2;
                  break;
                }
                case 'INACTIVE':
                case 3: {
                  message.status = 3;
                  break;
                }
                default: {
                  if (typeof object.status == 'number') {
                    message.status = object.status;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.status != null && message.hasOwnProperty('status')) {
              object.status =
                options.enums === String
                  ? $root.workflows.v1.Status[message.status] === undefined
                    ? message.status
                    : $root.workflows.v1.Status[message.status]
                  : message.status;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.ListRequest.Filter';
          }
        }

        Filter.prototype.status = null;

        return Filter;
      })();

      return ListRequest;
    })();

    v1.ListResponse = (() => {
      class ListResponse {
        constructor(properties) {
          this.workflows = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflows != null &&
            Object.hasOwnProperty.call(message, 'workflows')
          ) {
            for (const element of message.workflows) {
              $root.workflows.v1.WorkflowSummary.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.workflows || !message.workflows.length) {
                  message.workflows = [];
                }
                message.workflows.push(
                  $root.workflows.v1.WorkflowSummary.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListResponse();
          if (object.workflows) {
            if (!Array.isArray(object.workflows)) {
              throw new TypeError(
                '.workflows.v1.ListResponse.workflows: array type expected, but got ' +
                  typeof object.workflows
              );
            }
            message.workflows = new Array(object.workflows.length);
            for (let i = 0; i < object.workflows.length; ++i) {
              if (typeof object.workflows[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListResponse.workflows: object expected, but got ' +
                    typeof object.workflows[i]
                );
              }
              message.workflows[i] =
                $root.workflows.v1.WorkflowSummary.fromObject(
                  object.workflows[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflows = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.workflows && message.workflows.length) {
            object.workflows = new Array(message.workflows.length);
            for (let i = 0; i < message.workflows.length; ++i) {
              object.workflows[i] = $root.workflows.v1.WorkflowSummary.toObject(
                message.workflows[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListResponse';
        }
      }

      ListResponse.prototype.workflows = $util.emptyArray;

      return ListResponse;
    })();

    v1.ListWorkflowVersionsRequest = (() => {
      class ListWorkflowVersionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowVersionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(32).uint64(message.limit);
          }
          if (
            message.returnLightweight != null &&
            Object.hasOwnProperty.call(message, 'returnLightweight')
          ) {
            writer.uint32(40).bool(message.returnLightweight);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowVersionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              case 5: {
                message.returnLightweight = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowVersionsRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowVersionsRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.returnLightweight != null) {
            message.returnLightweight = Boolean(object.returnLightweight);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          if (
            message.returnLightweight != null &&
            message.hasOwnProperty('returnLightweight')
          ) {
            object.returnLightweight = message.returnLightweight;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowVersionsRequest';
        }
      }

      ListWorkflowVersionsRequest.prototype.workflowId = '';
      ListWorkflowVersionsRequest.prototype.earliestTimestampInclusive = null;
      ListWorkflowVersionsRequest.prototype.latestTimestampExclusive = null;
      ListWorkflowVersionsRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListWorkflowVersionsRequest.prototype.returnLightweight = null;

      return ListWorkflowVersionsRequest;
    })();

    v1.ListWorkflowVersionsResponse = (() => {
      class ListWorkflowVersionsResponse {
        constructor(properties) {
          this.versions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowVersionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.versions != null &&
            Object.hasOwnProperty.call(message, 'versions')
          ) {
            for (const element of message.versions) {
              $root.workflows.v1.Version.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowVersionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.versions || !message.versions.length) {
                  message.versions = [];
                }
                message.versions.push(
                  $root.workflows.v1.Version.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowVersionsResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowVersionsResponse();
          if (object.versions) {
            if (!Array.isArray(object.versions)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsResponse.versions: array type expected, but got ' +
                  typeof object.versions
              );
            }
            message.versions = new Array(object.versions.length);
            for (let i = 0; i < object.versions.length; ++i) {
              if (typeof object.versions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowVersionsResponse.versions: object expected, but got ' +
                    typeof object.versions[i]
                );
              }
              message.versions[i] = $root.workflows.v1.Version.fromObject(
                object.versions[i]
              );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.versions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.versions && message.versions.length) {
            object.versions = new Array(message.versions.length);
            for (let i = 0; i < message.versions.length; ++i) {
              object.versions[i] = $root.workflows.v1.Version.toObject(
                message.versions[i],
                options
              );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowVersionsResponse';
        }
      }

      ListWorkflowVersionsResponse.prototype.versions = $util.emptyArray;
      ListWorkflowVersionsResponse.prototype.earliestFetchedTimestamp = null;

      return ListWorkflowVersionsResponse;
    })();

    v1.GetWorkflowVersionResponse = (() => {
      class GetWorkflowVersionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            $root.workflows.v1.Version.encode(
              message.version,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetWorkflowVersionResponse) {
            return object;
          }
          const message = new $root.workflows.v1.GetWorkflowVersionResponse();
          if (object.version != null) {
            if (typeof object.version !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetWorkflowVersionResponse.version: object expected, but got ' +
                  typeof object.version
              );
            }
            message.version = $root.workflows.v1.Version.fromObject(
              object.version
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.version = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version = $root.workflows.v1.Version.toObject(
              message.version,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetWorkflowVersionResponse';
        }
      }

      GetWorkflowVersionResponse.prototype.version = null;

      return GetWorkflowVersionResponse;
    })();

    v1.GetValidTriggerEventsRequest = (() => {
      class GetValidTriggerEventsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetValidTriggerEventsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetValidTriggerEventsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetValidTriggerEventsRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.GetValidTriggerEventsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetValidTriggerEventsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetValidTriggerEventsRequest';
        }
      }

      GetValidTriggerEventsRequest.prototype.stub = null;

      return GetValidTriggerEventsRequest;
    })();

    v1.GetValidTriggerEventsResponse = (() => {
      class GetValidTriggerEventsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetValidTriggerEventsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.validTriggerEvents != null &&
            Object.hasOwnProperty.call(message, 'validTriggerEvents')
          ) {
            $root.workflows.v1.ValidTriggerEvents.encode(
              message.validTriggerEvents,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetValidTriggerEventsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.validTriggerEvents =
                  $root.workflows.v1.ValidTriggerEvents.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetValidTriggerEventsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetValidTriggerEventsResponse();
          if (object.validTriggerEvents != null) {
            if (typeof object.validTriggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetValidTriggerEventsResponse.validTriggerEvents: object expected, but got ' +
                  typeof object.validTriggerEvents
              );
            }
            message.validTriggerEvents =
              $root.workflows.v1.ValidTriggerEvents.fromObject(
                object.validTriggerEvents
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.validTriggerEvents = null;
          }
          let keys;
          if (
            message.validTriggerEvents != null &&
            message.hasOwnProperty('validTriggerEvents')
          ) {
            object.validTriggerEvents =
              $root.workflows.v1.ValidTriggerEvents.toObject(
                message.validTriggerEvents,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetValidTriggerEventsResponse';
        }
      }

      GetValidTriggerEventsResponse.prototype.validTriggerEvents = null;

      return GetValidTriggerEventsResponse;
    })();

    v1.RetryEventSnapshotRequest = (() => {
      class RetryEventSnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryEventSnapshotRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(18).string(message.workflowId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryEventSnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.workflowId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RetryEventSnapshotRequest) {
            return object;
          }
          const message = new $root.workflows.v1.RetryEventSnapshotRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryEventSnapshotRequest';
        }
      }

      RetryEventSnapshotRequest.prototype.id = '';
      RetryEventSnapshotRequest.prototype.workflowId = null;

      return RetryEventSnapshotRequest;
    })();

    v1.RetryEventSnapshotResponse = (() => {
      class RetryEventSnapshotResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryEventSnapshotResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryEventSnapshotResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RetryEventSnapshotResponse) {
            return object;
          }
          const message = new $root.workflows.v1.RetryEventSnapshotResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryEventSnapshotResponse';
        }
      }

      return RetryEventSnapshotResponse;
    })();

    v1.RetryWorkflowExecutionRequest = (() => {
      class RetryWorkflowExecutionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryWorkflowExecutionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.node != null &&
            Object.hasOwnProperty.call(message, 'node')
          ) {
            $root.workflows.v1.RetryWorkflowExecutionRequest.Node.encode(
              message.node,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryWorkflowExecutionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.node =
                  $root.workflows.v1.RetryWorkflowExecutionRequest.Node.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.RetryWorkflowExecutionRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.RetryWorkflowExecutionRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.node != null) {
            if (typeof object.node !== 'object') {
              throw new TypeError(
                '.workflows.v1.RetryWorkflowExecutionRequest.node: object expected, but got ' +
                  typeof object.node
              );
            }
            message.node =
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node.fromObject(
                object.node
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.node != null && message.hasOwnProperty('node')) {
            object.node =
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node.toObject(
                message.node,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionRequest';
        }
      }

      RetryWorkflowExecutionRequest.prototype.workflowExecutionId = '';
      RetryWorkflowExecutionRequest.prototype.node = null;
      RetryWorkflowExecutionRequest.Node = (() => {
        class Node {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Node(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.id != null &&
              Object.hasOwnProperty.call(message, 'id')
            ) {
              writer.uint32(10).string(message.id);
            }
            if (
              message.runDownstream != null &&
              Object.hasOwnProperty.call(message, 'runDownstream')
            ) {
              writer.uint32(16).bool(message.runDownstream);
            }
            if (
              message.runUpstream != null &&
              Object.hasOwnProperty.call(message, 'runUpstream')
            ) {
              writer.uint32(24).bool(message.runUpstream);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Node();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.id = reader.string();
                  break;
                }
                case 2: {
                  message.runDownstream = reader.bool();
                  break;
                }
                case 3: {
                  message.runUpstream = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.RetryWorkflowExecutionRequest.Node();
            if (object.id != null) {
              message.id = String(object.id);
            }
            if (object.runDownstream != null) {
              message.runDownstream = Boolean(object.runDownstream);
            }
            if (object.runUpstream != null) {
              message.runUpstream = Boolean(object.runUpstream);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.id = '';
              object.runDownstream = false;
              object.runUpstream = false;
            }
            let keys;
            if (message.id != null && message.hasOwnProperty('id')) {
              object.id = message.id;
            }
            if (
              message.runDownstream != null &&
              message.hasOwnProperty('runDownstream')
            ) {
              object.runDownstream = message.runDownstream;
            }
            if (
              message.runUpstream != null &&
              message.hasOwnProperty('runUpstream')
            ) {
              object.runUpstream = message.runUpstream;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionRequest.Node'
            );
          }
        }

        Node.prototype.id = '';
        Node.prototype.runDownstream = false;
        Node.prototype.runUpstream = false;

        return Node;
      })();

      return RetryWorkflowExecutionRequest;
    })();

    v1.RetryWorkflowExecutionResponse = (() => {
      class RetryWorkflowExecutionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryWorkflowExecutionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.executionIds != null &&
            Object.hasOwnProperty.call(message, 'executionIds')
          ) {
            $root.workflows.v1.InternalWorkflowExecutionIds.encode(
              message.executionIds,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryWorkflowExecutionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.executionIds =
                  $root.workflows.v1.InternalWorkflowExecutionIds.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.RetryWorkflowExecutionResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.RetryWorkflowExecutionResponse();
          if (object.executionIds != null) {
            if (typeof object.executionIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.RetryWorkflowExecutionResponse.executionIds: object expected, but got ' +
                  typeof object.executionIds
              );
            }
            message.executionIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.fromObject(
                object.executionIds
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.executionIds = null;
          }
          let keys;
          if (
            message.executionIds != null &&
            message.hasOwnProperty('executionIds')
          ) {
            object.executionIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.toObject(
                message.executionIds,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionResponse';
        }
      }

      RetryWorkflowExecutionResponse.prototype.executionIds = null;

      return RetryWorkflowExecutionResponse;
    })();

    v1.ListDestinationActionsRequest = (() => {
      class ListDestinationActionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDestinationActionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDestinationActionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListDestinationActionsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListDestinationActionsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListDestinationActionsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListDestinationActionsRequest';
        }
      }

      ListDestinationActionsRequest.prototype.stub = null;

      return ListDestinationActionsRequest;
    })();

    v1.ListDestinationActionsResponse = (() => {
      class ListDestinationActionsResponse {
        constructor(properties) {
          this.destinationActions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDestinationActionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationActions != null &&
            Object.hasOwnProperty.call(message, 'destinationActions')
          ) {
            for (const element of message.destinationActions) {
              $root.workflows.v1.WorkflowDestinationAction.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDestinationActionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.destinationActions ||
                  !message.destinationActions.length
                ) {
                  message.destinationActions = [];
                }
                message.destinationActions.push(
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListDestinationActionsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListDestinationActionsResponse();
          if (object.destinationActions) {
            if (!Array.isArray(object.destinationActions)) {
              throw new TypeError(
                '.workflows.v1.ListDestinationActionsResponse.destinationActions: array type expected, but got ' +
                  typeof object.destinationActions
              );
            }
            message.destinationActions = new Array(
              object.destinationActions.length
            );
            for (let i = 0; i < object.destinationActions.length; ++i) {
              if (typeof object.destinationActions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListDestinationActionsResponse.destinationActions: object expected, but got ' +
                    typeof object.destinationActions[i]
                );
              }
              message.destinationActions[i] =
                $root.workflows.v1.WorkflowDestinationAction.fromObject(
                  object.destinationActions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.destinationActions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.destinationActions && message.destinationActions.length) {
            object.destinationActions = new Array(
              message.destinationActions.length
            );
            for (let i = 0; i < message.destinationActions.length; ++i) {
              object.destinationActions[i] =
                $root.workflows.v1.WorkflowDestinationAction.toObject(
                  message.destinationActions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListDestinationActionsResponse';
        }
      }

      ListDestinationActionsResponse.prototype.destinationActions =
        $util.emptyArray;

      return ListDestinationActionsResponse;
    })();

    v1.ListFetchActionsRequest = (() => {
      class ListFetchActionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFetchActionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFetchActionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListFetchActionsRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListFetchActionsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListFetchActionsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListFetchActionsRequest';
        }
      }

      ListFetchActionsRequest.prototype.stub = null;

      return ListFetchActionsRequest;
    })();

    v1.ListFetchActionsResponse = (() => {
      class ListFetchActionsResponse {
        constructor(properties) {
          this.fetchActions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFetchActionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fetchActions != null &&
            Object.hasOwnProperty.call(message, 'fetchActions')
          ) {
            for (const element of message.fetchActions) {
              $root.workflows.v1.WorkflowFetchAction.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFetchActionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.fetchActions || !message.fetchActions.length) {
                  message.fetchActions = [];
                }
                message.fetchActions.push(
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListFetchActionsResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListFetchActionsResponse();
          if (object.fetchActions) {
            if (!Array.isArray(object.fetchActions)) {
              throw new TypeError(
                '.workflows.v1.ListFetchActionsResponse.fetchActions: array type expected, but got ' +
                  typeof object.fetchActions
              );
            }
            message.fetchActions = new Array(object.fetchActions.length);
            for (let i = 0; i < object.fetchActions.length; ++i) {
              if (typeof object.fetchActions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListFetchActionsResponse.fetchActions: object expected, but got ' +
                    typeof object.fetchActions[i]
                );
              }
              message.fetchActions[i] =
                $root.workflows.v1.WorkflowFetchAction.fromObject(
                  object.fetchActions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.fetchActions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.fetchActions && message.fetchActions.length) {
            object.fetchActions = new Array(message.fetchActions.length);
            for (let i = 0; i < message.fetchActions.length; ++i) {
              object.fetchActions[i] =
                $root.workflows.v1.WorkflowFetchAction.toObject(
                  message.fetchActions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListFetchActionsResponse';
        }
      }

      ListFetchActionsResponse.prototype.fetchActions = $util.emptyArray;

      return ListFetchActionsResponse;
    })();

    v1.GetEventPayloadFieldSchemasRequest = (() => {
      class GetEventPayloadFieldSchemasRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventPayloadFieldSchemasRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventPayloadStub != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadStub')
          ) {
            event_payload$1.v2.EventPayload.encode(
              message.eventPayloadStub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventPayloadFieldSchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventPayloadStub =
                  event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetEventPayloadFieldSchemasRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetEventPayloadFieldSchemasRequest();
          if (object.eventPayloadStub != null) {
            if (typeof object.eventPayloadStub !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasRequest.eventPayloadStub: object expected, but got ' +
                  typeof object.eventPayloadStub
              );
            }
            message.eventPayloadStub =
              event_payload$1.v2.EventPayload.fromObject(
                object.eventPayloadStub
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventPayloadStub = null;
          }
          let keys;
          if (
            message.eventPayloadStub != null &&
            message.hasOwnProperty('eventPayloadStub')
          ) {
            object.eventPayloadStub = event_payload$1.v2.EventPayload.toObject(
              message.eventPayloadStub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetEventPayloadFieldSchemasRequest'
          );
        }
      }

      GetEventPayloadFieldSchemasRequest.prototype.eventPayloadStub = null;

      return GetEventPayloadFieldSchemasRequest;
    })();

    v1.GetEventPayloadFieldSchemasResponse = (() => {
      class GetEventPayloadFieldSchemasResponse {
        constructor(properties) {
          this.eventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventPayloadFieldSchemasResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadFieldSchemas')
          ) {
            for (const element of message.eventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.eventPayloadExample != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadExample')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayloadExample,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventPayloadFieldSchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.eventPayloadFieldSchemas ||
                  !message.eventPayloadFieldSchemas.length
                ) {
                  message.eventPayloadFieldSchemas = [];
                }
                message.eventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.eventPayloadExample = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetEventPayloadFieldSchemasResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetEventPayloadFieldSchemasResponse();
          if (object.eventPayloadFieldSchemas) {
            if (!Array.isArray(object.eventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.eventPayloadFieldSchemas
              );
            }
            message.eventPayloadFieldSchemas = new Array(
              object.eventPayloadFieldSchemas.length
            );
            for (let i = 0; i < object.eventPayloadFieldSchemas.length; ++i) {
              if (typeof object.eventPayloadFieldSchemas[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.eventPayloadFieldSchemas[i]
                );
              }
              message.eventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.eventPayloadFieldSchemas[i]
                );
            }
          }
          if (object.eventPayloadExample != null) {
            if (typeof object.eventPayloadExample !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadExample: object expected, but got ' +
                  typeof object.eventPayloadExample
              );
            }
            message.eventPayloadExample = google$2.protobuf.Struct.fromObject(
              object.eventPayloadExample
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventPayloadExample = null;
          }
          let keys;
          if (
            message.eventPayloadFieldSchemas &&
            message.eventPayloadFieldSchemas.length
          ) {
            object.eventPayloadFieldSchemas = new Array(
              message.eventPayloadFieldSchemas.length
            );
            for (let i = 0; i < message.eventPayloadFieldSchemas.length; ++i) {
              object.eventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.eventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          if (
            message.eventPayloadExample != null &&
            message.hasOwnProperty('eventPayloadExample')
          ) {
            object.eventPayloadExample = google$2.protobuf.Struct.toObject(
              message.eventPayloadExample,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetEventPayloadFieldSchemasResponse'
          );
        }
      }

      GetEventPayloadFieldSchemasResponse.prototype.eventPayloadFieldSchemas =
        $util.emptyArray;
      GetEventPayloadFieldSchemasResponse.prototype.eventPayloadExample = null;

      return GetEventPayloadFieldSchemasResponse;
    })();

    v1.GetWorkflowVersionNodeFieldSchemasRequest = (() => {
      class GetWorkflowVersionNodeFieldSchemasRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionNodeFieldSchemasRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(26).string(message.nodeId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionNodeFieldSchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              case 3: {
                message.nodeId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.nodeId = '';
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.GetWorkflowVersionNodeFieldSchemasRequest'
          );
        }
      }

      GetWorkflowVersionNodeFieldSchemasRequest.prototype.workflowId = '';
      GetWorkflowVersionNodeFieldSchemasRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      GetWorkflowVersionNodeFieldSchemasRequest.prototype.nodeId = '';

      return GetWorkflowVersionNodeFieldSchemasRequest;
    })();

    v1.GetWorkflowVersionNodeFieldSchemasResponse = (() => {
      class GetWorkflowVersionNodeFieldSchemasResponse {
        constructor(properties) {
          this.nodeSpecificEventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionNodeFieldSchemasResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.nodeSpecificEventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(
              message,
              'nodeSpecificEventPayloadFieldSchemas'
            )
          ) {
            for (const element of message.nodeSpecificEventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionNodeFieldSchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.nodeSpecificEventPayloadFieldSchemas ||
                  !message.nodeSpecificEventPayloadFieldSchemas.length
                ) {
                  message.nodeSpecificEventPayloadFieldSchemas = [];
                }
                message.nodeSpecificEventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse();
          if (object.nodeSpecificEventPayloadFieldSchemas) {
            if (!Array.isArray(object.nodeSpecificEventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse.nodeSpecificEventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.nodeSpecificEventPayloadFieldSchemas
              );
            }
            message.nodeSpecificEventPayloadFieldSchemas = new Array(
              object.nodeSpecificEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < object.nodeSpecificEventPayloadFieldSchemas.length;
              ++i
            ) {
              if (
                typeof object.nodeSpecificEventPayloadFieldSchemas[i] !==
                'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse.nodeSpecificEventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.nodeSpecificEventPayloadFieldSchemas[i]
                );
              }
              message.nodeSpecificEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.nodeSpecificEventPayloadFieldSchemas[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.nodeSpecificEventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.nodeSpecificEventPayloadFieldSchemas &&
            message.nodeSpecificEventPayloadFieldSchemas.length
          ) {
            object.nodeSpecificEventPayloadFieldSchemas = new Array(
              message.nodeSpecificEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < message.nodeSpecificEventPayloadFieldSchemas.length;
              ++i
            ) {
              object.nodeSpecificEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.nodeSpecificEventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse'
          );
        }
      }

      GetWorkflowVersionNodeFieldSchemasResponse.prototype.nodeSpecificEventPayloadFieldSchemas =
        $util.emptyArray;

      return GetWorkflowVersionNodeFieldSchemasResponse;
    })();

    v1.Workflow = (() => {
      class Workflow {
        constructor(properties) {
          this.tags = [];
          this.destinationApplications = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Workflow(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(48).int32(message.status);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(58).string(message.sourceId);
          }
          if (
            message.activeVersion != null &&
            Object.hasOwnProperty.call(message, 'activeVersion')
          ) {
            $root.workflows.v1.Version.encode(
              message.activeVersion,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.latestDraftVersion != null &&
            Object.hasOwnProperty.call(message, 'latestDraftVersion')
          ) {
            $root.workflows.v1.Version.encode(
              message.latestDraftVersion,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.inactiveVersion != null &&
            Object.hasOwnProperty.call(message, 'inactiveVersion')
          ) {
            $root.workflows.v1.Version.encode(
              message.inactiveVersion,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.lastUpdatedBy != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedBy')
          ) {
            writer.uint32(90).string(message.lastUpdatedBy);
          }
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(96).int32(message.sourceApplication);
          }
          if (
            message.destinationApplications != null &&
            Object.hasOwnProperty.call(message, 'destinationApplications')
          ) {
            for (const element of message.destinationApplications) {
              workflow_destination_applications$1.v1.DestinationApplication.encode(
                element,
                writer.uint32(106).fork()
              ).ldelim();
            }
          }
          if (
            message.webhooksEndpoint != null &&
            Object.hasOwnProperty.call(message, 'webhooksEndpoint')
          ) {
            writer.uint32(114).string(message.webhooksEndpoint);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Workflow();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.status = reader.int32();
                break;
              }
              case 7: {
                message.sourceId = reader.string();
                break;
              }
              case 8: {
                message.activeVersion = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.latestDraftVersion = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.inactiveVersion = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 11: {
                message.lastUpdatedBy = reader.string();
                break;
              }
              case 12: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 13: {
                if (
                  !message.destinationApplications ||
                  !message.destinationApplications.length
                ) {
                  message.destinationApplications = [];
                }
                message.destinationApplications.push(
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 14: {
                message.webhooksEndpoint = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Workflow) {
            return object;
          }
          const message = new $root.workflows.v1.Workflow();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.workflows.v1.Workflow.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.activeVersion != null) {
            if (typeof object.activeVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.activeVersion: object expected, but got ' +
                  typeof object.activeVersion
              );
            }
            message.activeVersion = $root.workflows.v1.Version.fromObject(
              object.activeVersion
            );
          }
          if (object.latestDraftVersion != null) {
            if (typeof object.latestDraftVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.latestDraftVersion: object expected, but got ' +
                  typeof object.latestDraftVersion
              );
            }
            message.latestDraftVersion = $root.workflows.v1.Version.fromObject(
              object.latestDraftVersion
            );
          }
          if (object.inactiveVersion != null) {
            if (typeof object.inactiveVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.inactiveVersion: object expected, but got ' +
                  typeof object.inactiveVersion
              );
            }
            message.inactiveVersion = $root.workflows.v1.Version.fromObject(
              object.inactiveVersion
            );
          }
          if (object.lastUpdatedBy != null) {
            message.lastUpdatedBy = String(object.lastUpdatedBy);
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationApplications) {
            if (!Array.isArray(object.destinationApplications)) {
              throw new TypeError(
                '.workflows.v1.Workflow.destinationApplications: array type expected, but got ' +
                  typeof object.destinationApplications
              );
            }
            message.destinationApplications = new Array(
              object.destinationApplications.length
            );
            for (let i = 0; i < object.destinationApplications.length; ++i) {
              if (typeof object.destinationApplications[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.Workflow.destinationApplications: object expected, but got ' +
                    typeof object.destinationApplications[i]
                );
              }
              message.destinationApplications[i] =
                workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                  object.destinationApplications[i]
                );
            }
          }
          if (object.webhooksEndpoint != null) {
            message.webhooksEndpoint = String(object.webhooksEndpoint);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
            object.destinationApplications = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.timing = null;
            object.status =
              options.enums === String ? 'UNSPECIFIED_WORKFLOW_STATUS' : 0;
            object.sourceId = '';
            object.lastUpdatedBy = '';
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.webhooksEndpoint = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.activeVersion != null &&
            message.hasOwnProperty('activeVersion')
          ) {
            object.activeVersion = $root.workflows.v1.Version.toObject(
              message.activeVersion,
              options
            );
          }
          if (
            message.latestDraftVersion != null &&
            message.hasOwnProperty('latestDraftVersion')
          ) {
            object.latestDraftVersion = $root.workflows.v1.Version.toObject(
              message.latestDraftVersion,
              options
            );
          }
          if (
            message.inactiveVersion != null &&
            message.hasOwnProperty('inactiveVersion')
          ) {
            object.inactiveVersion = $root.workflows.v1.Version.toObject(
              message.inactiveVersion,
              options
            );
          }
          if (
            message.lastUpdatedBy != null &&
            message.hasOwnProperty('lastUpdatedBy')
          ) {
            object.lastUpdatedBy = message.lastUpdatedBy;
          }
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (
            message.destinationApplications &&
            message.destinationApplications.length
          ) {
            object.destinationApplications = new Array(
              message.destinationApplications.length
            );
            for (let i = 0; i < message.destinationApplications.length; ++i) {
              object.destinationApplications[i] =
                workflow_destination_applications$1.v1.DestinationApplication.toObject(
                  message.destinationApplications[i],
                  options
                );
            }
          }
          if (
            message.webhooksEndpoint != null &&
            message.hasOwnProperty('webhooksEndpoint')
          ) {
            object.webhooksEndpoint = message.webhooksEndpoint;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Workflow';
        }
      }

      Workflow.prototype.id = '';
      Workflow.prototype.name = '';
      Workflow.prototype.description = null;
      Workflow.prototype.tags = $util.emptyArray;
      Workflow.prototype.timing = null;
      Workflow.prototype.status = 0;
      Workflow.prototype.sourceId = '';
      Workflow.prototype.activeVersion = null;
      Workflow.prototype.latestDraftVersion = null;
      Workflow.prototype.inactiveVersion = null;
      Workflow.prototype.lastUpdatedBy = '';
      Workflow.prototype.sourceApplication = 0;
      Workflow.prototype.destinationApplications = $util.emptyArray;
      Workflow.prototype.webhooksEndpoint = '';

      return Workflow;
    })();

    v1.WorkflowSummary = (() => {
      class WorkflowSummary {
        constructor(properties) {
          this.tags = [];
          this.destinationApplications = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowSummary(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(48).int32(message.status);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(58).string(message.sourceId);
          }
          if (
            message.lastUpdatedBy != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedBy')
          ) {
            writer.uint32(66).string(message.lastUpdatedBy);
          }
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(72).int32(message.sourceApplication);
          }
          if (
            message.destinationApplications != null &&
            Object.hasOwnProperty.call(message, 'destinationApplications')
          ) {
            for (const element of message.destinationApplications) {
              workflow_destination_applications$1.v1.DestinationApplication.encode(
                element,
                writer.uint32(82).fork()
              ).ldelim();
            }
          }
          if (
            message.webhooksEndpoint != null &&
            Object.hasOwnProperty.call(message, 'webhooksEndpoint')
          ) {
            writer.uint32(90).string(message.webhooksEndpoint);
          }
          if (
            message.activeVersion != null &&
            Object.hasOwnProperty.call(message, 'activeVersion')
          ) {
            writer.uint32(96).int64(message.activeVersion);
          }
          if (
            message.latestDraftVersion != null &&
            Object.hasOwnProperty.call(message, 'latestDraftVersion')
          ) {
            writer.uint32(104).int64(message.latestDraftVersion);
          }
          if (
            message.inactiveVersion != null &&
            Object.hasOwnProperty.call(message, 'inactiveVersion')
          ) {
            writer.uint32(112).int64(message.inactiveVersion);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.status = reader.int32();
                break;
              }
              case 7: {
                message.sourceId = reader.string();
                break;
              }
              case 8: {
                message.lastUpdatedBy = reader.string();
                break;
              }
              case 9: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 10: {
                if (
                  !message.destinationApplications ||
                  !message.destinationApplications.length
                ) {
                  message.destinationApplications = [];
                }
                message.destinationApplications.push(
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 11: {
                message.webhooksEndpoint = reader.string();
                break;
              }
              case 12: {
                message.activeVersion = reader.int64();
                break;
              }
              case 13: {
                message.latestDraftVersion = reader.int64();
                break;
              }
              case 14: {
                message.inactiveVersion = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowSummary) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowSummary();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.workflows.v1.WorkflowSummary.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowSummary.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.lastUpdatedBy != null) {
            message.lastUpdatedBy = String(object.lastUpdatedBy);
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationApplications) {
            if (!Array.isArray(object.destinationApplications)) {
              throw new TypeError(
                '.workflows.v1.WorkflowSummary.destinationApplications: array type expected, but got ' +
                  typeof object.destinationApplications
              );
            }
            message.destinationApplications = new Array(
              object.destinationApplications.length
            );
            for (let i = 0; i < object.destinationApplications.length; ++i) {
              if (typeof object.destinationApplications[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowSummary.destinationApplications: object expected, but got ' +
                    typeof object.destinationApplications[i]
                );
              }
              message.destinationApplications[i] =
                workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                  object.destinationApplications[i]
                );
            }
          }
          if (object.webhooksEndpoint != null) {
            message.webhooksEndpoint = String(object.webhooksEndpoint);
          }
          if (object.activeVersion != null) {
            if ($util.Long) {
              (message.activeVersion = $util.Long.fromValue(
                object.activeVersion
              )).unsigned = false;
            } else if (typeof object.activeVersion === 'string') {
              message.activeVersion = parseInt(object.activeVersion, 10);
            } else if (typeof object.activeVersion === 'number') {
              message.activeVersion = object.activeVersion;
            } else if (typeof object.activeVersion === 'object') {
              message.activeVersion = new $util.LongBits(
                object.activeVersion.low >>> 0,
                object.activeVersion.high >>> 0
              ).toNumber();
            }
          }
          if (object.latestDraftVersion != null) {
            if ($util.Long) {
              (message.latestDraftVersion = $util.Long.fromValue(
                object.latestDraftVersion
              )).unsigned = false;
            } else if (typeof object.latestDraftVersion === 'string') {
              message.latestDraftVersion = parseInt(
                object.latestDraftVersion,
                10
              );
            } else if (typeof object.latestDraftVersion === 'number') {
              message.latestDraftVersion = object.latestDraftVersion;
            } else if (typeof object.latestDraftVersion === 'object') {
              message.latestDraftVersion = new $util.LongBits(
                object.latestDraftVersion.low >>> 0,
                object.latestDraftVersion.high >>> 0
              ).toNumber();
            }
          }
          if (object.inactiveVersion != null) {
            if ($util.Long) {
              (message.inactiveVersion = $util.Long.fromValue(
                object.inactiveVersion
              )).unsigned = false;
            } else if (typeof object.inactiveVersion === 'string') {
              message.inactiveVersion = parseInt(object.inactiveVersion, 10);
            } else if (typeof object.inactiveVersion === 'number') {
              message.inactiveVersion = object.inactiveVersion;
            } else if (typeof object.inactiveVersion === 'object') {
              message.inactiveVersion = new $util.LongBits(
                object.inactiveVersion.low >>> 0,
                object.inactiveVersion.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
            object.destinationApplications = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.timing = null;
            object.status =
              options.enums === String ? 'UNSPECIFIED_WORKFLOW_STATUS' : 0;
            object.sourceId = '';
            object.lastUpdatedBy = '';
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.webhooksEndpoint = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.lastUpdatedBy != null &&
            message.hasOwnProperty('lastUpdatedBy')
          ) {
            object.lastUpdatedBy = message.lastUpdatedBy;
          }
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (
            message.destinationApplications &&
            message.destinationApplications.length
          ) {
            object.destinationApplications = new Array(
              message.destinationApplications.length
            );
            for (let i = 0; i < message.destinationApplications.length; ++i) {
              object.destinationApplications[i] =
                workflow_destination_applications$1.v1.DestinationApplication.toObject(
                  message.destinationApplications[i],
                  options
                );
            }
          }
          if (
            message.webhooksEndpoint != null &&
            message.hasOwnProperty('webhooksEndpoint')
          ) {
            object.webhooksEndpoint = message.webhooksEndpoint;
          }
          if (
            message.activeVersion != null &&
            message.hasOwnProperty('activeVersion')
          ) {
            object.activeVersion =
              typeof message.activeVersion === 'number'
                ? options.longs === String
                  ? String(message.activeVersion)
                  : message.activeVersion
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.activeVersion)
                : options.longs === Number
                ? new $util.LongBits(
                    message.activeVersion.low >>> 0,
                    message.activeVersion.high >>> 0
                  ).toNumber()
                : message.activeVersion;
          }
          if (
            message.latestDraftVersion != null &&
            message.hasOwnProperty('latestDraftVersion')
          ) {
            object.latestDraftVersion =
              typeof message.latestDraftVersion === 'number'
                ? options.longs === String
                  ? String(message.latestDraftVersion)
                  : message.latestDraftVersion
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.latestDraftVersion)
                : options.longs === Number
                ? new $util.LongBits(
                    message.latestDraftVersion.low >>> 0,
                    message.latestDraftVersion.high >>> 0
                  ).toNumber()
                : message.latestDraftVersion;
          }
          if (
            message.inactiveVersion != null &&
            message.hasOwnProperty('inactiveVersion')
          ) {
            object.inactiveVersion =
              typeof message.inactiveVersion === 'number'
                ? options.longs === String
                  ? String(message.inactiveVersion)
                  : message.inactiveVersion
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.inactiveVersion)
                : options.longs === Number
                ? new $util.LongBits(
                    message.inactiveVersion.low >>> 0,
                    message.inactiveVersion.high >>> 0
                  ).toNumber()
                : message.inactiveVersion;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowSummary';
        }
      }

      WorkflowSummary.prototype.id = '';
      WorkflowSummary.prototype.name = '';
      WorkflowSummary.prototype.description = null;
      WorkflowSummary.prototype.tags = $util.emptyArray;
      WorkflowSummary.prototype.timing = null;
      WorkflowSummary.prototype.status = 0;
      WorkflowSummary.prototype.sourceId = '';
      WorkflowSummary.prototype.lastUpdatedBy = '';
      WorkflowSummary.prototype.sourceApplication = 0;
      WorkflowSummary.prototype.destinationApplications = $util.emptyArray;
      WorkflowSummary.prototype.webhooksEndpoint = '';
      WorkflowSummary.prototype.activeVersion = null;
      WorkflowSummary.prototype.latestDraftVersion = null;
      WorkflowSummary.prototype.inactiveVersion = null;

      return WorkflowSummary;
    })();

    v1.Version = (() => {
      class Version {
        constructor(properties) {
          this.workflowEventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Version(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(8).int64(message.version);
          }
          if (
            message.trigger != null &&
            Object.hasOwnProperty.call(message, 'trigger')
          ) {
            $root.workflows.v1.Trigger.encode(
              message.trigger,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.createdBy != null &&
            Object.hasOwnProperty.call(message, 'createdBy')
          ) {
            writer.uint32(26).string(message.createdBy);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            $root.workflows.v1.WorkflowNodes.encode(
              message.nodes,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            $root.workflows.v1.WorkflowEdges.encode(
              message.edges,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.eventPayloadTypeHash != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadTypeHash')
          ) {
            writer.uint32(64).int64(message.eventPayloadTypeHash);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(72).int32(message.status);
          }
          if (
            message.updatedBy != null &&
            Object.hasOwnProperty.call(message, 'updatedBy')
          ) {
            writer.uint32(82).string(message.updatedBy);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.workflowEventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(
              message,
              'workflowEventPayloadFieldSchemas'
            )
          ) {
            for (const element of message.workflowEventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(98).fork()
              ).ldelim();
            }
          }
          if (
            message.eventPayloadExample != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadExample')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayloadExample,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Version();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = reader.int64();
                break;
              }
              case 2: {
                message.trigger = $root.workflows.v1.Trigger.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.createdBy = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.nodes = $root.workflows.v1.WorkflowNodes.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.edges = $root.workflows.v1.WorkflowEdges.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.eventPayloadTypeHash = reader.int64();
                break;
              }
              case 9: {
                message.status = reader.int32();
                break;
              }
              case 10: {
                message.updatedBy = reader.string();
                break;
              }
              case 11: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                if (
                  !message.workflowEventPayloadFieldSchemas ||
                  !message.workflowEventPayloadFieldSchemas.length
                ) {
                  message.workflowEventPayloadFieldSchemas = [];
                }
                message.workflowEventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 13: {
                message.eventPayloadExample = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Version) {
            return object;
          }
          const message = new $root.workflows.v1.Version();
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.trigger != null) {
            if (typeof object.trigger !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.trigger: object expected, but got ' +
                  typeof object.trigger
              );
            }
            message.trigger = $root.workflows.v1.Trigger.fromObject(
              object.trigger
            );
          }
          if (object.createdBy != null) {
            message.createdBy = String(object.createdBy);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes != null) {
            if (typeof object.nodes !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.nodes: object expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = $root.workflows.v1.WorkflowNodes.fromObject(
              object.nodes
            );
          }
          if (object.edges != null) {
            if (typeof object.edges !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.edges: object expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = $root.workflows.v1.WorkflowEdges.fromObject(
              object.edges
            );
          }
          if (object.eventPayloadTypeHash != null) {
            if ($util.Long) {
              (message.eventPayloadTypeHash = $util.Long.fromValue(
                object.eventPayloadTypeHash
              )).unsigned = false;
            } else if (typeof object.eventPayloadTypeHash === 'string') {
              message.eventPayloadTypeHash = parseInt(
                object.eventPayloadTypeHash,
                10
              );
            } else if (typeof object.eventPayloadTypeHash === 'number') {
              message.eventPayloadTypeHash = object.eventPayloadTypeHash;
            } else if (typeof object.eventPayloadTypeHash === 'object') {
              message.eventPayloadTypeHash = new $util.LongBits(
                object.eventPayloadTypeHash.low >>> 0,
                object.eventPayloadTypeHash.high >>> 0
              ).toNumber();
            }
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.updatedBy != null) {
            message.updatedBy = String(object.updatedBy);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.workflowEventPayloadFieldSchemas) {
            if (!Array.isArray(object.workflowEventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.Version.workflowEventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.workflowEventPayloadFieldSchemas
              );
            }
            message.workflowEventPayloadFieldSchemas = new Array(
              object.workflowEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < object.workflowEventPayloadFieldSchemas.length;
              ++i
            ) {
              if (
                typeof object.workflowEventPayloadFieldSchemas[i] !== 'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.Version.workflowEventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.workflowEventPayloadFieldSchemas[i]
                );
              }
              message.workflowEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.workflowEventPayloadFieldSchemas[i]
                );
            }
          }
          if (object.eventPayloadExample != null) {
            if (typeof object.eventPayloadExample !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.eventPayloadExample: object expected, but got ' +
                  typeof object.eventPayloadExample
              );
            }
            message.eventPayloadExample = google$2.protobuf.Struct.fromObject(
              object.eventPayloadExample
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflowEventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.trigger = null;
            object.createdBy = '';
            object.createdAt = null;
            object.profileLookupConfiguration = null;
            object.nodes = null;
            object.edges = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.eventPayloadTypeHash =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.eventPayloadTypeHash = options.longs === String ? '0' : 0;
            }
            object.status =
              options.enums === String ? 'UNSPECIFIED_WORKFLOW_STATUS' : 0;
            object.updatedBy = '';
            object.updatedAt = null;
            object.eventPayloadExample = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.trigger != null && message.hasOwnProperty('trigger')) {
            object.trigger = $root.workflows.v1.Trigger.toObject(
              message.trigger,
              options
            );
          }
          if (
            message.createdBy != null &&
            message.hasOwnProperty('createdBy')
          ) {
            object.createdBy = message.createdBy;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes != null && message.hasOwnProperty('nodes')) {
            object.nodes = $root.workflows.v1.WorkflowNodes.toObject(
              message.nodes,
              options
            );
          }
          if (message.edges != null && message.hasOwnProperty('edges')) {
            object.edges = $root.workflows.v1.WorkflowEdges.toObject(
              message.edges,
              options
            );
          }
          if (
            message.eventPayloadTypeHash != null &&
            message.hasOwnProperty('eventPayloadTypeHash')
          ) {
            object.eventPayloadTypeHash =
              typeof message.eventPayloadTypeHash === 'number'
                ? options.longs === String
                  ? String(message.eventPayloadTypeHash)
                  : message.eventPayloadTypeHash
                : options.longs === String
                ? $util.Long.prototype.toString.call(
                    message.eventPayloadTypeHash
                  )
                : options.longs === Number
                ? new $util.LongBits(
                    message.eventPayloadTypeHash.low >>> 0,
                    message.eventPayloadTypeHash.high >>> 0
                  ).toNumber()
                : message.eventPayloadTypeHash;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (
            message.updatedBy != null &&
            message.hasOwnProperty('updatedBy')
          ) {
            object.updatedBy = message.updatedBy;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.workflowEventPayloadFieldSchemas &&
            message.workflowEventPayloadFieldSchemas.length
          ) {
            object.workflowEventPayloadFieldSchemas = new Array(
              message.workflowEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < message.workflowEventPayloadFieldSchemas.length;
              ++i
            ) {
              object.workflowEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.workflowEventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          if (
            message.eventPayloadExample != null &&
            message.hasOwnProperty('eventPayloadExample')
          ) {
            object.eventPayloadExample = google$2.protobuf.Struct.toObject(
              message.eventPayloadExample,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Version';
        }
      }

      Version.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      Version.prototype.trigger = null;
      Version.prototype.createdBy = '';
      Version.prototype.createdAt = null;
      Version.prototype.profileLookupConfiguration = null;
      Version.prototype.nodes = null;
      Version.prototype.edges = null;
      Version.prototype.eventPayloadTypeHash = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      Version.prototype.status = 0;
      Version.prototype.updatedBy = '';
      Version.prototype.updatedAt = null;
      Version.prototype.workflowEventPayloadFieldSchemas = $util.emptyArray;
      Version.prototype.eventPayloadExample = null;

      return Version;
    })();

    v1.Trigger = (() => {
      class Trigger {
        constructor(properties) {
          this.objectTypeFilters = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Trigger(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(8).int32(message.sourceApplication);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          if (
            message.triggerEvents != null &&
            Object.hasOwnProperty.call(message, 'triggerEvents')
          ) {
            event_payload$1.v2.EventTypes.encode(
              message.triggerEvents,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.objectTypeFilters != null &&
            Object.hasOwnProperty.call(message, 'objectTypeFilters')
          ) {
            for (const element of message.objectTypeFilters) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.extraFilter != null &&
            Object.hasOwnProperty.call(message, 'extraFilter')
          ) {
            $root.workflows.v1.WorkflowFilter.encode(
              message.extraFilter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.eventPayloadStub != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadStub')
          ) {
            event_payload$1.v2.EventPayload.encode(
              message.eventPayloadStub,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Trigger();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              case 3: {
                message.triggerEvents = event_payload$1.v2.EventTypes.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                if (
                  !message.objectTypeFilters ||
                  !message.objectTypeFilters.length
                ) {
                  message.objectTypeFilters = [];
                }
                message.objectTypeFilters.push(reader.string());
                break;
              }
              case 5: {
                message.extraFilter = $root.workflows.v1.WorkflowFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.eventPayloadStub =
                  event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Trigger) {
            return object;
          }
          const message = new $root.workflows.v1.Trigger();
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.triggerEvents != null) {
            if (typeof object.triggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.triggerEvents: object expected, but got ' +
                  typeof object.triggerEvents
              );
            }
            message.triggerEvents = event_payload$1.v2.EventTypes.fromObject(
              object.triggerEvents
            );
          }
          if (object.objectTypeFilters) {
            if (!Array.isArray(object.objectTypeFilters)) {
              throw new TypeError(
                '.workflows.v1.Trigger.objectTypeFilters: array type expected, but got ' +
                  typeof object.objectTypeFilters
              );
            }
            message.objectTypeFilters = new Array(
              object.objectTypeFilters.length
            );
            for (let i = 0; i < object.objectTypeFilters.length; ++i) {
              message.objectTypeFilters[i] = String(
                object.objectTypeFilters[i]
              );
            }
          }
          if (object.extraFilter != null) {
            if (typeof object.extraFilter !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.extraFilter: object expected, but got ' +
                  typeof object.extraFilter
              );
            }
            message.extraFilter = $root.workflows.v1.WorkflowFilter.fromObject(
              object.extraFilter
            );
          }
          if (object.eventPayloadStub != null) {
            if (typeof object.eventPayloadStub !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.eventPayloadStub: object expected, but got ' +
                  typeof object.eventPayloadStub
              );
            }
            message.eventPayloadStub =
              event_payload$1.v2.EventPayload.fromObject(
                object.eventPayloadStub
              );
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeFilters = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.triggerEvents = null;
            object.eventPayloadStub = null;
          }
          let keys;
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          if (
            message.triggerEvents != null &&
            message.hasOwnProperty('triggerEvents')
          ) {
            object.triggerEvents = event_payload$1.v2.EventTypes.toObject(
              message.triggerEvents,
              options
            );
          }
          if (message.objectTypeFilters && message.objectTypeFilters.length) {
            object.objectTypeFilters = new Array(
              message.objectTypeFilters.length
            );
            for (let i = 0; i < message.objectTypeFilters.length; ++i) {
              object.objectTypeFilters[i] = message.objectTypeFilters[i];
            }
          }
          if (
            message.extraFilter != null &&
            message.hasOwnProperty('extraFilter')
          ) {
            object.extraFilter = $root.workflows.v1.WorkflowFilter.toObject(
              message.extraFilter,
              options
            );
          }
          if (
            message.eventPayloadStub != null &&
            message.hasOwnProperty('eventPayloadStub')
          ) {
            object.eventPayloadStub = event_payload$1.v2.EventPayload.toObject(
              message.eventPayloadStub,
              options
            );
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Trigger';
        }
      }

      Trigger.prototype.sourceApplication = 0;
      Trigger.prototype.objectCategory = 0;
      Trigger.prototype.triggerEvents = null;
      Trigger.prototype.objectTypeFilters = $util.emptyArray;
      Trigger.prototype.extraFilter = null;
      Trigger.prototype.eventPayloadStub = null;
      Trigger.prototype.uniquenessConflictResolution = null;

      return Trigger;
    })();

    v1.WorkflowTrigger = (() => {
      class WorkflowTrigger {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowTrigger(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.workflowName != null &&
            Object.hasOwnProperty.call(message, 'workflowName')
          ) {
            writer.uint32(18).string(message.workflowName);
          }
          if (
            message.trigger != null &&
            Object.hasOwnProperty.call(message, 'trigger')
          ) {
            $root.workflows.v1.Trigger.encode(
              message.trigger,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowTrigger();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.workflowName = reader.string();
                break;
              }
              case 3: {
                message.trigger = $root.workflows.v1.Trigger.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowTrigger) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowTrigger();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.workflowName != null) {
            message.workflowName = String(object.workflowName);
          }
          if (object.trigger != null) {
            if (typeof object.trigger !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowTrigger.trigger: object expected, but got ' +
                  typeof object.trigger
              );
            }
            message.trigger = $root.workflows.v1.Trigger.fromObject(
              object.trigger
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            object.workflowName = '';
            object.trigger = null;
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (
            message.workflowName != null &&
            message.hasOwnProperty('workflowName')
          ) {
            object.workflowName = message.workflowName;
          }
          if (message.trigger != null && message.hasOwnProperty('trigger')) {
            object.trigger = $root.workflows.v1.Trigger.toObject(
              message.trigger,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowTrigger';
        }
      }

      WorkflowTrigger.prototype.workflowId = '';
      WorkflowTrigger.prototype.workflowName = '';
      WorkflowTrigger.prototype.trigger = null;

      return WorkflowTrigger;
    })();

    v1.ValidTriggerEvents = (() => {
      class ValidTriggerEvents {
        constructor(properties) {
          this.sourceApplicationObjectCategories = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ValidTriggerEvents(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplicationObjectCategories != null &&
            Object.hasOwnProperty.call(
              message,
              'sourceApplicationObjectCategories'
            )
          ) {
            for (const element of message.sourceApplicationObjectCategories) {
              $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ValidTriggerEvents();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.sourceApplicationObjectCategories ||
                  !message.sourceApplicationObjectCategories.length
                ) {
                  message.sourceApplicationObjectCategories = [];
                }
                message.sourceApplicationObjectCategories.push(
                  $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ValidTriggerEvents) {
            return object;
          }
          const message = new $root.workflows.v1.ValidTriggerEvents();
          if (object.sourceApplicationObjectCategories) {
            if (!Array.isArray(object.sourceApplicationObjectCategories)) {
              throw new TypeError(
                '.workflows.v1.ValidTriggerEvents.sourceApplicationObjectCategories: array type expected, but got ' +
                  typeof object.sourceApplicationObjectCategories
              );
            }
            message.sourceApplicationObjectCategories = new Array(
              object.sourceApplicationObjectCategories.length
            );
            for (
              let i = 0;
              i < object.sourceApplicationObjectCategories.length;
              ++i
            ) {
              if (
                typeof object.sourceApplicationObjectCategories[i] !== 'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.sourceApplicationObjectCategories: object expected, but got ' +
                    typeof object.sourceApplicationObjectCategories[i]
                );
              }
              message.sourceApplicationObjectCategories[i] =
                $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.fromObject(
                  object.sourceApplicationObjectCategories[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sourceApplicationObjectCategories = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.sourceApplicationObjectCategories &&
            message.sourceApplicationObjectCategories.length
          ) {
            object.sourceApplicationObjectCategories = new Array(
              message.sourceApplicationObjectCategories.length
            );
            for (
              let i = 0;
              i < message.sourceApplicationObjectCategories.length;
              ++i
            ) {
              object.sourceApplicationObjectCategories[i] =
                $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.toObject(
                  message.sourceApplicationObjectCategories[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ValidTriggerEvents';
        }
      }

      ValidTriggerEvents.prototype.sourceApplicationObjectCategories =
        $util.emptyArray;
      ValidTriggerEvents.SourceApplicationObjectCategories = (() => {
        class SourceApplicationObjectCategories {
          constructor(properties) {
            this.objectCategoryEventPayloads = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SourceApplicationObjectCategories(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sourceApplication != null &&
              Object.hasOwnProperty.call(message, 'sourceApplication')
            ) {
              writer.uint32(8).int32(message.sourceApplication);
            }
            if (
              message.objectCategoryEventPayloads != null &&
              Object.hasOwnProperty.call(message, 'objectCategoryEventPayloads')
            ) {
              for (const element of message.objectCategoryEventPayloads) {
                $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.sourceAlreadyExists != null &&
              Object.hasOwnProperty.call(message, 'sourceAlreadyExists')
            ) {
              writer.uint32(24).bool(message.sourceAlreadyExists);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SourceApplicationObjectCategories();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sourceApplication = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.objectCategoryEventPayloads ||
                    !message.objectCategoryEventPayloads.length
                  ) {
                    message.objectCategoryEventPayloads = [];
                  }
                  message.objectCategoryEventPayloads.push(
                    $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.sourceAlreadyExists = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents
                .SourceApplicationObjectCategories
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories();
            if (object.sourceApplication != null) {
              switch (object.sourceApplication) {
                case 'UNSPECIFIED_SOURCE_APPLICATION':
                case 0: {
                  message.sourceApplication = 0;
                  break;
                }
                case 'FORMSORT':
                case 1: {
                  message.sourceApplication = 1;
                  break;
                }
                case 'HEALTHIE':
                case 2: {
                  message.sourceApplication = 2;
                  break;
                }
                case 'AXLE_HEALTH':
                case 3: {
                  message.sourceApplication = 3;
                  break;
                }
                case 'BUTTERFLY_LABS':
                case 4: {
                  message.sourceApplication = 4;
                  break;
                }
                case 'MORF':
                case 5: {
                  message.sourceApplication = 5;
                  break;
                }
                case 'PUSH_JSON':
                case 6: {
                  message.sourceApplication = 6;
                  break;
                }
                case 'ACUITY':
                case 7: {
                  message.sourceApplication = 7;
                  break;
                }
                case 'VITAL':
                case 8: {
                  message.sourceApplication = 8;
                  break;
                }
                case 'SEGMENT':
                case 9: {
                  message.sourceApplication = 9;
                  break;
                }
                case 'ACTIVE_CAMPAIGN':
                case 10: {
                  message.sourceApplication = 10;
                  break;
                }
                case 'INTAKEQ':
                case 11: {
                  message.sourceApplication = 11;
                  break;
                }
                case 'CUSTOMER_IO':
                case 12: {
                  message.sourceApplication = 12;
                  break;
                }
                case 'HUBSPOT':
                case 13: {
                  message.sourceApplication = 13;
                  break;
                }
                case 'CUSTOMER':
                case 14: {
                  message.sourceApplication = 14;
                  break;
                }
                default: {
                  if (typeof object.sourceApplication == 'number') {
                    message.sourceApplication = object.sourceApplication;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.objectCategoryEventPayloads) {
              if (!Array.isArray(object.objectCategoryEventPayloads)) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.objectCategoryEventPayloads: array type expected, but got ' +
                    typeof object.objectCategoryEventPayloads
                );
              }
              message.objectCategoryEventPayloads = new Array(
                object.objectCategoryEventPayloads.length
              );
              for (
                let i = 0;
                i < object.objectCategoryEventPayloads.length;
                ++i
              ) {
                if (typeof object.objectCategoryEventPayloads[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.objectCategoryEventPayloads: object expected, but got ' +
                      typeof object.objectCategoryEventPayloads[i]
                  );
                }
                message.objectCategoryEventPayloads[i] =
                  $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.fromObject(
                    object.objectCategoryEventPayloads[i]
                  );
              }
            }
            if (object.sourceAlreadyExists != null) {
              message.sourceAlreadyExists = Boolean(object.sourceAlreadyExists);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.objectCategoryEventPayloads = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.sourceApplication =
                options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
              object.sourceAlreadyExists = false;
            }
            let keys;
            if (
              message.sourceApplication != null &&
              message.hasOwnProperty('sourceApplication')
            ) {
              object.sourceApplication =
                options.enums === String
                  ? source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ] === undefined
                    ? message.sourceApplication
                    : source_application$1.v2.SourceApplication.Application[
                        message.sourceApplication
                      ]
                  : message.sourceApplication;
            }
            if (
              message.objectCategoryEventPayloads &&
              message.objectCategoryEventPayloads.length
            ) {
              object.objectCategoryEventPayloads = new Array(
                message.objectCategoryEventPayloads.length
              );
              for (
                let i = 0;
                i < message.objectCategoryEventPayloads.length;
                ++i
              ) {
                object.objectCategoryEventPayloads[i] =
                  $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.toObject(
                    message.objectCategoryEventPayloads[i],
                    options
                  );
              }
            }
            if (
              message.sourceAlreadyExists != null &&
              message.hasOwnProperty('sourceAlreadyExists')
            ) {
              object.sourceAlreadyExists = message.sourceAlreadyExists;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories'
            );
          }
        }

        SourceApplicationObjectCategories.prototype.sourceApplication = 0;
        SourceApplicationObjectCategories.prototype.objectCategoryEventPayloads =
          $util.emptyArray;
        SourceApplicationObjectCategories.prototype.sourceAlreadyExists = false;

        return SourceApplicationObjectCategories;
      })();

      ValidTriggerEvents.ObjectCategoryEventPayloads = (() => {
        class ObjectCategoryEventPayloads {
          constructor(properties) {
            this.eventPayloadEventTypes = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ObjectCategoryEventPayloads(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.objectCategory != null &&
              Object.hasOwnProperty.call(message, 'objectCategory')
            ) {
              writer.uint32(8).int32(message.objectCategory);
            }
            if (
              message.eventPayloadEventTypes != null &&
              Object.hasOwnProperty.call(message, 'eventPayloadEventTypes')
            ) {
              for (const element of message.eventPayloadEventTypes) {
                $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ObjectCategoryEventPayloads();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.objectCategory = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.eventPayloadEventTypes ||
                    !message.eventPayloadEventTypes.length
                  ) {
                    message.eventPayloadEventTypes = [];
                  }
                  message.eventPayloadEventTypes.push(
                    $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads();
            if (object.objectCategory != null) {
              switch (object.objectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.objectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.objectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.objectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.objectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.objectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.objectCategory == 'number') {
                    message.objectCategory = object.objectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.eventPayloadEventTypes) {
              if (!Array.isArray(object.eventPayloadEventTypes)) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.eventPayloadEventTypes: array type expected, but got ' +
                    typeof object.eventPayloadEventTypes
                );
              }
              message.eventPayloadEventTypes = new Array(
                object.eventPayloadEventTypes.length
              );
              for (let i = 0; i < object.eventPayloadEventTypes.length; ++i) {
                if (typeof object.eventPayloadEventTypes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.eventPayloadEventTypes: object expected, but got ' +
                      typeof object.eventPayloadEventTypes[i]
                  );
                }
                message.eventPayloadEventTypes[i] =
                  $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.fromObject(
                    object.eventPayloadEventTypes[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventPayloadEventTypes = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.objectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            }
            let keys;
            if (
              message.objectCategory != null &&
              message.hasOwnProperty('objectCategory')
            ) {
              object.objectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ] === undefined
                    ? message.objectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.objectCategory
                      ]
                  : message.objectCategory;
            }
            if (
              message.eventPayloadEventTypes &&
              message.eventPayloadEventTypes.length
            ) {
              object.eventPayloadEventTypes = new Array(
                message.eventPayloadEventTypes.length
              );
              for (let i = 0; i < message.eventPayloadEventTypes.length; ++i) {
                object.eventPayloadEventTypes[i] =
                  $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.toObject(
                    message.eventPayloadEventTypes[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads'
            );
          }
        }

        ObjectCategoryEventPayloads.prototype.objectCategory = 0;
        ObjectCategoryEventPayloads.prototype.eventPayloadEventTypes =
          $util.emptyArray;

        return ObjectCategoryEventPayloads;
      })();

      ValidTriggerEvents.EventPayloadEventTypes = (() => {
        class EventPayloadEventTypes {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventPayloadEventTypes(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.validPayloadEventTypes != null &&
              Object.hasOwnProperty.call(message, 'validPayloadEventTypes')
            ) {
              event_payload$1.v2.EventTypes.encode(
                message.validPayloadEventTypes,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.payloadName != null &&
              Object.hasOwnProperty.call(message, 'payloadName')
            ) {
              writer.uint32(18).string(message.payloadName);
            }
            if (
              message.payloadStub != null &&
              Object.hasOwnProperty.call(message, 'payloadStub')
            ) {
              event_payload$1.v2.EventPayload.encode(
                message.payloadStub,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventPayloadEventTypes();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.validPayloadEventTypes =
                    event_payload$1.v2.EventTypes.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.payloadName = reader.string();
                  break;
                }
                case 3: {
                  message.payloadStub = event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes();
            if (object.validPayloadEventTypes != null) {
              if (typeof object.validPayloadEventTypes !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.validPayloadEventTypes: object expected, but got ' +
                    typeof object.validPayloadEventTypes
                );
              }
              message.validPayloadEventTypes =
                event_payload$1.v2.EventTypes.fromObject(
                  object.validPayloadEventTypes
                );
            }
            if (object.payloadName != null) {
              message.payloadName = String(object.payloadName);
            }
            if (object.payloadStub != null) {
              if (typeof object.payloadStub !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.payloadStub: object expected, but got ' +
                    typeof object.payloadStub
                );
              }
              message.payloadStub = event_payload$1.v2.EventPayload.fromObject(
                object.payloadStub
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.validPayloadEventTypes = null;
              object.payloadName = '';
              object.payloadStub = null;
            }
            let keys;
            if (
              message.validPayloadEventTypes != null &&
              message.hasOwnProperty('validPayloadEventTypes')
            ) {
              object.validPayloadEventTypes =
                event_payload$1.v2.EventTypes.toObject(
                  message.validPayloadEventTypes,
                  options
                );
            }
            if (
              message.payloadName != null &&
              message.hasOwnProperty('payloadName')
            ) {
              object.payloadName = message.payloadName;
            }
            if (
              message.payloadStub != null &&
              message.hasOwnProperty('payloadStub')
            ) {
              object.payloadStub = event_payload$1.v2.EventPayload.toObject(
                message.payloadStub,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.EventPayloadEventTypes'
            );
          }
        }

        EventPayloadEventTypes.prototype.validPayloadEventTypes = null;
        EventPayloadEventTypes.prototype.payloadName = '';
        EventPayloadEventTypes.prototype.payloadStub = null;

        return EventPayloadEventTypes;
      })();

      return ValidTriggerEvents;
    })();

    v1.WorkflowEdge = (() => {
      class WorkflowEdge {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEdge(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.from != null &&
            Object.hasOwnProperty.call(message, 'from')
          ) {
            writer.uint32(10).string(message.from);
          }
          if (message.to != null && Object.hasOwnProperty.call(message, 'to')) {
            writer.uint32(18).string(message.to);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEdge();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.from = reader.string();
                break;
              }
              case 2: {
                message.to = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowEdge) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowEdge();
          if (object.from != null) {
            message.from = String(object.from);
          }
          if (object.to != null) {
            message.to = String(object.to);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.from = '';
            object.to = '';
          }
          let keys;
          if (message.from != null && message.hasOwnProperty('from')) {
            object.from = message.from;
          }
          if (message.to != null && message.hasOwnProperty('to')) {
            object.to = message.to;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowEdge';
        }
      }

      WorkflowEdge.prototype.from = '';
      WorkflowEdge.prototype.to = '';

      return WorkflowEdge;
    })();

    v1.WorkflowNode = (() => {
      class WorkflowNode {
        get type() {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowNode(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.WorkflowFilter.encode(
              message.filter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.pause != null &&
            Object.hasOwnProperty.call(message, 'pause')
          ) {
            $root.workflows.v1.WorkflowPause.encode(
              message.pause,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.restart != null &&
            Object.hasOwnProperty.call(message, 'restart')
          ) {
            $root.workflows.v1.WorkflowRestart.encode(
              message.restart,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowNode();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.filter = $root.workflows.v1.WorkflowFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.pause = $root.workflows.v1.WorkflowPause.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.restart = $root.workflows.v1.WorkflowRestart.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowNode) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowNode();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.workflows.v1.WorkflowFilter.fromObject(
              object.filter
            );
          }
          if (object.pause != null) {
            if (typeof object.pause !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.pause: object expected, but got ' +
                  typeof object.pause
              );
            }
            message.pause = $root.workflows.v1.WorkflowPause.fromObject(
              object.pause
            );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.restart != null) {
            if (typeof object.restart !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.restart: object expected, but got ' +
                  typeof object.restart
              );
            }
            message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
              object.restart
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.type = 'destinationAction';
            }
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.workflows.v1.WorkflowFilter.toObject(
              message.filter,
              options
            );
            if (options.oneofs) {
              object.type = 'filter';
            }
          }
          if (message.pause != null && message.hasOwnProperty('pause')) {
            object.pause = $root.workflows.v1.WorkflowPause.toObject(
              message.pause,
              options
            );
            if (options.oneofs) {
              object.type = 'pause';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.type = 'fetchAction';
            }
          }
          if (message.restart != null && message.hasOwnProperty('restart')) {
            object.restart = $root.workflows.v1.WorkflowRestart.toObject(
              message.restart,
              options
            );
            if (options.oneofs) {
              object.type = 'restart';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowNode';
        }
      }

      WorkflowNode.prototype.id = '';
      WorkflowNode.prototype.name = null;
      WorkflowNode.prototype.description = null;
      WorkflowNode.prototype.destinationAction = null;
      WorkflowNode.prototype.filter = null;
      WorkflowNode.prototype.pause = null;
      WorkflowNode.prototype.fetchAction = null;
      WorkflowNode.prototype.restart = null;

      return WorkflowNode;
    })();

    v1.WorkflowNodes = (() => {
      class WorkflowNodes {
        constructor(properties) {
          this.nodes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowNodes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            for (const element of message.nodes) {
              $root.workflows.v1.WorkflowNode.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowNodes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.nodes || !message.nodes.length) {
                  message.nodes = [];
                }
                message.nodes.push(
                  $root.workflows.v1.WorkflowNode.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowNodes) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowNodes();
          if (object.nodes) {
            if (!Array.isArray(object.nodes)) {
              throw new TypeError(
                '.workflows.v1.WorkflowNodes.nodes: array type expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = new Array(object.nodes.length);
            for (let i = 0; i < object.nodes.length; ++i) {
              if (typeof object.nodes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowNodes.nodes: object expected, but got ' +
                    typeof object.nodes[i]
                );
              }
              message.nodes[i] = $root.workflows.v1.WorkflowNode.fromObject(
                object.nodes[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.nodes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.nodes && message.nodes.length) {
            object.nodes = new Array(message.nodes.length);
            for (let i = 0; i < message.nodes.length; ++i) {
              object.nodes[i] = $root.workflows.v1.WorkflowNode.toObject(
                message.nodes[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowNodes';
        }
      }

      WorkflowNodes.prototype.nodes = $util.emptyArray;

      return WorkflowNodes;
    })();

    v1.WorkflowEdges = (() => {
      class WorkflowEdges {
        constructor(properties) {
          this.edges = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEdges(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            for (const element of message.edges) {
              $root.workflows.v1.WorkflowEdge.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEdges();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.edges || !message.edges.length) {
                  message.edges = [];
                }
                message.edges.push(
                  $root.workflows.v1.WorkflowEdge.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowEdges) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowEdges();
          if (object.edges) {
            if (!Array.isArray(object.edges)) {
              throw new TypeError(
                '.workflows.v1.WorkflowEdges.edges: array type expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = new Array(object.edges.length);
            for (let i = 0; i < object.edges.length; ++i) {
              if (typeof object.edges[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowEdges.edges: object expected, but got ' +
                    typeof object.edges[i]
                );
              }
              message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                object.edges[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.edges = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.edges && message.edges.length) {
            object.edges = new Array(message.edges.length);
            for (let i = 0; i < message.edges.length; ++i) {
              object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                message.edges[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowEdges';
        }
      }

      WorkflowEdges.prototype.edges = $util.emptyArray;

      return WorkflowEdges;
    })();

    v1.WorkflowDestinationAction = (() => {
      class WorkflowDestinationAction {
        get type() {
          for (const key of [
            'activeCampaignV1',
            'customerIoV1',
            'healthieV1',
            'morfV1',
            'intakeqV1',
            'segmentV1',
            'spruceV1',
            'slackV1',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'activeCampaignV1',
            'customerIoV1',
            'healthieV1',
            'morfV1',
            'intakeqV1',
            'segmentV1',
            'spruceV1',
            'slackV1',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowDestinationAction(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.parameterMissingPolicy != null &&
            Object.hasOwnProperty.call(message, 'parameterMissingPolicy')
          ) {
            workflow_parameters$1.v1.ParameterMissingPolicy.encode(
              message.parameterMissingPolicy,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.retryPolicy != null &&
            Object.hasOwnProperty.call(message, 'retryPolicy')
          ) {
            workflow_parameters$1.v1.DestinationActionRetryPolicy.encode(
              message.retryPolicy,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            workflow_destination_applications$1.v1.DestinationApplication.encode(
              message.application,
              writer.uint32(114).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignV1 != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignV1')
          ) {
            destinationactions$1.activecampaign.v1.DestinationAction.encode(
              message.activeCampaignV1,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.customerIoV1 != null &&
            Object.hasOwnProperty.call(message, 'customerIoV1')
          ) {
            destinationactions$2.customerio.v1.DestinationAction.encode(
              message.customerIoV1,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.healthieV1 != null &&
            Object.hasOwnProperty.call(message, 'healthieV1')
          ) {
            destinationactions$3.healthie.v1.DestinationAction.encode(
              message.healthieV1,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.morfV1 != null &&
            Object.hasOwnProperty.call(message, 'morfV1')
          ) {
            destinationactions$4.morf.v1.DestinationAction.encode(
              message.morfV1,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.intakeqV1 != null &&
            Object.hasOwnProperty.call(message, 'intakeqV1')
          ) {
            destinationactions$5.intakeq.v1.DestinationAction.encode(
              message.intakeqV1,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.segmentV1 != null &&
            Object.hasOwnProperty.call(message, 'segmentV1')
          ) {
            destinationactions$6.segment.v1.DestinationAction.encode(
              message.segmentV1,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.spruceV1 != null &&
            Object.hasOwnProperty.call(message, 'spruceV1')
          ) {
            destinationactions$7.spruce.v1.DestinationAction.encode(
              message.spruceV1,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.slackV1 != null &&
            Object.hasOwnProperty.call(message, 'slackV1')
          ) {
            destinationactions$8.slack.v1.DestinationAction.encode(
              message.slackV1,
              writer.uint32(98).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowDestinationAction();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.parameterMissingPolicy =
                  workflow_parameters$1.v1.ParameterMissingPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.retryPolicy =
                  workflow_parameters$1.v1.DestinationActionRetryPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.application =
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.activeCampaignV1 =
                  destinationactions$1.activecampaign.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.customerIoV1 =
                  destinationactions$2.customerio.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.healthieV1 =
                  destinationactions$3.healthie.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.morfV1 =
                  destinationactions$4.morf.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.intakeqV1 =
                  destinationactions$5.intakeq.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.segmentV1 =
                  destinationactions$6.segment.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.spruceV1 =
                  destinationactions$7.spruce.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.slackV1 =
                  destinationactions$8.slack.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowDestinationAction) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowDestinationAction();
          if (object.parameterMissingPolicy != null) {
            if (typeof object.parameterMissingPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.parameterMissingPolicy: object expected, but got ' +
                  typeof object.parameterMissingPolicy
              );
            }
            message.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.fromObject(
                object.parameterMissingPolicy
              );
          }
          if (object.retryPolicy != null) {
            if (typeof object.retryPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.retryPolicy: object expected, but got ' +
                  typeof object.retryPolicy
              );
            }
            message.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.fromObject(
                object.retryPolicy
              );
          }
          if (object.application != null) {
            if (typeof object.application !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.application: object expected, but got ' +
                  typeof object.application
              );
            }
            message.application =
              workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                object.application
              );
          }
          if (object.activeCampaignV1 != null) {
            if (typeof object.activeCampaignV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.activeCampaignV1: object expected, but got ' +
                  typeof object.activeCampaignV1
              );
            }
            message.activeCampaignV1 =
              destinationactions$1.activecampaign.v1.DestinationAction.fromObject(
                object.activeCampaignV1
              );
          }
          if (object.customerIoV1 != null) {
            if (typeof object.customerIoV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.customerIoV1: object expected, but got ' +
                  typeof object.customerIoV1
              );
            }
            message.customerIoV1 =
              destinationactions$2.customerio.v1.DestinationAction.fromObject(
                object.customerIoV1
              );
          }
          if (object.healthieV1 != null) {
            if (typeof object.healthieV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.healthieV1: object expected, but got ' +
                  typeof object.healthieV1
              );
            }
            message.healthieV1 =
              destinationactions$3.healthie.v1.DestinationAction.fromObject(
                object.healthieV1
              );
          }
          if (object.morfV1 != null) {
            if (typeof object.morfV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.morfV1: object expected, but got ' +
                  typeof object.morfV1
              );
            }
            message.morfV1 =
              destinationactions$4.morf.v1.DestinationAction.fromObject(
                object.morfV1
              );
          }
          if (object.intakeqV1 != null) {
            if (typeof object.intakeqV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.intakeqV1: object expected, but got ' +
                  typeof object.intakeqV1
              );
            }
            message.intakeqV1 =
              destinationactions$5.intakeq.v1.DestinationAction.fromObject(
                object.intakeqV1
              );
          }
          if (object.segmentV1 != null) {
            if (typeof object.segmentV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.segmentV1: object expected, but got ' +
                  typeof object.segmentV1
              );
            }
            message.segmentV1 =
              destinationactions$6.segment.v1.DestinationAction.fromObject(
                object.segmentV1
              );
          }
          if (object.spruceV1 != null) {
            if (typeof object.spruceV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.spruceV1: object expected, but got ' +
                  typeof object.spruceV1
              );
            }
            message.spruceV1 =
              destinationactions$7.spruce.v1.DestinationAction.fromObject(
                object.spruceV1
              );
          }
          if (object.slackV1 != null) {
            if (typeof object.slackV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.slackV1: object expected, but got ' +
                  typeof object.slackV1
              );
            }
            message.slackV1 =
              destinationactions$8.slack.v1.DestinationAction.fromObject(
                object.slackV1
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.parameterMissingPolicy = null;
            object.retryPolicy = null;
            object.application = null;
          }
          let keys;
          if (
            message.parameterMissingPolicy != null &&
            message.hasOwnProperty('parameterMissingPolicy')
          ) {
            object.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.toObject(
                message.parameterMissingPolicy,
                options
              );
          }
          if (
            message.retryPolicy != null &&
            message.hasOwnProperty('retryPolicy')
          ) {
            object.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.toObject(
                message.retryPolicy,
                options
              );
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              workflow_destination_applications$1.v1.DestinationApplication.toObject(
                message.application,
                options
              );
          }
          if (
            message.activeCampaignV1 != null &&
            message.hasOwnProperty('activeCampaignV1')
          ) {
            object.activeCampaignV1 =
              destinationactions$1.activecampaign.v1.DestinationAction.toObject(
                message.activeCampaignV1,
                options
              );
            if (options.oneofs) {
              object.type = 'activeCampaignV1';
            }
          }
          if (
            message.customerIoV1 != null &&
            message.hasOwnProperty('customerIoV1')
          ) {
            object.customerIoV1 =
              destinationactions$2.customerio.v1.DestinationAction.toObject(
                message.customerIoV1,
                options
              );
            if (options.oneofs) {
              object.type = 'customerIoV1';
            }
          }
          if (
            message.healthieV1 != null &&
            message.hasOwnProperty('healthieV1')
          ) {
            object.healthieV1 =
              destinationactions$3.healthie.v1.DestinationAction.toObject(
                message.healthieV1,
                options
              );
            if (options.oneofs) {
              object.type = 'healthieV1';
            }
          }
          if (message.morfV1 != null && message.hasOwnProperty('morfV1')) {
            object.morfV1 =
              destinationactions$4.morf.v1.DestinationAction.toObject(
                message.morfV1,
                options
              );
            if (options.oneofs) {
              object.type = 'morfV1';
            }
          }
          if (
            message.intakeqV1 != null &&
            message.hasOwnProperty('intakeqV1')
          ) {
            object.intakeqV1 =
              destinationactions$5.intakeq.v1.DestinationAction.toObject(
                message.intakeqV1,
                options
              );
            if (options.oneofs) {
              object.type = 'intakeqV1';
            }
          }
          if (
            message.segmentV1 != null &&
            message.hasOwnProperty('segmentV1')
          ) {
            object.segmentV1 =
              destinationactions$6.segment.v1.DestinationAction.toObject(
                message.segmentV1,
                options
              );
            if (options.oneofs) {
              object.type = 'segmentV1';
            }
          }
          if (message.spruceV1 != null && message.hasOwnProperty('spruceV1')) {
            object.spruceV1 =
              destinationactions$7.spruce.v1.DestinationAction.toObject(
                message.spruceV1,
                options
              );
            if (options.oneofs) {
              object.type = 'spruceV1';
            }
          }
          if (message.slackV1 != null && message.hasOwnProperty('slackV1')) {
            object.slackV1 =
              destinationactions$8.slack.v1.DestinationAction.toObject(
                message.slackV1,
                options
              );
            if (options.oneofs) {
              object.type = 'slackV1';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowDestinationAction';
        }
      }

      WorkflowDestinationAction.prototype.parameterMissingPolicy = null;
      WorkflowDestinationAction.prototype.retryPolicy = null;
      WorkflowDestinationAction.prototype.application = null;
      WorkflowDestinationAction.prototype.activeCampaignV1 = null;
      WorkflowDestinationAction.prototype.customerIoV1 = null;
      WorkflowDestinationAction.prototype.healthieV1 = null;
      WorkflowDestinationAction.prototype.morfV1 = null;
      WorkflowDestinationAction.prototype.intakeqV1 = null;
      WorkflowDestinationAction.prototype.segmentV1 = null;
      WorkflowDestinationAction.prototype.spruceV1 = null;
      WorkflowDestinationAction.prototype.slackV1 = null;

      return WorkflowDestinationAction;
    })();

    v1.WorkflowRestart = (() => {
      class WorkflowRestart {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowRestart(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowRestart();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowRestart) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowRestart();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowRestart';
        }
      }

      return WorkflowRestart;
    })();

    v1.WorkflowFetchAction = (() => {
      class WorkflowFetchAction {
        get type() {
          for (const key of [
            'healthieV1',
            'freshdeskV1',
            'morfV1',
            'hubspotV1',
            'intercomV1',
            'facebookV1',
            'googleV1',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'healthieV1',
            'freshdeskV1',
            'morfV1',
            'hubspotV1',
            'intercomV1',
            'facebookV1',
            'googleV1',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowFetchAction(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.resultObjectKey != null &&
            Object.hasOwnProperty.call(message, 'resultObjectKey')
          ) {
            writer.uint32(10).string(message.resultObjectKey);
          }
          if (
            message.parameterMissingPolicy != null &&
            Object.hasOwnProperty.call(message, 'parameterMissingPolicy')
          ) {
            workflow_parameters$1.v1.ParameterMissingPolicy.encode(
              message.parameterMissingPolicy,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.retryPolicy != null &&
            Object.hasOwnProperty.call(message, 'retryPolicy')
          ) {
            workflow_parameters$1.v1.DestinationActionRetryPolicy.encode(
              message.retryPolicy,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            workflow_destination_applications$1.v1.DestinationApplication.encode(
              message.application,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.healthieV1 != null &&
            Object.hasOwnProperty.call(message, 'healthieV1')
          ) {
            fetchactions$1.healthie.v1.FetchAction.encode(
              message.healthieV1,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.freshdeskV1 != null &&
            Object.hasOwnProperty.call(message, 'freshdeskV1')
          ) {
            fetchactions$2.freshdesk.v1.FetchAction.encode(
              message.freshdeskV1,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.morfV1 != null &&
            Object.hasOwnProperty.call(message, 'morfV1')
          ) {
            fetchactions$3.morf.v1.FetchAction.encode(
              message.morfV1,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.hubspotV1 != null &&
            Object.hasOwnProperty.call(message, 'hubspotV1')
          ) {
            fetchactions$4.hubspot.v1.FetchAction.encode(
              message.hubspotV1,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.intercomV1 != null &&
            Object.hasOwnProperty.call(message, 'intercomV1')
          ) {
            fetchactions$5.intercom.v1.FetchAction.encode(
              message.intercomV1,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.facebookV1 != null &&
            Object.hasOwnProperty.call(message, 'facebookV1')
          ) {
            fetchactions$6.facebook.v1.FetchAction.encode(
              message.facebookV1,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.googleV1 != null &&
            Object.hasOwnProperty.call(message, 'googleV1')
          ) {
            fetchactions$7.google.v1.FetchAction.encode(
              message.googleV1,
              writer.uint32(90).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowFetchAction();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.resultObjectKey = reader.string();
                break;
              }
              case 2: {
                message.parameterMissingPolicy =
                  workflow_parameters$1.v1.ParameterMissingPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.retryPolicy =
                  workflow_parameters$1.v1.DestinationActionRetryPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.application =
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.healthieV1 =
                  fetchactions$1.healthie.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.freshdeskV1 =
                  fetchactions$2.freshdesk.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.morfV1 = fetchactions$3.morf.v1.FetchAction.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.hubspotV1 =
                  fetchactions$4.hubspot.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.intercomV1 =
                  fetchactions$5.intercom.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.facebookV1 =
                  fetchactions$6.facebook.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.googleV1 = fetchactions$7.google.v1.FetchAction.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowFetchAction) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowFetchAction();
          if (object.resultObjectKey != null) {
            message.resultObjectKey = String(object.resultObjectKey);
          }
          if (object.parameterMissingPolicy != null) {
            if (typeof object.parameterMissingPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.parameterMissingPolicy: object expected, but got ' +
                  typeof object.parameterMissingPolicy
              );
            }
            message.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.fromObject(
                object.parameterMissingPolicy
              );
          }
          if (object.retryPolicy != null) {
            if (typeof object.retryPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.retryPolicy: object expected, but got ' +
                  typeof object.retryPolicy
              );
            }
            message.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.fromObject(
                object.retryPolicy
              );
          }
          if (object.application != null) {
            if (typeof object.application !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.application: object expected, but got ' +
                  typeof object.application
              );
            }
            message.application =
              workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                object.application
              );
          }
          if (object.healthieV1 != null) {
            if (typeof object.healthieV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.healthieV1: object expected, but got ' +
                  typeof object.healthieV1
              );
            }
            message.healthieV1 =
              fetchactions$1.healthie.v1.FetchAction.fromObject(
                object.healthieV1
              );
          }
          if (object.freshdeskV1 != null) {
            if (typeof object.freshdeskV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.freshdeskV1: object expected, but got ' +
                  typeof object.freshdeskV1
              );
            }
            message.freshdeskV1 =
              fetchactions$2.freshdesk.v1.FetchAction.fromObject(
                object.freshdeskV1
              );
          }
          if (object.morfV1 != null) {
            if (typeof object.morfV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.morfV1: object expected, but got ' +
                  typeof object.morfV1
              );
            }
            message.morfV1 = fetchactions$3.morf.v1.FetchAction.fromObject(
              object.morfV1
            );
          }
          if (object.hubspotV1 != null) {
            if (typeof object.hubspotV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.hubspotV1: object expected, but got ' +
                  typeof object.hubspotV1
              );
            }
            message.hubspotV1 =
              fetchactions$4.hubspot.v1.FetchAction.fromObject(
                object.hubspotV1
              );
          }
          if (object.intercomV1 != null) {
            if (typeof object.intercomV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.intercomV1: object expected, but got ' +
                  typeof object.intercomV1
              );
            }
            message.intercomV1 =
              fetchactions$5.intercom.v1.FetchAction.fromObject(
                object.intercomV1
              );
          }
          if (object.facebookV1 != null) {
            if (typeof object.facebookV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.facebookV1: object expected, but got ' +
                  typeof object.facebookV1
              );
            }
            message.facebookV1 =
              fetchactions$6.facebook.v1.FetchAction.fromObject(
                object.facebookV1
              );
          }
          if (object.googleV1 != null) {
            if (typeof object.googleV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.googleV1: object expected, but got ' +
                  typeof object.googleV1
              );
            }
            message.googleV1 = fetchactions$7.google.v1.FetchAction.fromObject(
              object.googleV1
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.resultObjectKey = '';
            object.parameterMissingPolicy = null;
            object.retryPolicy = null;
            object.application = null;
          }
          let keys;
          if (
            message.resultObjectKey != null &&
            message.hasOwnProperty('resultObjectKey')
          ) {
            object.resultObjectKey = message.resultObjectKey;
          }
          if (
            message.parameterMissingPolicy != null &&
            message.hasOwnProperty('parameterMissingPolicy')
          ) {
            object.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.toObject(
                message.parameterMissingPolicy,
                options
              );
          }
          if (
            message.retryPolicy != null &&
            message.hasOwnProperty('retryPolicy')
          ) {
            object.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.toObject(
                message.retryPolicy,
                options
              );
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              workflow_destination_applications$1.v1.DestinationApplication.toObject(
                message.application,
                options
              );
          }
          if (
            message.healthieV1 != null &&
            message.hasOwnProperty('healthieV1')
          ) {
            object.healthieV1 = fetchactions$1.healthie.v1.FetchAction.toObject(
              message.healthieV1,
              options
            );
            if (options.oneofs) {
              object.type = 'healthieV1';
            }
          }
          if (
            message.freshdeskV1 != null &&
            message.hasOwnProperty('freshdeskV1')
          ) {
            object.freshdeskV1 =
              fetchactions$2.freshdesk.v1.FetchAction.toObject(
                message.freshdeskV1,
                options
              );
            if (options.oneofs) {
              object.type = 'freshdeskV1';
            }
          }
          if (message.morfV1 != null && message.hasOwnProperty('morfV1')) {
            object.morfV1 = fetchactions$3.morf.v1.FetchAction.toObject(
              message.morfV1,
              options
            );
            if (options.oneofs) {
              object.type = 'morfV1';
            }
          }
          if (
            message.hubspotV1 != null &&
            message.hasOwnProperty('hubspotV1')
          ) {
            object.hubspotV1 = fetchactions$4.hubspot.v1.FetchAction.toObject(
              message.hubspotV1,
              options
            );
            if (options.oneofs) {
              object.type = 'hubspotV1';
            }
          }
          if (
            message.intercomV1 != null &&
            message.hasOwnProperty('intercomV1')
          ) {
            object.intercomV1 = fetchactions$5.intercom.v1.FetchAction.toObject(
              message.intercomV1,
              options
            );
            if (options.oneofs) {
              object.type = 'intercomV1';
            }
          }
          if (
            message.facebookV1 != null &&
            message.hasOwnProperty('facebookV1')
          ) {
            object.facebookV1 = fetchactions$6.facebook.v1.FetchAction.toObject(
              message.facebookV1,
              options
            );
            if (options.oneofs) {
              object.type = 'facebookV1';
            }
          }
          if (message.googleV1 != null && message.hasOwnProperty('googleV1')) {
            object.googleV1 = fetchactions$7.google.v1.FetchAction.toObject(
              message.googleV1,
              options
            );
            if (options.oneofs) {
              object.type = 'googleV1';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowFetchAction';
        }
      }

      WorkflowFetchAction.prototype.resultObjectKey = '';
      WorkflowFetchAction.prototype.parameterMissingPolicy = null;
      WorkflowFetchAction.prototype.retryPolicy = null;
      WorkflowFetchAction.prototype.application = null;
      WorkflowFetchAction.prototype.healthieV1 = null;
      WorkflowFetchAction.prototype.freshdeskV1 = null;
      WorkflowFetchAction.prototype.morfV1 = null;
      WorkflowFetchAction.prototype.hubspotV1 = null;
      WorkflowFetchAction.prototype.intercomV1 = null;
      WorkflowFetchAction.prototype.facebookV1 = null;
      WorkflowFetchAction.prototype.googleV1 = null;

      return WorkflowFetchAction;
    })();

    v1.WorkflowCelEnvironment = (() => {
      class WorkflowCelEnvironment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowCelEnvironment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.thirdPartyIds != null &&
            Object.hasOwnProperty.call(message, 'thirdPartyIds')
          ) {
            profiles$1.v1.ThirdPartyIds.encode(
              message.thirdPartyIds,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.tracing != null &&
            Object.hasOwnProperty.call(message, 'tracing')
          ) {
            google$2.protobuf.Struct.encode(
              message.tracing,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.now != null &&
            Object.hasOwnProperty.call(message, 'now')
          ) {
            timing$1.v1.Timestamp.encode(
              message.now,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowCelEnvironment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.tracing = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.now = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowCelEnvironment) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowCelEnvironment();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.thirdPartyIds: object expected, but got ' +
                  typeof object.thirdPartyIds
              );
            }
            message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.fromObject(
              object.thirdPartyIds
            );
          }
          if (object.tracing != null) {
            if (typeof object.tracing !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.tracing: object expected, but got ' +
                  typeof object.tracing
              );
            }
            message.tracing = google$2.protobuf.Struct.fromObject(
              object.tracing
            );
          }
          if (object.now != null) {
            if (typeof object.now !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.now: object expected, but got ' +
                  typeof object.now
              );
            }
            message.now = timing$1.v1.Timestamp.fromObject(object.now);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.thirdPartyIds = null;
            object.tracing = null;
            object.now = null;
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.thirdPartyIds != null &&
            message.hasOwnProperty('thirdPartyIds')
          ) {
            object.thirdPartyIds = profiles$1.v1.ThirdPartyIds.toObject(
              message.thirdPartyIds,
              options
            );
          }
          if (message.tracing != null && message.hasOwnProperty('tracing')) {
            object.tracing = google$2.protobuf.Struct.toObject(
              message.tracing,
              options
            );
          }
          if (message.now != null && message.hasOwnProperty('now')) {
            object.now = timing$1.v1.Timestamp.toObject(message.now, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowCelEnvironment';
        }
      }

      WorkflowCelEnvironment.prototype.organizationId = '';
      WorkflowCelEnvironment.prototype.thirdPartyIds = null;
      WorkflowCelEnvironment.prototype.tracing = null;
      WorkflowCelEnvironment.prototype.now = null;

      return WorkflowCelEnvironment;
    })();

    v1.WorkflowFilter = (() => {
      class WorkflowFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.celExpressionStr != null &&
            Object.hasOwnProperty.call(message, 'celExpressionStr')
          ) {
            writer.uint32(18).string(message.celExpressionStr);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 2: {
                message.celExpressionStr = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowFilter) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowFilter();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = '';
          }
          let keys;
          if (
            message.celExpressionStr != null &&
            message.hasOwnProperty('celExpressionStr')
          ) {
            object.celExpressionStr = message.celExpressionStr;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowFilter';
        }
      }

      WorkflowFilter.prototype.celExpressionStr = '';

      return WorkflowFilter;
    })();

    v1.WorkflowPause = (() => {
      class WorkflowPause {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowPause(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.timestamps != null &&
            Object.hasOwnProperty.call(message, 'timestamps')
          ) {
            workflow_parameters$1.v1.DestinationActionParameterSources.encode(
              message.timestamps,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.refreshEventPayload != null &&
            Object.hasOwnProperty.call(message, 'refreshEventPayload')
          ) {
            writer.uint32(16).bool(message.refreshEventPayload);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowPause();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.timestamps =
                  workflow_parameters$1.v1.DestinationActionParameterSources.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.refreshEventPayload = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowPause) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowPause();
          if (object.timestamps != null) {
            if (typeof object.timestamps !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowPause.timestamps: object expected, but got ' +
                  typeof object.timestamps
              );
            }
            message.timestamps =
              workflow_parameters$1.v1.DestinationActionParameterSources.fromObject(
                object.timestamps
              );
          }
          if (object.refreshEventPayload != null) {
            message.refreshEventPayload = Boolean(object.refreshEventPayload);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.timestamps = null;
            object.refreshEventPayload = false;
          }
          let keys;
          if (
            message.timestamps != null &&
            message.hasOwnProperty('timestamps')
          ) {
            object.timestamps =
              workflow_parameters$1.v1.DestinationActionParameterSources.toObject(
                message.timestamps,
                options
              );
          }
          if (
            message.refreshEventPayload != null &&
            message.hasOwnProperty('refreshEventPayload')
          ) {
            object.refreshEventPayload = message.refreshEventPayload;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowPause';
        }
      }

      WorkflowPause.prototype.timestamps = null;
      WorkflowPause.prototype.refreshEventPayload = false;

      return WorkflowPause;
    })();

    v1.WorkflowExecutionGraph = (() => {
      class WorkflowExecutionGraph {
        get profileSource() {
          for (const key of ['profileLookupConfiguration', 'providedProfile']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set profileSource(name) {
          for (const key of ['profileLookupConfiguration', 'providedProfile']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.roots = [];
          this.extraFetchedDataRefs = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowExecutionGraph(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.roots != null &&
            Object.hasOwnProperty.call(message, 'roots')
          ) {
            for (const element of message.roots) {
              $root.workflows.v1.WorkflowExecutionGraph.Node.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(26).string(message.organizationId);
          }
          if (
            message.organizationName != null &&
            Object.hasOwnProperty.call(message, 'organizationName')
          ) {
            writer.uint32(34).string(message.organizationName);
          }
          if (
            message.organizationType != null &&
            Object.hasOwnProperty.call(message, 'organizationType')
          ) {
            writer.uint32(40).int32(message.organizationType);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(50).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(56).int64(message.version);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(66).string(message.name);
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(74).string(message.eventSnapshotId);
          }
          if (
            message.eventPayloadIndex != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadIndex')
          ) {
            writer.uint32(80).int64(message.eventPayloadIndex);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(88).int32(message.applicationType);
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.providedProfile != null &&
            Object.hasOwnProperty.call(message, 'providedProfile')
          ) {
            $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.encode(
              message.providedProfile,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(114).string(message.webhookRequestId);
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.supersededWorkflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'supersededWorkflowExecutionId')
          ) {
            writer.uint32(130).string(message.supersededWorkflowExecutionId);
          }
          if (
            message.retryOriginalWorkflowExecutionId != null &&
            Object.hasOwnProperty.call(
              message,
              'retryOriginalWorkflowExecutionId'
            )
          ) {
            writer.uint32(138).string(message.retryOriginalWorkflowExecutionId);
          }
          if (
            message.triggeredAt != null &&
            Object.hasOwnProperty.call(message, 'triggeredAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.triggeredAt,
              writer.uint32(146).fork()
            ).ldelim();
          }
          if (
            message.extraFetchedDataRefs != null &&
            Object.hasOwnProperty.call(message, 'extraFetchedDataRefs')
          ) {
            for (const element of message.extraFetchedDataRefs) {
              workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.encode(
                element,
                writer.uint32(154).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowExecutionGraph();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.roots || !message.roots.length) {
                  message.roots = [];
                }
                message.roots.push(
                  $root.workflows.v1.WorkflowExecutionGraph.Node.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                message.organizationId = reader.string();
                break;
              }
              case 4: {
                message.organizationName = reader.string();
                break;
              }
              case 5: {
                message.organizationType = reader.int32();
                break;
              }
              case 6: {
                message.workflowId = reader.string();
                break;
              }
              case 7: {
                message.version = reader.int64();
                break;
              }
              case 8: {
                message.name = reader.string();
                break;
              }
              case 9: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 10: {
                message.eventPayloadIndex = reader.int64();
                break;
              }
              case 11: {
                message.applicationType = reader.int32();
                break;
              }
              case 12: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.providedProfile =
                  $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 15: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 16: {
                message.supersededWorkflowExecutionId = reader.string();
                break;
              }
              case 17: {
                message.retryOriginalWorkflowExecutionId = reader.string();
                break;
              }
              case 18: {
                message.triggeredAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 19: {
                if (
                  !message.extraFetchedDataRefs ||
                  !message.extraFetchedDataRefs.length
                ) {
                  message.extraFetchedDataRefs = [];
                }
                message.extraFetchedDataRefs.push(
                  workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowExecutionGraph) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowExecutionGraph();
          if (object.roots) {
            if (!Array.isArray(object.roots)) {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.roots: array type expected, but got ' +
                  typeof object.roots
              );
            }
            message.roots = new Array(object.roots.length);
            for (let i = 0; i < object.roots.length; ++i) {
              if (typeof object.roots[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.roots: object expected, but got ' +
                    typeof object.roots[i]
                );
              }
              message.roots[i] =
                $root.workflows.v1.WorkflowExecutionGraph.Node.fromObject(
                  object.roots[i]
                );
            }
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.organizationName != null) {
            message.organizationName = String(object.organizationName);
          }
          if (object.organizationType != null) {
            switch (object.organizationType) {
              case 'UNSPECIFIED_ORGANIZATION_TYPE':
              case 0: {
                message.organizationType = 0;
                break;
              }
              case 'MORF_INTERNAL':
              case 1: {
                message.organizationType = 1;
                break;
              }
              case 'CUSTOMER_SANDBOX':
              case 2: {
                message.organizationType = 2;
                break;
              }
              case 'CUSTOMER_PRODUCTION':
              case 3: {
                message.organizationType = 3;
                break;
              }
              default: {
                if (typeof object.organizationType == 'number') {
                  message.organizationType = object.organizationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventPayloadIndex != null) {
            if ($util.Long) {
              (message.eventPayloadIndex = $util.Long.fromValue(
                object.eventPayloadIndex
              )).unsigned = false;
            } else if (typeof object.eventPayloadIndex === 'string') {
              message.eventPayloadIndex = parseInt(
                object.eventPayloadIndex,
                10
              );
            } else if (typeof object.eventPayloadIndex === 'number') {
              message.eventPayloadIndex = object.eventPayloadIndex;
            } else if (typeof object.eventPayloadIndex === 'object') {
              message.eventPayloadIndex = new $util.LongBits(
                object.eventPayloadIndex.low >>> 0,
                object.eventPayloadIndex.high >>> 0
              ).toNumber();
            }
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.applicationType = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.applicationType = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.applicationType = 14;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.providedProfile != null) {
            if (typeof object.providedProfile !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.providedProfile: object expected, but got ' +
                  typeof object.providedProfile
              );
            }
            message.providedProfile =
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.fromObject(
                object.providedProfile
              );
          }
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          if (object.supersededWorkflowExecutionId != null) {
            message.supersededWorkflowExecutionId = String(
              object.supersededWorkflowExecutionId
            );
          }
          if (object.retryOriginalWorkflowExecutionId != null) {
            message.retryOriginalWorkflowExecutionId = String(
              object.retryOriginalWorkflowExecutionId
            );
          }
          if (object.triggeredAt != null) {
            if (typeof object.triggeredAt !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.triggeredAt: object expected, but got ' +
                  typeof object.triggeredAt
              );
            }
            message.triggeredAt = timing$1.v1.Timestamp.fromObject(
              object.triggeredAt
            );
          }
          if (object.extraFetchedDataRefs) {
            if (!Array.isArray(object.extraFetchedDataRefs)) {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.extraFetchedDataRefs: array type expected, but got ' +
                  typeof object.extraFetchedDataRefs
              );
            }
            message.extraFetchedDataRefs = new Array(
              object.extraFetchedDataRefs.length
            );
            for (let i = 0; i < object.extraFetchedDataRefs.length; ++i) {
              if (typeof object.extraFetchedDataRefs[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.extraFetchedDataRefs: object expected, but got ' +
                    typeof object.extraFetchedDataRefs[i]
                );
              }
              message.extraFetchedDataRefs[i] =
                workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.fromObject(
                  object.extraFetchedDataRefs[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.roots = [];
            object.extraFetchedDataRefs = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.organizationName = '';
            object.organizationType =
              options.enums === String ? 'UNSPECIFIED_ORGANIZATION_TYPE' : 0;
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.name = '';
            object.eventSnapshotId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.eventPayloadIndex =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.eventPayloadIndex = options.longs === String ? '0' : 0;
            }
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
          }
          let keys;
          if (message.roots && message.roots.length) {
            object.roots = new Array(message.roots.length);
            for (let i = 0; i < message.roots.length; ++i) {
              object.roots[i] =
                $root.workflows.v1.WorkflowExecutionGraph.Node.toObject(
                  message.roots[i],
                  options
                );
            }
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.organizationName != null &&
            message.hasOwnProperty('organizationName')
          ) {
            object.organizationName = message.organizationName;
          }
          if (
            message.organizationType != null &&
            message.hasOwnProperty('organizationType')
          ) {
            object.organizationType =
              options.enums === String
                ? accounts$1.v1.OrganizationType[message.organizationType] ===
                  undefined
                  ? message.organizationType
                  : accounts$1.v1.OrganizationType[message.organizationType]
                : message.organizationType;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventPayloadIndex != null &&
            message.hasOwnProperty('eventPayloadIndex')
          ) {
            object.eventPayloadIndex =
              typeof message.eventPayloadIndex === 'number'
                ? options.longs === String
                  ? String(message.eventPayloadIndex)
                  : message.eventPayloadIndex
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.eventPayloadIndex)
                : options.longs === Number
                ? new $util.LongBits(
                    message.eventPayloadIndex.low >>> 0,
                    message.eventPayloadIndex.high >>> 0
                  ).toNumber()
                : message.eventPayloadIndex;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : source_application$1.v2.SourceApplication.Application[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
            if (options.oneofs) {
              object.profileSource = 'profileLookupConfiguration';
            }
          }
          if (
            message.providedProfile != null &&
            message.hasOwnProperty('providedProfile')
          ) {
            object.providedProfile =
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.toObject(
                message.providedProfile,
                options
              );
            if (options.oneofs) {
              object.profileSource = 'providedProfile';
            }
          }
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          if (
            message.supersededWorkflowExecutionId != null &&
            message.hasOwnProperty('supersededWorkflowExecutionId')
          ) {
            object.supersededWorkflowExecutionId =
              message.supersededWorkflowExecutionId;
          }
          if (
            message.retryOriginalWorkflowExecutionId != null &&
            message.hasOwnProperty('retryOriginalWorkflowExecutionId')
          ) {
            object.retryOriginalWorkflowExecutionId =
              message.retryOriginalWorkflowExecutionId;
          }
          if (
            message.triggeredAt != null &&
            message.hasOwnProperty('triggeredAt')
          ) {
            object.triggeredAt = timing$1.v1.Timestamp.toObject(
              message.triggeredAt,
              options
            );
          }
          if (
            message.extraFetchedDataRefs &&
            message.extraFetchedDataRefs.length
          ) {
            object.extraFetchedDataRefs = new Array(
              message.extraFetchedDataRefs.length
            );
            for (let i = 0; i < message.extraFetchedDataRefs.length; ++i) {
              object.extraFetchedDataRefs[i] =
                workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.toObject(
                  message.extraFetchedDataRefs[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowExecutionGraph';
        }
      }

      WorkflowExecutionGraph.prototype.roots = $util.emptyArray;
      WorkflowExecutionGraph.prototype.organizationId = '';
      WorkflowExecutionGraph.prototype.organizationName = '';
      WorkflowExecutionGraph.prototype.organizationType = 0;
      WorkflowExecutionGraph.prototype.workflowId = '';
      WorkflowExecutionGraph.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      WorkflowExecutionGraph.prototype.name = '';
      WorkflowExecutionGraph.prototype.eventSnapshotId = '';
      WorkflowExecutionGraph.prototype.eventPayloadIndex = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      WorkflowExecutionGraph.prototype.applicationType = 0;
      WorkflowExecutionGraph.prototype.profileLookupConfiguration = null;
      WorkflowExecutionGraph.prototype.providedProfile = null;
      WorkflowExecutionGraph.prototype.webhookRequestId = null;
      WorkflowExecutionGraph.prototype.uniquenessConflictResolution = null;
      WorkflowExecutionGraph.prototype.supersededWorkflowExecutionId = null;
      WorkflowExecutionGraph.prototype.retryOriginalWorkflowExecutionId = null;
      WorkflowExecutionGraph.prototype.triggeredAt = null;
      WorkflowExecutionGraph.prototype.extraFetchedDataRefs = $util.emptyArray;
      WorkflowExecutionGraph.Node = (() => {
        class Node {
          constructor(properties) {
            this.children = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Node(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.node != null &&
              Object.hasOwnProperty.call(message, 'node')
            ) {
              $root.workflows.v1.WorkflowNode.encode(
                message.node,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.children != null &&
              Object.hasOwnProperty.call(message, 'children')
            ) {
              for (const element of message.children) {
                $root.workflows.v1.WorkflowExecutionGraph.Node.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Node();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.node = $root.workflows.v1.WorkflowNode.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  if (!message.children || !message.children.length) {
                    message.children = [];
                  }
                  message.children.push(
                    $root.workflows.v1.WorkflowExecutionGraph.Node.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.workflows.v1.WorkflowExecutionGraph.Node
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.WorkflowExecutionGraph.Node();
            if (object.node != null) {
              if (typeof object.node !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.Node.node: object expected, but got ' +
                    typeof object.node
                );
              }
              message.node = $root.workflows.v1.WorkflowNode.fromObject(
                object.node
              );
            }
            if (object.children) {
              if (!Array.isArray(object.children)) {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.Node.children: array type expected, but got ' +
                    typeof object.children
                );
              }
              message.children = new Array(object.children.length);
              for (let i = 0; i < object.children.length; ++i) {
                if (typeof object.children[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.WorkflowExecutionGraph.Node.children: object expected, but got ' +
                      typeof object.children[i]
                  );
                }
                message.children[i] =
                  $root.workflows.v1.WorkflowExecutionGraph.Node.fromObject(
                    object.children[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.children = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.node = null;
            }
            let keys;
            if (message.node != null && message.hasOwnProperty('node')) {
              object.node = $root.workflows.v1.WorkflowNode.toObject(
                message.node,
                options
              );
            }
            if (message.children && message.children.length) {
              object.children = new Array(message.children.length);
              for (let i = 0; i < message.children.length; ++i) {
                object.children[i] =
                  $root.workflows.v1.WorkflowExecutionGraph.Node.toObject(
                    message.children[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.WorkflowExecutionGraph.Node';
          }
        }

        Node.prototype.node = null;
        Node.prototype.children = $util.emptyArray;

        return Node;
      })();

      WorkflowExecutionGraph.ProvidedProfile = (() => {
        class ProvidedProfile {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ProvidedProfile(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.profile != null &&
              Object.hasOwnProperty.call(message, 'profile')
            ) {
              profiles$1.v1.Profile.encode(
                message.profile,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.originalLookupResult != null &&
              Object.hasOwnProperty.call(message, 'originalLookupResult')
            ) {
              profiles$1.v1.LookupExecutionResult.encode(
                message.originalLookupResult,
                writer.uint32(18).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ProvidedProfile();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.profile = profiles$1.v1.Profile.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.originalLookupResult =
                    profiles$1.v1.LookupExecutionResult.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile();
            if (object.profile != null) {
              if (typeof object.profile !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.profile: object expected, but got ' +
                    typeof object.profile
                );
              }
              message.profile = profiles$1.v1.Profile.fromObject(
                object.profile
              );
            }
            if (object.originalLookupResult != null) {
              if (typeof object.originalLookupResult !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.originalLookupResult: object expected, but got ' +
                    typeof object.originalLookupResult
                );
              }
              message.originalLookupResult =
                profiles$1.v1.LookupExecutionResult.fromObject(
                  object.originalLookupResult
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.profile = null;
              object.originalLookupResult = null;
            }
            let keys;
            if (message.profile != null && message.hasOwnProperty('profile')) {
              object.profile = profiles$1.v1.Profile.toObject(
                message.profile,
                options
              );
            }
            if (
              message.originalLookupResult != null &&
              message.hasOwnProperty('originalLookupResult')
            ) {
              object.originalLookupResult =
                profiles$1.v1.LookupExecutionResult.toObject(
                  message.originalLookupResult,
                  options
                );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.WorkflowExecutionGraph.ProvidedProfile'
            );
          }
        }

        ProvidedProfile.prototype.profile = null;
        ProvidedProfile.prototype.originalLookupResult = null;

        return ProvidedProfile;
      })();

      return WorkflowExecutionGraph;
    })();

    v1.InternalWorkflowExecutionIds = (() => {
      class InternalWorkflowExecutionIds {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InternalWorkflowExecutionIds(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.temporalWorkflowId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowId')
          ) {
            writer.uint32(10).string(message.temporalWorkflowId);
          }
          if (
            message.temporalWorkflowRunId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowRunId')
          ) {
            writer.uint32(18).string(message.temporalWorkflowRunId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InternalWorkflowExecutionIds();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.temporalWorkflowId = reader.string();
                break;
              }
              case 2: {
                message.temporalWorkflowRunId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.InternalWorkflowExecutionIds
          ) {
            return object;
          }
          const message = new $root.workflows.v1.InternalWorkflowExecutionIds();
          if (object.temporalWorkflowId != null) {
            message.temporalWorkflowId = String(object.temporalWorkflowId);
          }
          if (object.temporalWorkflowRunId != null) {
            message.temporalWorkflowRunId = String(
              object.temporalWorkflowRunId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.temporalWorkflowId = '';
            object.temporalWorkflowRunId = '';
          }
          let keys;
          if (
            message.temporalWorkflowId != null &&
            message.hasOwnProperty('temporalWorkflowId')
          ) {
            object.temporalWorkflowId = message.temporalWorkflowId;
          }
          if (
            message.temporalWorkflowRunId != null &&
            message.hasOwnProperty('temporalWorkflowRunId')
          ) {
            object.temporalWorkflowRunId = message.temporalWorkflowRunId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.InternalWorkflowExecutionIds';
        }
      }

      InternalWorkflowExecutionIds.prototype.temporalWorkflowId = '';
      InternalWorkflowExecutionIds.prototype.temporalWorkflowRunId = '';

      return InternalWorkflowExecutionIds;
    })();

    v1.WorkflowUniquenessConflictResolution = (() => {
      class WorkflowUniquenessConflictResolution {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowUniquenessConflictResolution(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.conflictResolution != null &&
            Object.hasOwnProperty.call(message, 'conflictResolution')
          ) {
            writer.uint32(8).int32(message.conflictResolution);
          }
          if (
            message.scope != null &&
            Object.hasOwnProperty.call(message, 'scope')
          ) {
            writer.uint32(16).int32(message.scope);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowUniquenessConflictResolution();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.conflictResolution = reader.int32();
                break;
              }
              case 2: {
                message.scope = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.WorkflowUniquenessConflictResolution
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.WorkflowUniquenessConflictResolution();
          if (object.conflictResolution != null) {
            switch (object.conflictResolution) {
              case 'UNSPECIFIED_CONFLICT_RESOLUTION':
              case 0: {
                message.conflictResolution = 0;
                break;
              }
              case 'SUPERSEDE_EXISTING':
              case 1: {
                message.conflictResolution = 1;
                break;
              }
              case 'SKIP_NEW':
              case 2: {
                message.conflictResolution = 2;
                break;
              }
              default: {
                if (typeof object.conflictResolution == 'number') {
                  message.conflictResolution = object.conflictResolution;
                  break;
                }
                break;
              }
            }
          }
          if (object.scope != null) {
            switch (object.scope) {
              case 'UNSPECIFIED_CONFLICT_SCOPE':
              case 0: {
                message.scope = 0;
                break;
              }
              case 'WORKFLOW':
              case 1: {
                message.scope = 1;
                break;
              }
              default: {
                if (typeof object.scope == 'number') {
                  message.scope = object.scope;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.conflictResolution =
              options.enums === String ? 'UNSPECIFIED_CONFLICT_RESOLUTION' : 0;
            object.scope =
              options.enums === String ? 'UNSPECIFIED_CONFLICT_SCOPE' : 0;
          }
          let keys;
          if (
            message.conflictResolution != null &&
            message.hasOwnProperty('conflictResolution')
          ) {
            object.conflictResolution =
              options.enums === String
                ? $root.workflows.v1.WorkflowUniquenessConflictResolution
                    .ConflictResolution[message.conflictResolution] ===
                  undefined
                  ? message.conflictResolution
                  : $root.workflows.v1.WorkflowUniquenessConflictResolution
                      .ConflictResolution[message.conflictResolution]
                : message.conflictResolution;
          }
          if (message.scope != null && message.hasOwnProperty('scope')) {
            object.scope =
              options.enums === String
                ? $root.workflows.v1.WorkflowUniquenessConflictResolution
                    .ConflictScope[message.scope] === undefined
                  ? message.scope
                  : $root.workflows.v1.WorkflowUniquenessConflictResolution
                      .ConflictScope[message.scope]
                : message.scope;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.WorkflowUniquenessConflictResolution'
          );
        }
      }

      WorkflowUniquenessConflictResolution.prototype.conflictResolution = 0;
      WorkflowUniquenessConflictResolution.prototype.scope = 0;
      WorkflowUniquenessConflictResolution.ConflictResolution = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED_CONFLICT_RESOLUTION')] = 0;
        values[(valuesById[1] = 'SUPERSEDE_EXISTING')] = 1;
        values[(valuesById[2] = 'SKIP_NEW')] = 2;
        return values;
      })();

      WorkflowUniquenessConflictResolution.ConflictScope = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED_CONFLICT_SCOPE')] = 0;
        values[(valuesById[1] = 'WORKFLOW')] = 1;
        return values;
      })();

      return WorkflowUniquenessConflictResolution;
    })();

    v1.RefreshWorkflowResult = (() => {
      class RefreshWorkflowResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RefreshWorkflowResult(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.result != null &&
            Object.hasOwnProperty.call(message, 'result')
          ) {
            workflow_evaluation_results$1.v1.DestinationActionResult.encode(
              message.result,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.refreshedWorkflow != null &&
            Object.hasOwnProperty.call(message, 'refreshedWorkflow')
          ) {
            $root.workflows.v1.WorkflowExecutionGraph.encode(
              message.refreshedWorkflow,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RefreshWorkflowResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.result =
                  workflow_evaluation_results$1.v1.DestinationActionResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.refreshedWorkflow =
                  $root.workflows.v1.WorkflowExecutionGraph.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RefreshWorkflowResult) {
            return object;
          }
          const message = new $root.workflows.v1.RefreshWorkflowResult();
          if (object.result != null) {
            if (typeof object.result !== 'object') {
              throw new TypeError(
                '.workflows.v1.RefreshWorkflowResult.result: object expected, but got ' +
                  typeof object.result
              );
            }
            message.result =
              workflow_evaluation_results$1.v1.DestinationActionResult.fromObject(
                object.result
              );
          }
          if (object.refreshedWorkflow != null) {
            if (typeof object.refreshedWorkflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.RefreshWorkflowResult.refreshedWorkflow: object expected, but got ' +
                  typeof object.refreshedWorkflow
              );
            }
            message.refreshedWorkflow =
              $root.workflows.v1.WorkflowExecutionGraph.fromObject(
                object.refreshedWorkflow
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.result = null;
            object.refreshedWorkflow = null;
          }
          let keys;
          if (message.result != null && message.hasOwnProperty('result')) {
            object.result =
              workflow_evaluation_results$1.v1.DestinationActionResult.toObject(
                message.result,
                options
              );
          }
          if (
            message.refreshedWorkflow != null &&
            message.hasOwnProperty('refreshedWorkflow')
          ) {
            object.refreshedWorkflow =
              $root.workflows.v1.WorkflowExecutionGraph.toObject(
                message.refreshedWorkflow,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RefreshWorkflowResult';
        }
      }

      RefreshWorkflowResult.prototype.result = null;
      RefreshWorkflowResult.prototype.refreshedWorkflow = null;

      return RefreshWorkflowResult;
    })();

    v1.ListObjectTypeIDsRequest = (() => {
      class ListObjectTypeIDsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypeIDsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypeIDsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListObjectTypeIDsRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListObjectTypeIDsRequest();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.application = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.application = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.application = 14;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.application
                  ] === undefined
                  ? message.application
                  : source_application$1.v2.SourceApplication.Application[
                      message.application
                    ]
                : message.application;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListObjectTypeIDsRequest';
        }
      }

      ListObjectTypeIDsRequest.prototype.application = 0;
      ListObjectTypeIDsRequest.prototype.objectCategory = 0;

      return ListObjectTypeIDsRequest;
    })();

    v1.ListObjectTypeIDsResponse = (() => {
      class ListObjectTypeIDsResponse {
        constructor(properties) {
          this.ids = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypeIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.ids != null &&
            Object.hasOwnProperty.call(message, 'ids')
          ) {
            for (const element of message.ids) {
              workflow_parameters$1.v1.ObjectTypeID.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypeIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.ids || !message.ids.length) {
                  message.ids = [];
                }
                message.ids.push(
                  workflow_parameters$1.v1.ObjectTypeID.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListObjectTypeIDsResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListObjectTypeIDsResponse();
          if (object.ids) {
            if (!Array.isArray(object.ids)) {
              throw new TypeError(
                '.workflows.v1.ListObjectTypeIDsResponse.ids: array type expected, but got ' +
                  typeof object.ids
              );
            }
            message.ids = new Array(object.ids.length);
            for (let i = 0; i < object.ids.length; ++i) {
              if (typeof object.ids[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListObjectTypeIDsResponse.ids: object expected, but got ' +
                    typeof object.ids[i]
                );
              }
              message.ids[i] = workflow_parameters$1.v1.ObjectTypeID.fromObject(
                object.ids[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.ids = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.ids && message.ids.length) {
            object.ids = new Array(message.ids.length);
            for (let i = 0; i < message.ids.length; ++i) {
              object.ids[i] = workflow_parameters$1.v1.ObjectTypeID.toObject(
                message.ids[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListObjectTypeIDsResponse';
        }
      }

      ListObjectTypeIDsResponse.prototype.ids = $util.emptyArray;

      return ListObjectTypeIDsResponse;
    })();

    v1.GetActionFieldObjectTypeIDsRequest = (() => {
      class GetActionFieldObjectTypeIDsRequest {
        get action() {
          for (const key of ['destinationAction', 'fetchAction']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set action(name) {
          for (const key of ['destinationAction', 'fetchAction']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionFieldObjectTypeIDsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.fieldName != null &&
            Object.hasOwnProperty.call(message, 'fieldName')
          ) {
            writer.uint32(26).string(message.fieldName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionFieldObjectTypeIDsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.fieldName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetActionFieldObjectTypeIDsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetActionFieldObjectTypeIDsRequest();
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsRequest.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsRequest.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.fieldName != null) {
            message.fieldName = String(object.fieldName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldName = '';
          }
          let keys;
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.action = 'destinationAction';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.action = 'fetchAction';
            }
          }
          if (
            message.fieldName != null &&
            message.hasOwnProperty('fieldName')
          ) {
            object.fieldName = message.fieldName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetActionFieldObjectTypeIDsRequest'
          );
        }
      }

      GetActionFieldObjectTypeIDsRequest.prototype.destinationAction = null;
      GetActionFieldObjectTypeIDsRequest.prototype.fetchAction = null;
      GetActionFieldObjectTypeIDsRequest.prototype.fieldName = '';

      return GetActionFieldObjectTypeIDsRequest;
    })();

    v1.GetActionFieldObjectTypeIDsResponse = (() => {
      class GetActionFieldObjectTypeIDsResponse {
        constructor(properties) {
          this.objectTypeIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionFieldObjectTypeIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectTypeIds != null &&
            Object.hasOwnProperty.call(message, 'objectTypeIds')
          ) {
            for (const element of message.objectTypeIds) {
              workflow_parameters$1.v1.ObjectTypeID.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionFieldObjectTypeIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.objectTypeIds || !message.objectTypeIds.length) {
                  message.objectTypeIds = [];
                }
                message.objectTypeIds.push(
                  workflow_parameters$1.v1.ObjectTypeID.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetActionFieldObjectTypeIDsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetActionFieldObjectTypeIDsResponse();
          if (object.objectTypeIds) {
            if (!Array.isArray(object.objectTypeIds)) {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsResponse.objectTypeIds: array type expected, but got ' +
                  typeof object.objectTypeIds
              );
            }
            message.objectTypeIds = new Array(object.objectTypeIds.length);
            for (let i = 0; i < object.objectTypeIds.length; ++i) {
              if (typeof object.objectTypeIds[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetActionFieldObjectTypeIDsResponse.objectTypeIds: object expected, but got ' +
                    typeof object.objectTypeIds[i]
                );
              }
              message.objectTypeIds[i] =
                workflow_parameters$1.v1.ObjectTypeID.fromObject(
                  object.objectTypeIds[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.objectTypeIds && message.objectTypeIds.length) {
            object.objectTypeIds = new Array(message.objectTypeIds.length);
            for (let i = 0; i < message.objectTypeIds.length; ++i) {
              object.objectTypeIds[i] =
                workflow_parameters$1.v1.ObjectTypeID.toObject(
                  message.objectTypeIds[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetActionFieldObjectTypeIDsResponse'
          );
        }
      }

      GetActionFieldObjectTypeIDsResponse.prototype.objectTypeIds =
        $util.emptyArray;

      return GetActionFieldObjectTypeIDsResponse;
    })();

    v1.GetObjectTypeFieldSchemasRequest = (() => {
      class GetObjectTypeFieldSchemasRequest {
        constructor(properties) {
          this.objectTypeIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectTypeFieldSchemasRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          if (
            message.objectTypeIds != null &&
            Object.hasOwnProperty.call(message, 'objectTypeIds')
          ) {
            for (const element of message.objectTypeIds) {
              writer.uint32(26).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectTypeFieldSchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              case 3: {
                if (!message.objectTypeIds || !message.objectTypeIds.length) {
                  message.objectTypeIds = [];
                }
                message.objectTypeIds.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetObjectTypeFieldSchemasRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetObjectTypeFieldSchemasRequest();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.application = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.application = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.application = 14;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectTypeIds) {
            if (!Array.isArray(object.objectTypeIds)) {
              throw new TypeError(
                '.workflows.v1.GetObjectTypeFieldSchemasRequest.objectTypeIds: array type expected, but got ' +
                  typeof object.objectTypeIds
              );
            }
            message.objectTypeIds = new Array(object.objectTypeIds.length);
            for (let i = 0; i < object.objectTypeIds.length; ++i) {
              message.objectTypeIds[i] = String(object.objectTypeIds[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.application
                  ] === undefined
                  ? message.application
                  : source_application$1.v2.SourceApplication.Application[
                      message.application
                    ]
                : message.application;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          if (message.objectTypeIds && message.objectTypeIds.length) {
            object.objectTypeIds = new Array(message.objectTypeIds.length);
            for (let i = 0; i < message.objectTypeIds.length; ++i) {
              object.objectTypeIds[i] = message.objectTypeIds[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetObjectTypeFieldSchemasRequest'
          );
        }
      }

      GetObjectTypeFieldSchemasRequest.prototype.application = 0;
      GetObjectTypeFieldSchemasRequest.prototype.objectCategory = 0;
      GetObjectTypeFieldSchemasRequest.prototype.objectTypeIds =
        $util.emptyArray;

      return GetObjectTypeFieldSchemasRequest;
    })();

    v1.GetObjectTypeFieldSchemasResponse = (() => {
      class GetObjectTypeFieldSchemasResponse {
        constructor(properties) {
          this.schemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectTypeFieldSchemasResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.schemas != null &&
            Object.hasOwnProperty.call(message, 'schemas')
          ) {
            for (const element of message.schemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectTypeFieldSchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.schemas || !message.schemas.length) {
                  message.schemas = [];
                }
                message.schemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetObjectTypeFieldSchemasResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetObjectTypeFieldSchemasResponse();
          if (object.schemas) {
            if (!Array.isArray(object.schemas)) {
              throw new TypeError(
                '.workflows.v1.GetObjectTypeFieldSchemasResponse.schemas: array type expected, but got ' +
                  typeof object.schemas
              );
            }
            message.schemas = new Array(object.schemas.length);
            for (let i = 0; i < object.schemas.length; ++i) {
              if (typeof object.schemas[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetObjectTypeFieldSchemasResponse.schemas: object expected, but got ' +
                    typeof object.schemas[i]
                );
              }
              message.schemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.schemas[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.schemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.schemas && message.schemas.length) {
            object.schemas = new Array(message.schemas.length);
            for (let i = 0; i < message.schemas.length; ++i) {
              object.schemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.schemas[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetObjectTypeFieldSchemasResponse'
          );
        }
      }

      GetObjectTypeFieldSchemasResponse.prototype.schemas = $util.emptyArray;

      return GetObjectTypeFieldSchemasResponse;
    })();

    v1.GetExtraFetchedDataRequest = (() => {
      class GetExtraFetchedDataRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetExtraFetchedDataRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(18).string(message.nodeId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetExtraFetchedDataRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.nodeId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetExtraFetchedDataRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetExtraFetchedDataRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.nodeId = '';
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetExtraFetchedDataRequest';
        }
      }

      GetExtraFetchedDataRequest.prototype.workflowExecutionId = '';
      GetExtraFetchedDataRequest.prototype.nodeId = '';

      return GetExtraFetchedDataRequest;
    })();

    v1.GetExtraFetchedDataResponse = (() => {
      class GetExtraFetchedDataResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetExtraFetchedDataResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.extraFetchedData != null &&
            Object.hasOwnProperty.call(message, 'extraFetchedData')
          ) {
            workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.encode(
              message.extraFetchedData,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetExtraFetchedDataResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.extraFetchedData =
                  workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetExtraFetchedDataResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.GetExtraFetchedDataResponse();
          if (object.extraFetchedData != null) {
            if (typeof object.extraFetchedData !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetExtraFetchedDataResponse.extraFetchedData: object expected, but got ' +
                  typeof object.extraFetchedData
              );
            }
            message.extraFetchedData =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.fromObject(
                object.extraFetchedData
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.extraFetchedData = null;
          }
          let keys;
          if (
            message.extraFetchedData != null &&
            message.hasOwnProperty('extraFetchedData')
          ) {
            object.extraFetchedData =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.toObject(
                message.extraFetchedData,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetExtraFetchedDataResponse';
        }
      }

      GetExtraFetchedDataResponse.prototype.extraFetchedData = null;

      return GetExtraFetchedDataResponse;
    })();

    v1.CancelWorkflowRequest = (() => {
      class CancelWorkflowRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelWorkflowRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.temporalWorkflowId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowId')
          ) {
            writer.uint32(10).string(message.temporalWorkflowId);
          }
          if (
            message.temporalWorkflowRunId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowRunId')
          ) {
            writer.uint32(18).string(message.temporalWorkflowRunId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelWorkflowRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.temporalWorkflowId = reader.string();
                break;
              }
              case 2: {
                message.temporalWorkflowRunId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CancelWorkflowRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CancelWorkflowRequest();
          if (object.temporalWorkflowId != null) {
            message.temporalWorkflowId = String(object.temporalWorkflowId);
          }
          if (object.temporalWorkflowRunId != null) {
            message.temporalWorkflowRunId = String(
              object.temporalWorkflowRunId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.temporalWorkflowId = '';
            object.temporalWorkflowRunId = '';
          }
          let keys;
          if (
            message.temporalWorkflowId != null &&
            message.hasOwnProperty('temporalWorkflowId')
          ) {
            object.temporalWorkflowId = message.temporalWorkflowId;
          }
          if (
            message.temporalWorkflowRunId != null &&
            message.hasOwnProperty('temporalWorkflowRunId')
          ) {
            object.temporalWorkflowRunId = message.temporalWorkflowRunId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CancelWorkflowRequest';
        }
      }

      CancelWorkflowRequest.prototype.temporalWorkflowId = '';
      CancelWorkflowRequest.prototype.temporalWorkflowRunId = '';

      return CancelWorkflowRequest;
    })();

    v1.CancelWorkflowResponse = (() => {
      class CancelWorkflowResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelWorkflowResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelWorkflowResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CancelWorkflowResponse) {
            return object;
          }
          const message = new $root.workflows.v1.CancelWorkflowResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CancelWorkflowResponse';
        }
      }

      return CancelWorkflowResponse;
    })();

    v1.RunCELPlaygroundRequest = (() => {
      class RunCELPlaygroundRequest {
        get input() {
          for (const key of ['eventPayload', 'struct']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set input(name) {
          for (const key of ['eventPayload', 'struct']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RunCELPlaygroundRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.celExpressionStr != null &&
            Object.hasOwnProperty.call(message, 'celExpressionStr')
          ) {
            writer.uint32(10).string(message.celExpressionStr);
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            event_payload$1.v2.EventPayload.encode(
              message.eventPayload,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.struct != null &&
            Object.hasOwnProperty.call(message, 'struct')
          ) {
            google$2.protobuf.Struct.encode(
              message.struct,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RunCELPlaygroundRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.celExpressionStr = reader.string();
                break;
              }
              case 2: {
                message.eventPayload = event_payload$1.v2.EventPayload.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.struct = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RunCELPlaygroundRequest) {
            return object;
          }
          const message = new $root.workflows.v1.RunCELPlaygroundRequest();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.RunCELPlaygroundRequest.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = event_payload$1.v2.EventPayload.fromObject(
              object.eventPayload
            );
          }
          if (object.struct != null) {
            if (typeof object.struct !== 'object') {
              throw new TypeError(
                '.workflows.v1.RunCELPlaygroundRequest.struct: object expected, but got ' +
                  typeof object.struct
              );
            }
            message.struct = google$2.protobuf.Struct.fromObject(object.struct);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = '';
          }
          let keys;
          if (
            message.celExpressionStr != null &&
            message.hasOwnProperty('celExpressionStr')
          ) {
            object.celExpressionStr = message.celExpressionStr;
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = event_payload$1.v2.EventPayload.toObject(
              message.eventPayload,
              options
            );
            if (options.oneofs) {
              object.input = 'eventPayload';
            }
          }
          if (message.struct != null && message.hasOwnProperty('struct')) {
            object.struct = google$2.protobuf.Struct.toObject(
              message.struct,
              options
            );
            if (options.oneofs) {
              object.input = 'struct';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RunCELPlaygroundRequest';
        }
      }

      RunCELPlaygroundRequest.prototype.celExpressionStr = '';
      RunCELPlaygroundRequest.prototype.eventPayload = null;
      RunCELPlaygroundRequest.prototype.struct = null;

      return RunCELPlaygroundRequest;
    })();

    v1.RunCELPlaygroundResponse = (() => {
      class RunCELPlaygroundResponse {
        get result() {
          for (const key of ['evaluationResult', 'error']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set result(name) {
          for (const key of ['evaluationResult', 'error']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RunCELPlaygroundResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.evaluationResult != null &&
            Object.hasOwnProperty.call(message, 'evaluationResult')
          ) {
            $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.encode(
              message.evaluationResult,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.error != null &&
            Object.hasOwnProperty.call(message, 'error')
          ) {
            $root.workflows.v1.CELErrors.encode(
              message.error,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RunCELPlaygroundResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.evaluationResult =
                  $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.error = $root.workflows.v1.CELErrors.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RunCELPlaygroundResponse) {
            return object;
          }
          const message = new $root.workflows.v1.RunCELPlaygroundResponse();
          if (object.evaluationResult != null) {
            if (typeof object.evaluationResult !== 'object') {
              throw new TypeError(
                '.workflows.v1.RunCELPlaygroundResponse.evaluationResult: object expected, but got ' +
                  typeof object.evaluationResult
              );
            }
            message.evaluationResult =
              $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.fromObject(
                object.evaluationResult
              );
          }
          if (object.error != null) {
            if (typeof object.error !== 'object') {
              throw new TypeError(
                '.workflows.v1.RunCELPlaygroundResponse.error: object expected, but got ' +
                  typeof object.error
              );
            }
            message.error = $root.workflows.v1.CELErrors.fromObject(
              object.error
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.evaluationResult != null &&
            message.hasOwnProperty('evaluationResult')
          ) {
            object.evaluationResult =
              $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.toObject(
                message.evaluationResult,
                options
              );
            if (options.oneofs) {
              object.result = 'evaluationResult';
            }
          }
          if (message.error != null && message.hasOwnProperty('error')) {
            object.error = $root.workflows.v1.CELErrors.toObject(
              message.error,
              options
            );
            if (options.oneofs) {
              object.result = 'error';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RunCELPlaygroundResponse';
        }
      }

      RunCELPlaygroundResponse.prototype.evaluationResult = null;
      RunCELPlaygroundResponse.prototype.error = null;
      RunCELPlaygroundResponse.EvaluationResult = (() => {
        class EvaluationResult {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EvaluationResult(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.value != null &&
              Object.hasOwnProperty.call(message, 'value')
            ) {
              google$2.protobuf.Value.encode(
                message.value,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.datatype != null &&
              Object.hasOwnProperty.call(message, 'datatype')
            ) {
              google$3.api.expr.v1alpha1.Type.encode(
                message.datatype,
                writer.uint32(18).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EvaluationResult();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.value = google$2.protobuf.Value.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.datatype = google$3.api.expr.v1alpha1.Type.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.RunCELPlaygroundResponse.EvaluationResult();
            if (object.value != null) {
              if (typeof object.value !== 'object') {
                throw new TypeError(
                  '.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.value: object expected, but got ' +
                    typeof object.value
                );
              }
              message.value = google$2.protobuf.Value.fromObject(object.value);
            }
            if (object.datatype != null) {
              if (typeof object.datatype !== 'object') {
                throw new TypeError(
                  '.workflows.v1.RunCELPlaygroundResponse.EvaluationResult.datatype: object expected, but got ' +
                    typeof object.datatype
                );
              }
              message.datatype = google$3.api.expr.v1alpha1.Type.fromObject(
                object.datatype
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.value = null;
              object.datatype = null;
            }
            let keys;
            if (message.value != null && message.hasOwnProperty('value')) {
              object.value = google$2.protobuf.Value.toObject(
                message.value,
                options
              );
            }
            if (
              message.datatype != null &&
              message.hasOwnProperty('datatype')
            ) {
              object.datatype = google$3.api.expr.v1alpha1.Type.toObject(
                message.datatype,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.RunCELPlaygroundResponse.EvaluationResult'
            );
          }
        }

        EvaluationResult.prototype.value = null;
        EvaluationResult.prototype.datatype = null;

        return EvaluationResult;
      })();

      return RunCELPlaygroundResponse;
    })();

    v1.CELErrors = (() => {
      class CELErrors {
        constructor(properties) {
          this.errors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CELErrors(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errors != null &&
            Object.hasOwnProperty.call(message, 'errors')
          ) {
            for (const element of message.errors) {
              $root.workflows.v1.CELError.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CELErrors();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push(
                  $root.workflows.v1.CELError.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CELErrors) {
            return object;
          }
          const message = new $root.workflows.v1.CELErrors();
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(
                '.workflows.v1.CELErrors.errors: array type expected, but got ' +
                  typeof object.errors
              );
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.CELErrors.errors: object expected, but got ' +
                    typeof object.errors[i]
                );
              }
              message.errors[i] = $root.workflows.v1.CELError.fromObject(
                object.errors[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] = $root.workflows.v1.CELError.toObject(
                message.errors[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CELErrors';
        }
      }

      CELErrors.prototype.errors = $util.emptyArray;

      return CELErrors;
    })();

    v1.CELError = (() => {
      class CELError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CELError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.message != null &&
            Object.hasOwnProperty.call(message, 'message')
          ) {
            writer.uint32(10).string(message.message);
          }
          if (
            message.location != null &&
            Object.hasOwnProperty.call(message, 'location')
          ) {
            $root.workflows.v1.CELError.Location.encode(
              message.location,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CELError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.message = reader.string();
                break;
              }
              case 2: {
                message.location = $root.workflows.v1.CELError.Location.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CELError) {
            return object;
          }
          const message = new $root.workflows.v1.CELError();
          if (object.message != null) {
            message.message = String(object.message);
          }
          if (object.location != null) {
            if (typeof object.location !== 'object') {
              throw new TypeError(
                '.workflows.v1.CELError.location: object expected, but got ' +
                  typeof object.location
              );
            }
            message.location = $root.workflows.v1.CELError.Location.fromObject(
              object.location
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.message = '';
          }
          let keys;
          if (message.message != null && message.hasOwnProperty('message')) {
            object.message = message.message;
          }
          if (message.location != null && message.hasOwnProperty('location')) {
            object.location = $root.workflows.v1.CELError.Location.toObject(
              message.location,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CELError';
        }
      }

      CELError.prototype.message = '';
      CELError.prototype.location = null;
      CELError.Location = (() => {
        class Location {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Location(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.line != null &&
              Object.hasOwnProperty.call(message, 'line')
            ) {
              writer.uint32(8).int32(message.line);
            }
            if (
              message.column != null &&
              Object.hasOwnProperty.call(message, 'column')
            ) {
              writer.uint32(16).int32(message.column);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Location();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.line = reader.int32();
                  break;
                }
                case 2: {
                  message.column = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.CELError.Location) {
              return object;
            }
            const message = new $root.workflows.v1.CELError.Location();
            if (object.line != null) {
              message.line = object.line | 0;
            }
            if (object.column != null) {
              message.column = object.column | 0;
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.line = 0;
              object.column = 0;
            }
            let keys;
            if (message.line != null && message.hasOwnProperty('line')) {
              object.line = message.line;
            }
            if (message.column != null && message.hasOwnProperty('column')) {
              object.column = message.column;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.CELError.Location';
          }
        }

        Location.prototype.line = 0;
        Location.prototype.column = 0;

        return Location;
      })();

      return CELError;
    })();

    v1.Status = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_WORKFLOW_STATUS')] = 0;
      values[(valuesById[1] = 'DRAFT')] = 1;
      values[(valuesById[2] = 'ACTIVE')] = 2;
      values[(valuesById[3] = 'INACTIVE')] = 3;
      return values;
    })();

    v1.WorkflowsService = (() => {
      class WorkflowsService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new WorkflowsService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (WorkflowsService.prototype.create = function create(
          request,
          callback
        ) {
          return this.rpcCall(
            create,
            $root.workflows.v1.CreateRequest,
            $root.workflows.v1.CreateResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Create' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.get = function get(request, callback) {
          return this.rpcCall(
            get,
            $root.workflows.v1.GetRequest,
            $root.workflows.v1.GetResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Get' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.update = function update(
          request,
          callback
        ) {
          return this.rpcCall(
            update,
            $root.workflows.v1.UpdateRequest,
            $root.workflows.v1.UpdateResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Update' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.createWorkflowClone =
          function createWorkflowClone(request, callback) {
            return this.rpcCall(
              createWorkflowClone,
              $root.workflows.v1.CreateWorkflowCloneRequest,
              $root.workflows.v1.CreateWorkflowCloneResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateWorkflowClone' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getWorkflowVersion =
          function getWorkflowVersion(request, callback) {
            return this.rpcCall(
              getWorkflowVersion,
              $root.workflows.v1.GetWorkflowVersionRequest,
              $root.workflows.v1.GetWorkflowVersionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetWorkflowVersion' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getWorkflowVersionNodeFieldSchemas =
          function getWorkflowVersionNodeFieldSchemas(request, callback) {
            return this.rpcCall(
              getWorkflowVersionNodeFieldSchemas,
              $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasRequest,
              $root.workflows.v1.GetWorkflowVersionNodeFieldSchemasResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetWorkflowVersionNodeFieldSchemas' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.updateWorkflowVersion =
          function updateWorkflowVersion(request, callback) {
            return this.rpcCall(
              updateWorkflowVersion,
              $root.workflows.v1.UpdateWorkflowVersionRequest,
              $root.workflows.v1.UpdateWorkflowVersionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateWorkflowVersion' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.updateWorkflowVersionNode =
          function updateWorkflowVersionNode(request, callback) {
            return this.rpcCall(
              updateWorkflowVersionNode,
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest,
              $root.workflows.v1.UpdateWorkflowVersionNodeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateWorkflowVersionNode' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.list = function list(request, callback) {
          return this.rpcCall(
            list,
            $root.workflows.v1.ListRequest,
            $root.workflows.v1.ListResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'List' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowVersions =
          function listWorkflowVersions(request, callback) {
            return this.rpcCall(
              listWorkflowVersions,
              $root.workflows.v1.ListWorkflowVersionsRequest,
              $root.workflows.v1.ListWorkflowVersionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowVersions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getValidTriggerEvents =
          function getValidTriggerEvents(request, callback) {
            return this.rpcCall(
              getValidTriggerEvents,
              $root.workflows.v1.GetValidTriggerEventsRequest,
              $root.workflows.v1.GetValidTriggerEventsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetValidTriggerEvents' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.retryEventSnapshot =
          function retryEventSnapshot(request, callback) {
            return this.rpcCall(
              retryEventSnapshot,
              $root.workflows.v1.RetryEventSnapshotRequest,
              $root.workflows.v1.RetryEventSnapshotResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'RetryEventSnapshot' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.retryWorkflowExecution =
          function retryWorkflowExecution(request, callback) {
            return this.rpcCall(
              retryWorkflowExecution,
              $root.workflows.v1.RetryWorkflowExecutionRequest,
              $root.workflows.v1.RetryWorkflowExecutionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'RetryWorkflowExecution' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listDestinationActions =
          function listDestinationActions(request, callback) {
            return this.rpcCall(
              listDestinationActions,
              $root.workflows.v1.ListDestinationActionsRequest,
              $root.workflows.v1.ListDestinationActionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListDestinationActions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listFetchActions =
          function listFetchActions(request, callback) {
            return this.rpcCall(
              listFetchActions,
              $root.workflows.v1.ListFetchActionsRequest,
              $root.workflows.v1.ListFetchActionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListFetchActions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listObjectTypeIDs =
          function listObjectTypeIDs(request, callback) {
            return this.rpcCall(
              listObjectTypeIDs,
              $root.workflows.v1.ListObjectTypeIDsRequest,
              $root.workflows.v1.ListObjectTypeIDsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListObjectTypeIDs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getActionFieldObjectTypeIDs =
          function getActionFieldObjectTypeIDs(request, callback) {
            return this.rpcCall(
              getActionFieldObjectTypeIDs,
              $root.workflows.v1.GetActionFieldObjectTypeIDsRequest,
              $root.workflows.v1.GetActionFieldObjectTypeIDsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetActionFieldObjectTypeIDs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getObjectTypeFieldSchemas =
          function getObjectTypeFieldSchemas(request, callback) {
            return this.rpcCall(
              getObjectTypeFieldSchemas,
              $root.workflows.v1.GetObjectTypeFieldSchemasRequest,
              $root.workflows.v1.GetObjectTypeFieldSchemasResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetObjectTypeFieldSchemas' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getEventPayloadFieldSchemas =
          function getEventPayloadFieldSchemas(request, callback) {
            return this.rpcCall(
              getEventPayloadFieldSchemas,
              $root.workflows.v1.GetEventPayloadFieldSchemasRequest,
              $root.workflows.v1.GetEventPayloadFieldSchemasResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetEventPayloadFieldSchemas' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getExtraFetchedData =
          function getExtraFetchedData(request, callback) {
            return this.rpcCall(
              getExtraFetchedData,
              $root.workflows.v1.GetExtraFetchedDataRequest,
              $root.workflows.v1.GetExtraFetchedDataResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetExtraFetchedData' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.cancelWorkflow = function cancelWorkflow(
          request,
          callback
        ) {
          return this.rpcCall(
            cancelWorkflow,
            $root.workflows.v1.CancelWorkflowRequest,
            $root.workflows.v1.CancelWorkflowResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CancelWorkflow' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.runCELPlayground =
          function runCELPlayground(request, callback) {
            return this.rpcCall(
              runCELPlayground,
              $root.workflows.v1.RunCELPlaygroundRequest,
              $root.workflows.v1.RunCELPlaygroundResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'RunCELPlayground' }
      );

      return WorkflowsService;
    })();

    return v1;
  })();

  return workflows;
})();

export const workflows = $root.workflows;

import _ from 'lodash';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { SkeletonLoadable } from '../../Skeleton';

const ActionExecutionRowLoading: FC = () => {
  return (
    <Flexbox
      data-testid='action-execution-row-loading'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      borderRadius={0.25}
      width='16.5rem'
      height='auto'
      cursor='default'
      p={0.5}
      gap={0.25}
    >
      <SkeletonLoadable isLoading width='1rem' height='1rem' />

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <SkeletonLoadable isLoading width='11.5rem' height='1rem' />
        <SkeletonLoadable isLoading width='11.5rem' height='0.5rem' />
      </Flexbox>
      <SkeletonLoadable isLoading width='1rem' height='1rem' />
    </Flexbox>
  );
};

export const MemoizedActionExecutionRowLoading = memo(
  ActionExecutionRowLoading
);

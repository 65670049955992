import { NodeType } from '../types';
import { TriggerNodeProps } from './types';
import { event_payload } from '@morf/proto/event_payload_v2_ts_proto';
import { formsort } from '@morf/proto/formsort_v1_ts_proto';
import { object_category } from '@morf/proto/object_category_v1_ts_proto';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const triggerNode: TriggerNodeProps = {
  id: 'trigger-node',
  type: NodeType.TriggerNode,
  data: {
    value: {
      title: 'Trigger',
      integrationIconName: 'formsort',
      name: 'Formsort Forms',
      configuration: new workflows.v1.Trigger({
        sourceApplication:
          source_application.v2.SourceApplication.Application.FORMSORT,
        objectCategory: object_category.v1.ObjectCategory.Category.FORM,
        triggerEvents: new event_payload.v2.EventTypes({
          formsortEventTypes: {
            eventTypes: [formsort.v1.FormsortEventType.FORMSORT_STEP_COMPLETED],
          },
        }),
        objectTypeFilters: ['campaign-004'],
        extraFilter: new workflows.v1.WorkflowFilter({
          celExpressionStr: '',
        }),
        eventPayloadStub: null,
        uniquenessConflictResolution: {
          conflictResolution:
            workflows.v1.WorkflowUniquenessConflictResolution.ConflictResolution
              .SKIP_NEW,
          scope:
            workflows.v1.WorkflowUniquenessConflictResolution.ConflictScope
              .WORKFLOW,
        },
      }),
    },
  },
  position: { x: 0, y: 0 },
};

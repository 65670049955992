import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { ReactElement } from 'react';
import { Text } from '../Typography';
import { getDefaultIndividualWorkflowExecutionPath } from '../../../apps/admin/components/helpers/getDefaultIndividualWorkflowExecutionPath';
import { useHover } from '../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

export const getHoverDescription = (
  prefix: string,
  workflowExecutionId?: string | null
): ReactElement => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();
  const { organizationId } = useUrlParams();

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      flexWrap='wrap'
      height='auto'
      gap={0.25}
    >
      <Text tag='p3'>{prefix}</Text>
      {workflowExecutionId && organizationId && (
        <Link
          href={getDefaultIndividualWorkflowExecutionPath(
            organizationId,
            workflowExecutionId
          )}
          target='_blank'
        >
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            flexWrap='wrap'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            height='auto'
            gap={0.25}
          >
            <Text
              tag={isHovered ? 'h6' : 'p3'}
              color={theme.colors.text.muted}
              wordWrap='break-all'
            >
              this execution
            </Text>
            <Icon
              name='arrow-right'
              stroke={theme.colors.text.muted}
              size={0.75}
            />
          </Flexbox>
        </Link>
      )}
    </Flexbox>
  );
};

import { Button } from '../Button';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { LinkedAccount } from '../LinkedAccount';
import { ProfileSidebarProps } from './types';
import { Sidebar } from '../Sidebar';
import { SkeletonLoadable } from '../Skeleton';
import { Text, TooltipText } from '../Typography';
import { Timestamp } from '../Timestamp';
import { useTheme } from '@morf/theming';

const ProfileSidebar: FC<ProfileSidebarProps> = ({
  createdAt,
  email,
  id,
  isLoading,
  link,
  linkedAccounts,
  name,
  onClose,
  type,
}) => {
  const theme = useTheme();

  const typeMap: Record<
    ProfileSidebarProps['type'],
    { icon: string; name: string }
  > = {
    anonymous: { icon: 'anonymous-type', name: 'Anonymous' },
    lead: { icon: 'lead-type', name: 'Lead' },
    patient: { icon: 'patient-type', name: 'Patient' },
  };

  return (
    <Sidebar isOpen width='25rem'>
      <Flexbox
        key={id}
        data-testid='profile-sidebar'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        p={1.25}
        gap={1.25}
      >
        <Icon
          name='double-chevron-right'
          stroke={theme.colors.ui.dark}
          size={1.25}
          cursor='pointer'
          onClick={onClose}
        />
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0.75}
        >
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={0.125}
            height='auto'
          >
            <SkeletonLoadable
              isLoading={isLoading}
              height='1.25rem'
              width='1.25rem'
            >
              <Icon
                name={typeMap[type].icon}
                stroke={theme.colors.ui.dark}
                size={1.25}
              />
            </SkeletonLoadable>
            <SkeletonLoadable
              isLoading={isLoading}
              height='1.25rem'
              width='5rem'
            >
              <Text tag='p3' color={theme.colors.text.muted}>
                {typeMap[type].name}
              </Text>
            </SkeletonLoadable>
          </Flexbox>
          <SkeletonLoadable isLoading={isLoading} height='1.5rem' width='15rem'>
            <TooltipText tag='h3' whiteSpace='nowrap'>
              {name}
            </TooltipText>
          </SkeletonLoadable>
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={0}
            height='auto'
          >
            <SkeletonLoadable
              isLoading={isLoading}
              height='1.25rem'
              width='20rem'
            >
              <Text tag='p2' color={theme.colors.text.muted}>
                {id}
              </Text>
            </SkeletonLoadable>
            <SkeletonLoadable
              isLoading={isLoading}
              height='1.25rem'
              width='10rem'
            >
              <Text tag='p2' color={theme.colors.text.muted}>
                {email}
              </Text>
            </SkeletonLoadable>
          </Flexbox>
        </Flexbox>

        <Link data-testid='profile-link' href={link}>
          <Button
            variant='custom'
            borderColor={theme.colors.ui.divider}
            backgroundColor={theme.colors.ui.card}
            width='22.5rem'
          >
            <Text tag='h5' color={theme.colors.main.primary.darker}>
              View Profile
            </Text>
          </Button>
        </Link>

        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          data-testid='linked-accounts'
          overflowY='scroll'
          flexGrow={1}
        >
          <Text tag='h5'>Linked Accounts</Text>
          {linkedAccounts.map((linkedAccount, index) => (
            <LinkedAccount
              key={index}
              {...linkedAccount}
              isLoading={isLoading}
              type='horizontal'
            />
          ))}
        </Flexbox>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Text tag='p2' color={theme.colors.text.muted}>
            Created on:
          </Text>
          <SkeletonLoadable
            isLoading={isLoading}
            height='1.25rem'
            width='10rem'
          >
            <Timestamp
              tag='p2'
              color={theme.colors.text.muted}
              timestamp={createdAt}
            />
          </SkeletonLoadable>
        </Flexbox>
      </Flexbox>
    </Sidebar>
  );
};

export const MemoizedProfileSidebar = memo(ProfileSidebar);

import { FC } from 'react';
import { Input } from '../Input';
import { Flexbox } from '../Flexbox';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';
import { DateFieldInputProps } from './types';
import { Container } from '../Container';
import { goToPrevDate } from '../DatePicker/goToPrevDate';
import { goToNextDate } from '../DatePicker/goToNextDate';
import { useResize } from '../Hooks/useResize';

export const DateFieldInput: FC<DateFieldInputProps> = ({
  date,
  onDateChange,
}) => {
  const theme = useTheme();

  const responsiveInput = useResize({
    type: {
      xxl: 'date',
      xl: 'date',
      lg: 'date',
      md: 'text',
      sm: 'text',
      xs: 'text',
    },
  });

  return (
    <Input
      id='date-field-input'
      type={responsiveInput.type}
      style={{ fontSize: theme.fontSizes[0.875] }}
      key={date}
      value={date}
      height='2.5rem'
      onChange={(e) => onDateChange(e.target.value)}
      leftElement={
        <Flexbox alignItems='center'>
          <Icon
            name='calendar'
            size={1.25}
            fill={theme.colors.text.muted}
            stroke='none'
            cursor='pointer'
          />
        </Flexbox>
      }
      rightElement={
        <Flexbox
          justifyContent='flex-end'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Container
            width='auto'
            onClick={() => goToPrevDate(date, onDateChange)}
          >
            <Icon
              cursor='pointer'
              name='chevron-left'
              size={1.25}
              stroke={theme.colors.main.primary.darker}
            />
          </Container>
          <Container
            width='auto'
            onClick={() => goToNextDate(date, onDateChange)}
          >
            <Icon
              cursor='pointer'
              name='chevron-right'
              size={1.25}
              stroke={theme.colors.main.primary.darker}
            />
          </Container>
        </Flexbox>
      }
    />
  );
};

import _ from 'lodash';
import { Button } from '../Button';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { NoMoreTimelineRowsProps } from './types';
import { Text } from '../Typography';
import { formatCustomLabel } from '../Filters';
import { timing } from '@morf/proto/timing_v1_ts_proto';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';

const NoMoreTimelineRows: FC<NoMoreTimelineRowsProps> = ({
  setIsLoadingAnotherHour,
  timeOption,
  type,
}) => {
  const updateUrlParam = useUpdateUrlParam();

  const formattedLabel = timeOption?.label.includes(' - ')
    ? timeOption.label
    : _.toLower(timeOption?.label);

  const timeRangePreposition = timeOption?.label.includes(' - ')
    ? 'between'
    : 'within';

  const handleLoadAnotherHour = () => {
    if (timeOption) {
      const startTimestamp = new timing.v1.Timestamp({
        seconds: timeOption.timeRange.startTimestamp.seconds.subtract(3600),
        nanoseconds: timeOption.timeRange.startTimestamp.nanoseconds,
      });

      const endTimestamp = timeOption.timeRange.endTimestamp;

      const label = formatCustomLabel(startTimestamp, endTimestamp);
      updateUrlParam({ time: label });
      setIsLoadingAnotherHour(true);
    }
  };

  return (
    <Flexbox
      direction='column'
      justifyContent='center'
      alignItems='center'
      height='auto'
      p={1}
    >
      <Text tag='p3' align='center'>
        {timeOption
          ? `No more ${type} ${timeRangePreposition} ${formattedLabel}`
          : `No more ${type}`}
      </Text>

      {timeOption && (
        <Button size='base' variant='primary' onClick={handleLoadAnotherHour}>
          <Text tag='h5' color='inherit'>
            Load another hour
          </Text>
        </Button>
      )}
    </Flexbox>
  );
};

export const MemoizedNoMoreTimelineRows = memo(NoMoreTimelineRows);

import _ from 'lodash';
import rpcService from '@morf/server/browser-server';
import { FC, memo } from 'react';
import { Filter } from '@morf/ui/Filter';
import { FilterOption } from '@morf/ui/Filter/FilterDropdown/FilterOption/types';
import { WorkflowFilterProps } from './types';
import { convertToKebabCase } from '@morf/ui';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { useQuery } from '@tanstack/react-query';
import { useUrlParams } from '../../../helpers/useUrlParams';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const WorkflowFilter: FC<WorkflowFilterProps> = ({
  setIsWorkflowFilterSelected,
}) => {
  const { organizationId, workflowIds } = useUrlParams();

  const { data: listResponse, isLoading: listResponseIsLoading } =
    useQuery<workflows.v1.ListResponse>(
      ['/workflows.v1.WorkflowsService/List', organizationId],
      async () => {
        return await rpcService.workflowsV1Service.list({
          filter: new workflows.v1.ListRequest.Filter(),
        });
      }
    );

  const allWorkflowOptions: FilterOption[] =
    listResponse?.workflows?.map(({ id, name, sourceApplication }) => ({
      id,
      name,
      integrationIconName: convertToKebabCase(
        source_application.v2.SourceApplication.Application[sourceApplication]
      ),
    })) || [];

  const selectedWorkflowOptions = allWorkflowOptions.filter(({ id }) =>
    workflowIds?.includes(id)
  );

  return (
    <Filter
      allOptions={allWorkflowOptions}
      name='Workflow'
      options={selectedWorkflowOptions}
      queryName='workflowIds'
      setIsFilterSelected={setIsWorkflowFilterSelected}
    />
  );
};

export const MemoizedWorkflowFilter = memo(WorkflowFilter);

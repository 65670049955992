import { Button } from '../Button';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { WrapperModalFooterActionsProps } from './types';
import { useTheme } from '@morf/theming';

const WrapperModalFooterActions: FC<WrapperModalFooterActionsProps> = ({
  currentStep,
  lastStepLabel,
  nextStepDisabled,
  onNextStep,
  onPreviousStep,
  totalSteps,
  ...props
}) => {
  const theme = useTheme();
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === totalSteps;

  return (
    <Flexbox
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      height='auto'
      {...props}
    >
      <Text tag='p2' color={theme.colors.text.muted}>
        Step {currentStep} of {totalSteps}
      </Text>

      <Flexbox
        direction='row'
        justifyContent='flex-end'
        width='auto'
        height='auto'
        gap={1.25}
      >
        <Button variant='tertiary' onClick={onPreviousStep}>
          <Text tag='h5'>{isFirstStep ? 'Cancel' : 'Previous step'}</Text>
        </Button>

        <Button
          variant='primary'
          isDisabled={nextStepDisabled}
          onClick={onNextStep}
        >
          <Text tag='h5' color='inherit'>
            {isLastStep ? lastStepLabel : 'Continue'}
          </Text>
        </Button>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWrapperModalFooterActions = memo(
  WrapperModalFooterActions
);

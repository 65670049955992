import Link from 'next/link';
import { styled } from '@morf/theming';
import { LinkProps } from './types';

export const StyledLink = styled(Link)<LinkProps>`
  width: ${({ width = 'auto' }) => width};
  color: ${({ theme }) => theme.colors.text.body};
  text-decoration: none;
  cursor: pointer;
`;

import _ from 'lodash';
import { FC, memo, useLayoutEffect, useState } from 'react';
import { FilterChip } from '../../Filter/FilterChip';
import { Flexbox } from '../../Flexbox';
import { TimeDropdown } from './TimeDropdown';
import { TimeFilterProps } from './types';
import { TimeOption } from './TimeDropdown/TimeOption/types';
import { WrapperModal } from '../../WrapperModal';
import { useRef } from 'react';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';

const TimeFilter: FC<TimeFilterProps> = ({ timeOption }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const updateUrlParam = useUpdateUrlParam();

  const [showTimeOptions, setShowTimeOptions] = useState<boolean>(!timeOption);
  const [position, setPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const handleToggleTimeOptions = () => {
    setShowTimeOptions(!showTimeOptions);
  };
  const handleCloseTimeOptions = () => setShowTimeOptions(false);

  const handleApplyClick = (timeOption: TimeOption) => {
    setShowTimeOptions(false);
    updateUrlParam({ time: timeOption.label });
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top / 16 + 1.75 + 'rem',
        left: rect.left / 16 + 'rem',
      });
    }
  }, []);

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='time-filter'
      width='auto'
      height='auto'
    >
      <FilterChip
        name='Time'
        onClick={handleToggleTimeOptions}
        showOptions={showTimeOptions}
        {...(timeOption && {
          value: timeOption.label,
        })}
      />

      {showTimeOptions && (
        <WrapperModal
          {...position}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          width='13.125rem'
          onClose={handleCloseTimeOptions}
        >
          <TimeDropdown
            timeOption={timeOption}
            onApplyClick={handleApplyClick}
          />
        </WrapperModal>
      )}
    </Flexbox>
  );
};

export const MemoizedTimeFilter = memo(TimeFilter);

import _ from 'lodash';
import { Container } from '../../../Container';
import { EventSubRowProps } from './types';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { WorkflowExecutionStatus } from '../../../WorkflowExecutionStatus';
import { WorkflowExecutionTooltip } from '../../../WorkflowExecutionTimeline/WorkflowExecutionRow/WorkflowExecutionTooltip';
import { WrapperModal } from '../../../WrapperModal';
import { useHover } from '../../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../../Hooks/useUpdateUrlParam';

const EventSubRow: FC<EventSubRowProps> = ({
  executionEndTime,
  instantaneousMatchedProfileEmail,
  instantaneousMatchedProfilePhone,
  isLastSubRow,
  isSubRowSelected,
  matchedProfileIndex,
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  supersededByWorkflowExecutionId,
  supersededWorkflowExecutionId,
  workflowErrors,
  workflowExecutionId,
  workflowName,
  ...props
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const backgroundColor = isSubRowSelected
    ? theme.colors.main.light.light
    : isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;

  const nameTag = isSubRowSelected ? 'h5' : 'p2';

  const profileMatch =
    instantaneousMatchedProfileEmail ||
    instantaneousMatchedProfilePhone ||
    matchedProfileIndex?.thirdPartyId ||
    'No profile match';

  const handleSubRowClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateUrlParam({
      workflowExecutionId: workflowExecutionId,
      nodeId: 'trigger-node',
    });
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, handleShowTooltip]);

  return (
    <>
      <Flexbox
        containerRef={containerRef}
        data-testid='event-sub-row'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        backgroundColor={backgroundColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleSubRowClick}
        width='auto'
        height='auto'
        cursor='pointer'
        pl={0.75}
        pr={0.5}
        gap={0.5}
        {...(isLastSubRow && {
          borderType: 'borderBottom',
          borderColor: backgroundColor,
          borderRadius: 0.25,
        })}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          borderType='borderLeft'
          borderColor={theme.colors.ui.muted}
          position='relative'
          height='auto'
          gap={0.5}
          py={0.5}
        >
          {isLastSubRow && (
            <Container
              position='absolute'
              left='-0.1rem'
              top='1.75rem'
              width='1rem'
              height='1.65rem'
              borderType='borderLeft'
              borderColor={backgroundColor}
            />
          )}

          <Container
            width='1.75rem'
            borderType='borderTop'
            borderColor={theme.colors.ui.muted}
          />

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0}
          >
            <Flexbox
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              height='auto'
              gap={0.25}
            >
              <Container width='11.5rem' height='auto'>
                <Text tag={nameTag} whiteSpace='nowrap'>
                  {workflowName}
                </Text>
              </Container>

              <WorkflowExecutionStatus
                variant='icon'
                retryNumber={retryNumber}
                retryOriginalWorkflowExecutionId={
                  retryOriginalWorkflowExecutionId
                }
                status={status}
                supersededByWorkflowExecutionId={
                  supersededByWorkflowExecutionId
                }
                supersededWorkflowExecutionId={supersededWorkflowExecutionId}
              />
            </Flexbox>

            <Container width='11.25rem'>
              <Text
                tag='p3'
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {profileMatch}
              </Text>
            </Container>
          </Flexbox>
        </Flexbox>
      </Flexbox>

      {showTooltip && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <WorkflowExecutionTooltip
            executionEndTime={executionEndTime}
            instantaneousMatchedProfileEmail={instantaneousMatchedProfileEmail}
            instantaneousMatchedProfilePhone={instantaneousMatchedProfilePhone}
            matchedProfileIndex={matchedProfileIndex}
            retryNumber={retryNumber}
            retryOriginalWorkflowExecutionId={retryOriginalWorkflowExecutionId}
            status={status}
            supersededByWorkflowExecutionId={supersededByWorkflowExecutionId}
            supersededWorkflowExecutionId={supersededWorkflowExecutionId}
            workflowErrors={workflowErrors}
            workflowExecutionId={workflowExecutionId}
            workflowName={workflowName}
            {...props}
          />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedEventSubRow = memo(EventSubRow);

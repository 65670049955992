import { convertToTitleCase } from '../../../Helpers/convertToTitleCase';
import { formatEventPayloadStubObjectType } from './formatEventPayloadStubObjectType';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const formatTriggerName = (
  trigger: workflows.v1.Trigger | null | undefined
): string => {
  if (!trigger) {
    return '';
  }

  const { sourceApplication, eventPayloadStub } = trigger;

  const formattedEventPayloadStubObjectType =
    formatEventPayloadStubObjectType(eventPayloadStub);

  return sourceApplication ===
    source_application.v2.SourceApplication.Application.CUSTOMER
    ? formattedEventPayloadStubObjectType
    : `${convertToTitleCase(
        source_application.v2.SourceApplication.Application[sourceApplication]
      )} ${formattedEventPayloadStubObjectType}`;
};

import { FC, memo, useRef } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { VersionTimelineProps } from './types';
import { VersionTimelineRow } from '../VersionTimelineRow';
import { createScrollHandler } from '../Helpers/createScrollHandler';
import { useTheme } from '@morf/theming';

const VersionTimeline: FC<VersionTimelineProps> = ({
  fetchNextPage,
  onClose,
  versionTimelineRows,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = createScrollHandler({
    containerRef,
    callback: fetchNextPage,
  });

  return (
    <Flexbox
      direction='column'
      data-testid='version-timeline'
      justifyContent='flex-start'
      alignItems='center'
      py={1.25}
      borderType='borderRight'
      width='20.5rem'
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
        gap={0.5}
        px={0.75}
      >
        <Text tag='h5'>Version History</Text>
        <Icon
          name='close'
          stroke={theme.colors.ui.dark}
          strokeWidth={1.75}
          size={1.25}
          onClick={onClose}
          cursor='pointer'
        />
      </Flexbox>
      <Flexbox
        containerRef={containerRef}
        data-testid='version-timeline-rows'
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        overflow='scroll'
        onScroll={handleScroll}
        gap={0}
      >
        {versionTimelineRows.map((versionTimelineRow, index) => (
          <VersionTimelineRow key={index} {...versionTimelineRow} />
        ))}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedVersionTimeline = memo(VersionTimeline);

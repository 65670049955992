import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { ReadOnlyBadgeProps } from './types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const ReadOnlyBadge: FC<ReadOnlyBadgeProps> = () => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='read-only-badge'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      width='4.75rem'
      gap={0.25}
    >
      <Icon
        name='lock-closed'
        size={0.75}
        stroke={theme.colors.ui.dark}
        strokeWidth={2}
      />
      <Text tag='h6' color={theme.colors.text.muted}>
        Read Only
      </Text>
    </Flexbox>
  );
};

export const MemoizedReadOnlyBadge = memo(ReadOnlyBadge);

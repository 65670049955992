import { ReactFlowProps } from 'reactflow';
import { ParameterVariantProps } from '../ParameterVariant';

export { EdgeType } from './CustomEdge/types';
export { NodeType, SupportNodeType } from './CustomNode/types';
export type {
  onAddNodeData,
  onRemoveNodeData,
  onUpdateNodeData,
  onUpdateProfileLookupData,
  onUpdateTriggerData,
} from './CustomNode/types';

export type ControlsProps = {
  showEventPayload?: boolean;
};
export type WorkflowProps = {
  description?: string;
  id: string;
  isLoading: boolean;
} & ControlsProps &
  ReactFlowProps;

export type ParameterConfigProps = {
  destinationFieldName?: string;
  fieldName?: string;
  fieldType?: string;
  integrationIconName?: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  sensitivity?: string;
  tagType?: ParameterVariantProps['type'];
};

export type SourceConfigProps = {
  fieldName?: string;
  fieldType?: string;
  integrationIconName?: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  sensitivity?: string;
  tagType?: ParameterVariantProps['type'];
};

export type ParamSourceConfigProps = {
  param: ParameterConfigProps;
  source: SourceConfigProps | null;
  subRows?: ParamSourceConfigProps[];
};

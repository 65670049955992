import { CopyToClipboardProps } from './types';
import { FC } from 'react';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { copyTextToClipboard } from '../Helpers/copyTextToClipboard';
import { useTheme } from '@morf/theming';

export const CopyToClipboard: FC<CopyToClipboardProps> = ({
  iconName = 'copy',
  iconViewBox = '0 0 24 24',
  iconFill,
  iconStroke,
  iconSize = 1,
  text,
  label = 'Copy to clipboard',
  tooltipText,
  ...props
}) => {
  const theme = useTheme();

  const handleOnClick = () => copyTextToClipboard(text);

  return (
    <Tooltip tooltipText={label} {...props}>
      <Icon
        name={iconName}
        cursor='pointer'
        fill='none'
        onClick={handleOnClick}
        size={iconSize}
        stroke={iconStroke || theme.colors.main.primary.darker}
        viewBox={iconViewBox}
      />
    </Tooltip>
  );
};

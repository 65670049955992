import { Container } from '../../Container';
import { FC } from 'react';
import { Icon } from '../../Icon';
import { Tooltip } from '../../Tooltip';
import { TopPanelProps } from './types';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const TopPanel: FC<TopPanelProps> = ({ isTopPanelOpen, setIsTopPanelOpen }) => {
  const theme = useTheme();
  const panelTooltipText = isTopPanelOpen ? 'Hide top panel' : 'Show top panel';
  const panelIconName = isTopPanelOpen ? 'panel-top-solid' : 'panel-top';
  const panelIconStroke = isTopPanelOpen
    ? theme.colors.main.primary.darker
    : theme.colors.ui.dark;
  const handleToggleTopPanel = () => setIsTopPanelOpen(!isTopPanelOpen);

  return (
    <Tooltip
      tooltipText={panelTooltipText}
      tooltipDirection='bottom'
      arrowDirection='left'
    >
      <Container
        data-testid='top-panel'
        borderRadius={0.25}
        cursor='pointer'
        height='auto'
        hoverBackgroundColor={theme.colors.main.light.light}
        onClick={handleToggleTopPanel}
        p={0.125}
        width='auto'
      >
        <Icon
          name={panelIconName}
          size={1.125}
          stroke={panelIconStroke}
          strokeWidth={2}
        />
      </Container>
    </Tooltip>
  );
};

export const MemoizedTopPanel = memo(TopPanel);

import { CustomNodeLabelProps } from './CustomNodeLabel/types';
import { Node } from 'reactflow';
import { organization } from '@morf/proto/organization_v1_ts_proto';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export enum NodeType {
  DestinationActionNode = 'destinationActionNode',
  FetchActionNode = 'fetchActionNode',
  FilterNode = 'filterNode',
  PauseNode = 'pauseNode',
  ProfileConvertNode = 'profileConvertNode',
  ProfileLookupNode = 'profileLookupNode',
  ProfileUpdateNode = 'profileUpdateNode',
  RestartNode = 'restartNode',
  TrackProfilePropertiesNode = 'trackProfilePropertiesNode',
  TriggerNode = 'triggerNode',
}

export enum SupportNodeType {
  HiddenNode = 'hiddenNode',
}

export type NodeData<T> = {
  value: {
    title: string;
    configuration?: T;
    description?: string;
    integrationIconName?: string;
    isMorfAnalyticsEventPayload?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;
    name: string;
    nodeSpecificEventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
    onAddNode?: (data: onAddNodeData) => void;
    onCreateCustomProperty?: (
      data: organization.v1.CreateProfilePropertyRequest
    ) => void;
    onRemoveNode?: (data: onRemoveNodeData) => void;
    onUpdateNode?: (data: onUpdateNodeData) => void;
    onUpdateProfileLookup?: (data: onUpdateProfileLookupData) => void;
    onUpdateTrigger?: (data: onUpdateTriggerData) => void;
    status?: CustomNodeLabelProps['status'];
    workflowEventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
  };
};

export type CustomNode<T> = Node<NodeData<T>>;

export type onUpdateTriggerData = {
  trigger: workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger;
};
export type onUpdateProfileLookupData = {
  profileLookupConfiguration: profiles.v1.LookupConfiguration;
};
export type onAddNodeData = { node: workflows.v1.CreateWorkflowNodeRequest };
export type onUpdateNodeData = {
  nodeId: string;
  type: workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType;
};
export type onRemoveNodeData = { nodeId: string };
